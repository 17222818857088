 import { createContext } from "react";




const pageDetails = {
  "/dashboard": { title: "Dashboard", pageName: "Dashboard" },
  "/created-users": { title: "Created User", pageName: "Created User" },
  "/leads": { title: "Leads", pageName: "Leads" },
  "/create-lead": { title: "Create Lead", pageName: "Create Lead" },
  "/lead-details": { title: "Lead Details", pageName: "Lead Details" },
  "/contact": { title: "Contact", pageName: "Contact" },
  "/create-contact": { title: "Create Contact", pageName: "Create Contact" },
  "/contact-details": { title: "Contact Details", pageName: "Contact Details" },
  "/accounts": { title: "Accounts", pageName: "Accounts" },
  "/create-account": { title: "Create Account", pageName: "Create Account" },
  "/account-details": { title: "Account Details", pageName: "Account Details" },
  "/deals": { title: "Deals", pageName: "Deals" },
  "/create-deal": { title: "Create Deal", pageName: "Create Deal" },
  "/deal-details": { title: "Deal Details", pageName: "Deal Details" },
  "/tasks": { title: "Tasks", pageName: "Tasks" },
  "/create-task": { title: "Create Task", pageName: "Create Task" },
  "/task-details": { title: "Task Details", pageName: "Task Details" },
  "/meetings": { title: "Meetings", pageName: "Meetings" },
  "/create-meeting": { title: "Create Meeting", pageName: "Create Meeting" },
  "/meetings-details": {
    title: "Meeting Details",
    pageName: "Meeting Details",
  },
  "/calls": { title: "Calls", pageName: "Calls" },
  "/call-logs": { title: "Log Calls", pageName: "Log Calls" },
  "/call-schedule": { title: "Schedule Calls", pageName: "Schedule Calls" },
  "/create-call": { title: "Create Call", pageName: "Create Call" },
  "/call-details": { title: "Call Details", pageName: "Call Details" },
  "/reports": { title: "Reports", pageName: "Reports" },
  "/create-report": { title: "Create Report", pageName: "Create Report" },
  "/report-details": { title: "Report Details", pageName: "Report Details" },
  "/companylist": { title: "Company List", pageName: "Company List" },
  "/companydetails": { title: "Company Detais", pageName: "Company Detais" },
  "/updatecompanydetails": {
    title: "Update Details",
    pageName: "Update Details",
  },
  "/create-company": { title: "Create Company", pageName: "Create Company" },
  "/role&permission": {
    title: "Update Permission",
    pageName: "Update Permission",
  },
  "/schedule-call-details": {
    title: "Schedule-Call",
    pageName: "Schedule-Call",
  },
  "/userLogs": { title: "History", pageName: "User Log" },
  "/log-call-details": { title: "Log-Call", pageName: "Log-Call" },
  "/product&services": {
    title: "Product&Services",
    pageName: "Product & Service",
  },
};

export default pageDetails;
 
export const PageDetailsContext  = createContext(pageDetails);

