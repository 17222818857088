import React, { useState, useEffect } from "react";
// CSS
// import "../../styles/dashboardCss/meetingDetails.css";
import { getSingleMeeting } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Gobackpage from "../gobackpage";


const MeetingsViewDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const meetingId = location.state;
  const [getSingleMeetingData, setSingleMeetingData] = useState([]);
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;

  useEffect(() => {
    getSingleMeeting(meetingId, tokenId).then((res) => {
      setSingleMeetingData(res);
    });
  }, []);
  
  return (
    <>
    <Gobackpage pageName={"Meeting Details"}/>
   
      <div className="account_view_details_Row" style={{minWidth:"400px"}}>
        
        {/* Meeting Information */}
        <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
          Meetings Information
        </h3>
        <p className="mx-2 dashboard_leadView_details_heading_second">
          Lead Id : LI-{getSingleMeetingData?.leadId}
        </p>
        <div style={{ padding: '16px', width: "100%", marginTop: "15px" }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {[
              { label: 'Host', value: getSingleMeetingData?.host },
              { label: 'Title', value: getSingleMeetingData?.title },
              { label: 'Address', value: getSingleMeetingData?.location },
              { label: 'Date', value: getSingleMeetingData?.modifiedDate },
              {
                label: 'Participants',
                value: Array.isArray(getSingleMeetingData?.participants)
                  ? getSingleMeetingData.participants.join(' , ')  
                  : getSingleMeetingData?.participants
              },
            ].map((item, index) => (
              <div key={index} style={{ flex: '1 1 40%', minWidth: '250px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginBottom: '8px' }}>
                  <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>
                    {item.label}
                  </span>
                  <span style={{ fontWeight: "bold" }}>:</span>
                  <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word', marginLeft: "10px" }}>
                    {getSingleMeetingData?.host ? item.value || 'No Data' : ''}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </>

  );
};

export default MeetingsViewDetails;
