import React, { useEffect, useState } from "react";
import { getUserImage } from "../../controller/fetchApi";
import { Link } from "react-router-dom";

function Logo({companyLogo}) {
  

  return (
    <div >
      <Link className="logo_crmtext " to="/dashboard">
        <img width="45px" className="LOgo_Image" src={companyLogo} alt="CRM" />
        CR<span>M</span>
      </Link>
    </div>
  );
}

export default Logo;
