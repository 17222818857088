import { Link, useNavigate } from "react-router-dom"
import { IoArrowBackCircleOutline } from "react-icons/io5"


  const Goback = ({page})=>{
   const navigate = useNavigate()
 const pagelist = ["product&services","user","leads","contact","schedulecall","logcall","meetings","deals","account","task","report"]
    return(
        <span onClick={() =>pagelist.includes(page) ? navigate("/dashboard") : navigate(-1)} title="Go Back">
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </span>
    )
  }
  export default Goback;