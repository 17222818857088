import React, { useState, useEffect } from "react";
// controller methods
import { getSingleLogCall } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Gobackpage from "../gobackpage";


const LogCallViewDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const callId = location.state;
  const [getSingleLogCallData, setSingleLogCallData] = useState([]);
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  
  useEffect(() => {
    getSingleLogCall(callId, tokenId).then((res) => {
      setSingleLogCallData(res);
    });
  }, []);

  return (
    <>
      <div className="container-fluid account_view_details_main_container" style={{minWidth:"400px"}}>
        <Gobackpage pageName={"Log Call Details"}/>
      
        {/* Log Call Information */}
        <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
          <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
            Log Call Information
          </h3>
          {getSingleLogCallData?.leadId && (
            <p className="mx-2 dashboard_leadView_details_heading_second">
              Lead Id : LI-{getSingleLogCallData?.leadId}
            </p>
          )}

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {[
              { label: 'Call To', value: getSingleLogCallData?.callTo },
              { label: 'Related To', value: getSingleLogCallData?.relatedTo },
              { label: 'Call Type', value: getSingleLogCallData?.callType },
              { label: 'Call Status', value: getSingleLogCallData?.callStatus },
              { label: 'Call Start Time', value: getSingleLogCallData?.callStartTime },
              { label: 'Call Duration', value: getSingleLogCallData?.callDuration },
              { label: 'Subject', value: getSingleLogCallData?.subject },
            ].map((item, index) => (
              <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{ fontWeight: "bold" }}>:</span>
                  <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word', marginLeft: "10px" }}>{getSingleLogCallData?.callTo ? item.value || 'No Data' : ""}</span>
                </div>
              </div>
            ))}
          </div>


          <div className="row">
            <div className="col-xl-12">
              <div className="d-xl-flex d-md-flex justify-content-between flex-wrap justify-content-between align-items-start row-cols-3">
                {/* <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call To
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callTo}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Related To
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.relatedTo}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Type
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callType}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
                {/* <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Related To
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.relatedTo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Type
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callType}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
                {/* <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Status
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callStatus}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Start Time
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callStartTime}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
                {/* <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Start Time
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callStartTime}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
                {/* <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Duration
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callDuration}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Subject
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.subject}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
                {/* <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Subject
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.subject}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Purpose of Outgoing call  */}
        <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
        <h5 className=" dashboard_leadView_company_details_heading">
        Purpose Of Outgoing Call
          </h5>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop:"20px" }}>
              {[
                { label: 'Call Purpose', value: getSingleLogCallData?.callPurpose },
                { label: 'Call Agenda', value: getSingleLogCallData?.callAgenda },
                { label: 'Call Result', value: getSingleLogCallData?.callResult },
                { label: 'Description', value: getSingleLogCallData?.description },
              ].map((item, index) => (
                <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{ fontWeight: "bold" }}>:</span>
                    <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word', marginLeft: "10px" }}>{getSingleLogCallData?.callTo ? item.value || 'No Data' : ""}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

        {/* Purpose Of Outgoing Call */}
        {/* <div className="account_view_details_Row">
        <h3 className="my-2 mx-2 dashboard_leadView_company_details_heading">
          Purpose Of Outgoing Call
        </h3>
        <div className="row">
          <div className="col-xl-12">
            <div className="d-xl-flex d-md-flex justify-content-between flex-wrap justify-content-center align-items-center row-cols-3">
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Purpose
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callPurpose}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Agenda
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callAgenda}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Result
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callResult}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       
        <h4 className="my-2 mx-2 dashboard_leadView_company_details_heading">
          Description<span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
        </h4>
        <div className="row">
          <div className="col-xl-12 my-1 mx-2">
            <p className="lead_view_details_description">
              {getSingleLogCallData?.description}
            </p>
          </div>
        </div>
      </div> */}
      </div>
      {/* <div className="account_view_details_Row">
        <h3 className="my-2 mx-2 dashboard_leadView_company_details_heading">
          Purpose Of Outgoing Call
        </h3>
        <div className="row">
          <div className="col-xl-12">
            <div className="d-xl-flex d-md-flex justify-content-between flex-wrap justify-content-center align-items-center row-cols-3">
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Purpose
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callPurpose}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Agenda
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callAgenda}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Result
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLogCallData?.callResult}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       
        <h4 className="my-2 mx-2 dashboard_leadView_company_details_heading">
          Description<span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
        </h4>
        <div className="row">
          <div className="col-xl-12 my-1 mx-2">
            <p className="lead_view_details_description">
              {getSingleLogCallData?.description}
            </p>
          </div>
        </div>
      </div> */}
    </>

  );
};

export default LogCallViewDetails;
