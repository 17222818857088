import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, Link, useNavigate } from "react-router-dom";
//Import React Icon
import { MdOutlineSubtitles } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FaChalkboardUser } from "react-icons/fa6";
import { MdAdd } from "react-icons/md";
import { MeetingFormSchema } from "../../schema/FormValidation";
import { FaTreeCity } from "react-icons/fa6";
//Import CSS
// import "../../styles/dashboardCss/createMeeting.css";
// Import Toast
import Toast from "../../components/Toast";
// Import api function from controller
import { getContacts, createMeeting, updateMeeting } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage";


const CreateMeeting = () => {
  const navigate = useNavigate()
  // Get LeadId
  const location = useLocation();
  const leadId = location.state?.leadId;
  const data = location.state?.data;
  const navigatingFrom = location.state?.from;


  //  location.state?.data || 
  const [showParticipant, setshowParticipant] = useState(false)
  // For Particiapnts 
  const [getAllContactData, setAllContactData] = useState([]);
  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  useEffect(() => {
    (async () => {
      try {
        await getContacts(tokenId).then((res) => {
          setAllContactData(res);
        });
      } catch (error) {
        console.log(error);
      }
    })();  
  }, [tokenId]);

  // Start Toast Code -------
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: '' });
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast])
  const [loading, setLoading] = useState(false);

  const meetId = JSON.parse(localStorage.getItem("meetId"));
  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  } = useFormik({
    initialValues: {
      host: data?.host || userIdTokenData?.data?.fullName,
      title: data ? data.title : "",
      location: data ? data.location : "",
      date: data ? data.date : "", // for date,
      // leadId:data?data.leadId:"",
      leadId: data?.leadId || leadId,
      description: data ? data.description : "",
      participants: data ? data.participants : [],
    },
    validationSchema: MeetingFormSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {

      try {
        if (navigatingFrom == "update") {
          const updates = Object.keys(values).filter(key => {
            if (Array.isArray(values[key])) {
              return values[key].length == data[key].length ? values[key].length ? values[key].some(elm => !data[key].includes(elm)) ? true : false : false : true;
            }
            else {
              return (values[key] && values[key] != data[key]);
            }
          });

          let updatedData = {}
          if (updates.length) {
            updates.forEach(key => updatedData[key] = values[key]);
            await updateMeeting(meetId, updatedData, setShowToast, tokenId);
            setTimeout(() => {
              navigate('/meetings')
            }, 2000);
          }
          else {
            alert("No updated data.")
          }
        } else {
          setLoading(true);
          await createMeeting(uid, values, setShowToast, tokenId);
          if (createMeeting) {
            resetForm();
            setLoading(false);
            setTimeout(() => {
              navigate('/meetings')
            }, 2000);
          }
        }


      } catch (error) {
        console.log("Found Error", error);
        setLoading(false);
      } finally {
        setSubmitting(false)

      }
    },
  });

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (email) => {
    const index = values.participants.indexOf(email);
    if (index === -1) {
      setFieldValue("participants", [...values.participants, email]);
    } else {
      const newParticipants = [...values.participants];
      newParticipants.splice(index, 1);
      setFieldValue("participants", newParticipants);
    }
  };
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };
  // Set Current Date
  //const [currentDate, setCurrentDate] = useState("");
  // useEffect(() => {
  //   const today = new Date();
  //   const yyyy = today.getFullYear();
  //   const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  //   const dd = String(today.getDate()).padStart(2, "0");
  //   const hh = String(today.getHours()).padStart(2, "0");
  //   const min = String(today.getMinutes()).padStart(2, "0");

  //   setCurrentDate(`${yyyy}-${mm}-${dd}T${hh}:${min}`);
  // }, []);

  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <Gobackpage pageName={navigatingFrom == "update" ? "Update Meeting" : "Create Meeting"}/>
     

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2" style={{ position: "relative", marginTop: "10px" }}>
            <label htmlFor="host">
              Host
            </label>
            <input
              type="text"
              id="host"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.host}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="host"
              placeholder="Enter Host Name"
              disabled
            />
            {touched.host && errors.host && (
              <small className="errorMessage">{errors.host}</small>
            )}
            <FaChalkboardUser className="icon" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2" style={{ position: "relative", marginTop: "10px" }}>
            <label htmlFor="title">
              Title <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="title"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.title}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="title"
              placeholder="Enter Title Name"
            />
            {touched.title && errors.title && (
              <small className="errorMessage">{errors.title}</small>
            )}
            <MdOutlineSubtitles className="icon" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2" style={{ position: "relative", marginTop: "10px" }}>
            <label htmlFor="address">
              Address <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="address"
              maxLength={150}
              className="form-control create_lead_form_input"
              value={values.location}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="location"
              placeholder="Enter Address"
            />
            {touched.location && errors.location && (
              <small className="errorMessage">{errors.location}</small>
            )}
            <IoLocationOutline className="create_lead_input_iconnew" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2" style={{ position: "relative", marginTop: "10px" }}>
            <label htmlFor="date">
              Date & Time<span className="required_sign">*</span> &nbsp;
              {/* <small className="text-danger">
                {touched.date && errors.date ? errors.date : null}
              </small> */}
            </label>
            <input
              type="datetime-local"
              id="date"
              // min={currentDate}
              className="form-control create_lead_form_input"
              value={values.date}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="date"
            />
            {touched.date && errors.date && (
              <small className="errorMessage">{errors.date}</small>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2" style={{ position: "relative", marginTop: "10px" }}>
            <label htmlFor="leadId">
              Lead Id<span className="required_sign">*</span>
            </label>
            <br />
            <input
              type="text"
              id="leadId"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.leadId}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadId"
              placeholder="Enter Lead Id"
              disabled
            />
            {touched.leadId && errors.leadId && (
              <small className="errorMessage">{errors.leadId}</small>
            )}
            <FaTreeCity className="icon" />
          </div>
          {/* Participants dropdown */}
          {/* <div className=" create_meeting_mainDiv col-12 col-sm-6 col-md-4 mb-3 px-2 " style={{position:"relative" , marginTop:"10px"}}>
          <label
              htmlFor="participants"
              onClick={()=>setshowParticipant(prev=>!prev)}
              className="btn btn-primary text-white create_meeting_participant_btn"
            >
              <MdAdd /> Add Participants
              <span className="required_sign">*</span>
            </label>
            {showParticipant&&
            <div
              id="participantsCollapse"
              className="create_meeting_collapseDiv"
            > 
              {Array.isArray(getAllContactData?.content) &&
                getAllContactData?.content?.map((option) => (
                  <div key={option.id} className="form-check">
                    {console.log('getAllContactData',option)}
                    <input
                      type="checkbox"
                      id={option.id}
                      className="form-check-input"
                      style={{
                        border:"1px solid #ccc"
                      }}
                      value={option.companyEmail}
                      // checked={values.participants.includes(option.id)}
                      onChange={() => handleCheckboxToggle(option.companyEmail)}
                      checked={values.participants.includes(
                        option.companyEmail
                      )}
                      // onChange={() => handleCheckboxToggle(option.companyEmail)}
                    />
                    <label htmlFor={option.value} className="form-check-label" >
                      {option.companyName}
                    </label>
                  </div>
                ))}
            </div>
}
          </div> */}
          <div className="create_meeting_mainDiv col-12 col-sm-6 col-md-4 mb-3 px-2" style={{ position: "relative", marginTop: "10px" }}>
            {/* Dropdown Button */}
            <button
              type="button"
              onClick={() => setshowParticipant(prev => !prev)}
              className="btn btn-primary text-white create_meeting_participant_btn dropdown-toggle"
              style={{  }}
            >
              Add Participants
              <span className="required_sign">*</span>
            </button>

            {/* Dropdown Menu */}
            {showParticipant && (
              <div id="participantsCollapse" className="dropdown-menu show p-2" style={{  }}>
                {Array.isArray(getAllContactData?.content) &&
                  getAllContactData?.content?.map((option) => (
                    <div key={option.id} className="form-check">
                      {/* Checkbox */}
                      <input
                        type="checkbox"
                        id={option.id}
                        className="form-check-input"
                        style={{
                          border: "1px solid #ccc",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer"
                          
                        }}
                        value={option.companyEmail}
                        onChange={() => handleCheckboxToggle(option.companyEmail)}
                        checked={values.participants.includes(option.companyEmail)}
                      />

                      {/* Label */}
                      <label htmlFor={option.id} className="form-check-label" style={{ marginLeft: "10px", cursor: "pointer" }}>
                        {option.companyName}
                      </label>
                    </div>
                  ))}
              </div>
            )}
          </div>

        </div>
        {/* Description */}
        <div className="row">
          <div className="form-group ">
            <label
              htmlFor="description"
              className="create_lead_section2_description_label"
            >
              Description
            </label>
            <textarea
              id="description"
              maxLength={250}
              className="form-control create_lead_form_input"
              value={values.description}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="description"
              rows="3"
              placeholder={
                touched.description && errors.description
                  ? errors.description
                  : null
              }
            ></textarea>
          </div>
        </div>
        {/* Submit Button */}
        {loading ? (
          <div className="text-center">
            <button className="create_lead_form_submitBtn" disabled={isSubmitting}>Creating...</button>
          </div>
        ) : (
          <div className="text-center">
            <button className="create_lead_form_submitBtn" type="submit" disabled={isSubmitting}>
              {navigatingFrom == "update" ? "Update" : "submit"}
            </button>
          </div>
        )}
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreateMeeting;
