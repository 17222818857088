import React, { useState, useEffect, createContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardSidebar from "../components/common/DashboardSidebar";
import DashboardNavbar from "../components/common/DashboardNavbar";
import Loader from "./Loader";
import pageDetails, { PageDetailsContext } from "../pageDetails";
import { jwtDecode } from "jwt-decode";


//CSS
// import "../styles/main.page.css";
import Logo from "../components/common/logo";
export  const sidebarContext = createContext();
// Controller Api
// import { logoutUser } from "../controller/fetchApi";
const Main = ({ mobileview, loading, setLoading }) => {
  const locattion = useLocation();
  const navigate = useNavigate();
  const [tokenExpired, setTokenExpired] = useState(false);
  const [showSidebarSmallScreen, setShowSidebarSmallScreen] = useState(false);
   const [isSidebar, setIsSidebar] = useState(false);
   useEffect(()=>{
    !mobileview&&setIsSidebar(false);
   },[mobileview])
  // Show Loader
  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {   
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [loading]);

  const [shrinkSidebar, setShrinkSidebar] = useState(false);
  const [updatelogo, setupdatelogo] = useState(false);
  // const sidebarContext = useContext();

useEffect(()=>{
  const getUserAuthData = () => {
    try {
      const userIdTokenData = JSON.parse(localStorage.getItem("user")) || {};
      
      const uid = userIdTokenData?.data?.userId || null;
      const tokenId = userIdTokenData?.data?.token || null;
  
      if (!tokenId) {
        console.warn("No token found.");
        return {isExpired: true };
      }
  
      // Check if token is expired
      const { exp } = jwtDecode(tokenId);
      const isExpired = exp < Math.floor(Date.now() / 1000);
  
      return { uid, tokenId, isExpired };
    } catch (error) {
      console.error("Error parsing user data:", error);
      return {isExpired: true };
    }
  };
   
  const checkExpire = setInterval(() => {
    const {isExpired } = getUserAuthData();
    if(isExpired){
      clearInterval(checkExpire);
     setTokenExpired(true);
    }
  }, 1000);
  
},[])

useEffect(()=>{
if(tokenExpired){
  localStorage.clear();
  navigate("/login")
}
},[tokenExpired])
  return (
    <>
      <DashboardNavbar
       mobileview={mobileview}
        isSidebar={isSidebar}
        updatelogo={updatelogo}
        setupdatelogo={setupdatelogo}
        setIsSidebar={setIsSidebar}
        setShowSidebarSmallScreen={setShowSidebarSmallScreen}
      />
      <div className="d-flex main_dashboard_sidebar_main_div">
        <div  className="left_dashboard_sidebar">
            <DashboardSidebar
              setLoading={setLoading}
              mobileview={mobileview}
              shrinkSidebar={shrinkSidebar}
              isSidebar={isSidebar}
              updatelogo={updatelogo}
              setShrinkSidebar={setShrinkSidebar}
              showSidebarSmallScreen={showSidebarSmallScreen}
              setIsSidebar={setIsSidebar}
            />
        </div> 
        <PageDetailsContext.Provider value={pageDetails}>
        <div className="right_dashboard_outlet">
          <div className="right_dashboard_outlet_main">
            <div style={{width:"100%",overflow:"auto",height:"100%"}}>
            {loading ? <Loader /> : <Outlet />}
            </div>

          </div>
        </div>
        </PageDetailsContext.Provider>
      </div>
    </>
  );
};

export default Main;
