import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
// React Icon
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsBuildingsFill } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { FaLandmarkFlag } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";  
import { IoCallOutline } from "react-icons/io5";

import { registerSchema } from "../schema/FormValidation";
// Controller Api Methods
import { createLead, getcategoryDataApi, updateSingleLead } from "../controller/fetchApi";
// Import Toast
import Toast from "./Toast";
import { useLocation, useNavigate } from "react-router-dom";
import { object } from "yup";
const CreateLeadForm = ({ leadStatus, leadServices, leadSource }) => {
  const [categoryData, setcategoryData] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const leadId = location.state?.leadId;
  const data = location.state?.data;
  const navigatingFrom = location.state?.from;
  // Get TokenId And User Id
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));

  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  // Toast
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  if (showToast.message) {
    setTimeout(() => {
      setShowToast({ success: false, message: "", optmessage: "" });
    }, 3000);
  }
  const validationContext = { validationSchemaNumber: 5 };
  // const validationSchemaNumber = 5;
  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
    resetForm,
    setSubmitting,
  } = useFormik({
    initialValues: {
      // validationSchemaNumber: 5,
      leadOwner: userIdTokenData?.data?.fullName,
      firstName: data ? data?.firstName : "",
      lastName: data ? data?.lastName : "",
      email: data ? data?.email : "",
      mobile: data ? data?.mobile : "",
      mobileAlt: data ? data?.mobileAlt : "",
      leadSource: data ? data?.leadSource : "",
      leadStatus: data ? data?.leadStatus : "",
      annualRevenue: data ? data?.annualRevenue : "",
      companyName: data ? data?.companyName : "",
      companyEmail: data ? data?.companyEmail : "",
      companyContact: data ? data?.companyContact : "",
      secondaryContact: data ? data?.secondaryContact : "",
      city: data ? data?.city : "",
      district: data ? data?.district : "",
      state: data ? data?.state : "",
      country: data ? data?.country : "",
      description: data ? data?.description : "",
      phone: data ? data?.phone : "",
      emailAlt: data ? data?.emailAlt : "",
      subject: data ? data?.subject : "",
      categoryType: data ? data?.categoryType : "",
      categoryValue: data ? data?.categoryValue : "",
      pinCode: data ? data?.pinCode : "",
      address: data ? data?.address : "",
      productName: data ? data?.productName : "",
      priority: data ? data?.priority : "",
      leadType: data ? data?.leadType : "",
    },
    validationSchema: registerSchema(validationContext),
    onSubmit: async (values, { resetForm, setSubmitting, setFieldError }) => {
     
      if (!values.email || (values.email && values.email !== values.emailAlt)) {
        try {
   if(navigatingFrom=="update"){
    
            const updates = Object.keys(values).filter(key=>{ 
              if(Array.isArray(values[key])){ 
          return values[key].length==data[key].length ? values[key].length ? values[key].some(elm=>!data[key].includes(elm))? true:false:false : true;
              }
              else{
                return(values[key]&&values[key]!=data[key]);
              }
            });
  
            let updatedData={}
            if(updates.length){
              updates.forEach(key=>updatedData[key]=values[key]);
              console.log('updatedData updatedData',updatedData)
               await updateSingleLead(updatedData, data?.id, setShowToast, tokenId);
               setTimeout(() => {
                navigate('/leads')
              }, 2000);
            }
            else{
              alert("No updated data.")
            }
           }else{



          const registerSuccessFully = await createLead(
            values,
            uid,
            setShowToast,
            tokenId
          );

          if (registerSuccessFully?.data?.status === 200) {
            setTimeout(() => {
              navigate("/leads");
            }, 2000);

            resetForm();
          }
        }
        } catch (error) {
          console.log("Found Error", error);
        } finally {
          setSubmitting(false);
        }
      } else {
        setFieldError("emailAlt", "Please enter different email");
      }
    },
  });

  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  const getcategoryData = useCallback(async () => {
    const getcategData = await getcategoryDataApi(
      values?.categoryType,
      tokenId
    );
    if (getcategData?.status === 200) {
      setcategoryData(getcategData?.data?.data?.content);
    }
  }, [values.categoryType]);

  useEffect(() => {
    if (values?.categoryType) {
      getcategoryData();
    }
  }, [getcategoryData]);

  return (
    <div className="create_lead_form_main_div">
      <form onSubmit={handleSubmit}>
        {/*   Heading */}
        <div className="create-lead-heading">
          <div>
            <p className="create_lead_section2_company_info"> </p>
          </div>
          {/* <div className="create-lead-heading-right-part">
            <p className="create-lead-heading-right-para">
              Lead Name :{" "}
              <span className="create-lead-heading-span">Pankaj Swami Vaishnav</span>
            </p>
            <p className="create-lead-heading-right-para">
              Lead Id : <span className="create-lead-heading-span">2024</span>
            </p>
          </div> */}
        </div>
        {/* User Information */}
        <div className="row">
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="leadOwner" style={{ marginBottom: "5px" }}>
              Lead Owner<span className="required_sign">*</span>
            </label>
            <input
              disabled
              type="text"
              id="leadOwner"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.leadOwner}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadOwner"
              placeholder="Enter Name"
            />
            {touched.leadOwner && errors.leadOwner && (
              <small className="errorMessage">{errors.leadOwner}</small>
            )}
            <FaRegUser className="icon_new"  />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="firstName" style={{ marginBottom: "5px" }}>
              First Name<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.firstName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="firstName"
              placeholder="Enter First Name"
            />
            {touched.firstName && errors.firstName && (
              <small className="errorMessage">{errors.firstName}</small>
            )}
               <FaRegUser className="icon_new"  />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="lastName" style={{ marginBottom: "5px" }}>
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.lastName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="lastName"
              placeholder="Enter Last Name"
            />
            {touched.lastName && errors.lastName && (
              <small className="errorMessage">{errors.lastName}</small>
            )}
              <FaRegUser className="icon_new"  />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="email" style={{ marginBottom: "5px" }}>
              Email
            </label>
            <input
              type="email"
              id="email"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.email}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="email"
              placeholder="Enter Your Email "
            />
            {touched.email && errors.email && (
              <small className="errorMessage">{errors.email}</small>
            )}
            <CiMail  className="create_lead_input_iconnew" style={{marginTop:"5px"}}  />
          </div>
          {/*   Alternative Email  */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="emailAlt" style={{ marginBottom: "5px" }}>
              Alternative Email
            </label>
            <input
              type="email"
              id="emailAlt"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.emailAlt}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="emailAlt"
              placeholder="Enter Alternative Email "
            />
            {touched.emailAlt && errors.emailAlt && (
              <small className="errorMessage">{errors.emailAlt}</small>
            )}
               <CiMail  className="create_lead_input_iconnew" style={{marginTop:"5px"}}  />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="mobileNumber" style={{ marginBottom: "5px" }}>
              Mobile Number <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="mobileNumber"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobile}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobile"
              placeholder="Enter Mobile Number"
            />
            {touched.mobile && errors.mobile && (
              <small className="errorMessage">{errors.mobile}</small>
            )}
              <IoCallOutline className="create_lead_input_iconnew"  style={{marginTop:"5px"}}  />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label
              htmlFor="secondaryMobileNumber"
              style={{ marginBottom: "5px" }}
            >
              Secondary Mobile Number
            </label>

            <input
              type="text"
              id="secondaryMobileNumber"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobileAlt}
              onChange={handleChange}
              minLength="10"
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobileAlt"
              placeholder="Secondary Mobile Number"
            />
            {touched.mobileAlt && errors.mobileAlt && (
              <small className="errorMessage">{errors.mobileAlt}</small>
            )}
             <IoCallOutline className="create_lead_input_iconnew"  style={{marginTop:"5px"}}  />
          </div>

          {/* <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="phone" style={{marginBottom:"5px"}}>
            Phone Number 
            </label>
            <input
              type="text"
              id="phone"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.phone}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="phone"
              placeholder="Enter Mobile Number"
            />
            {touched.phone && errors.phone && (
              <small className="errorMessage">{errors.phone}</small>
            )}
            <FaPhone className="create_lead_input_icon createform_iconstop" />
          </div> */}

          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="phoneAlt">
            Alternative Phone Number <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="phoneAlt"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.phoneAlt}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="phoneAlt"
              placeholder="Enter Mobile Number"
            />
            {touched.phoneAlt && errors.phoneAlt && (
              <small className="errorMessage">{errors.phoneAlt}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
          */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="leadSource" style={{ marginBottom: "5px" }}>
              Lead Source <span className="required_sign">*</span>
            </label>
            <select
              id="leadSource"
              className="form-control create_lead_form_input"
              value={values.leadSource}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadSource"
              style={{ color: "#666565" }}
            >
              <option value="" hidden>
                Select Lead Source
              </option>
              {leadSource && leadSource?.length > 0
                ? leadSource?.map((source) => (
                    <option key={source?.id} value={source?.value}>
                      {source?.leadSource}
                    </option>
                  ))
                : ""}
            </select>
            {touched.leadSource && errors.leadSource && (
              <small className="errorMessage">{errors.leadSource}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon createform_iconstop" />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2 " style={{position:"relative" ,marginTop:"10px" }}>
            <label htmlFor="leadStatus" style={{ marginBottom: "5px" }}>
              Lead Status <span className="required_sign">*</span>
            </label>
            <select
              id="leadStatus"
              className="form-control create_lead_form_input"
              value={values.leadStatus}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadStatus"
              style={{ color: "#666565" }}
            >
              <option value="" hidden>
                Select Lead Status
              </option>
              <option value="New">New</option>
              <option value="Interested">Interested</option>
              <option value="Not Interested">Not Interested</option>
              <option value="No Response">No Response/Buy</option>
              <option value="Requested Info">Requested More Info</option>
              <option value="Requested Call Back">Requested Call Back</option>
              <option value="Invalid Number">Invalid Number</option>

              {/* {leadStatus && leadStatus?.length > 0
                ? leadStatus?.map((lead) => (
                    <option key={lead.id} value={lead.value}>
                      {lead.leadSource}
                    </option>
                  ))
                : ""} */}
            </select>
            {touched.leadStatus && errors.leadStatus && (
              <small className="errorMessage">{errors.leadStatus}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon createform_iconstop" />
          </div>

          {/* <div className="form-group createLeadInput col-xl-4 costum-select">
            <label htmlFor="leadStatus">
              Lead Service <span className="required_sign">*</span>
            </label>

            <select
              id="leadService"
              className="form-control"
              value={values.leadService}
              onChange={handleChange}
              onFocus={handleFocus} 
              onBlur={handleBlur}
              name="leadService"
              style={{color:"#666565"}}
            >
              <option value="" hidden>
                Select Lead Service
              </option>

              {leadServices && leadServices?.length > 0
                ? leadServices?.map((services) => ( 
                    <option key={services.id} value={services.leadSoruce}>
                      {services.leadSoruce}
                    </option>
                  ))
                : ""}
            </select>
            {touched.leadService && errors.leadService && (
              <small className="errorMessage">{errors.leadService}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />  
          </div> */}

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="subject" style={{ marginBottom: "5px" }}>
              Subject <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="subject"
              maxLength={100}
              className="form-control create_lead_form_input"
              value={values.subject}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="subject"
              placeholder="Enter Subject"
            />
            {touched.subject && errors.subject && (
              <small className="errorMessage">{errors.subject}</small>
            )}
          </div>
          {/* product category */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="callTo" style={{marginBottom:"5px"}}>
              Category <span className="required_sign">*</span>
            </label>
            <div className=" create_call_form_new " style={{marginTop:"4px"}} >
              <div>
                <select
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: "15px",
                  }}
                  className="create_lead_form_input"
                  id="callTo"
                  value={values.categoryType}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  name="categoryType"
                >
                  <option value="" hidden>
                    Category
                  </option>
                  <option value="product">Product</option>
                  <option value="service">Service</option>
                </select>
              </div>
              <div
                style={{
                  width: "100%",
                  borderLeft: "1px solid rgba(128, 128, 128, 0.24)",
                  position: "relative",
                }}
              >
                {/* category options */}
                <select
                  disabled={!values.categoryType}
                  id="leadSource"
                  className="form-control"
                  value={values.categoryValue}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  name="categoryValue"
                  style={{ color: "#666565", border: "none" }}
                >
                  <option value="" hidden>
                    Please select category type
                  </option>
                  {categoryData && categoryData?.length > 0
                    ? categoryData?.map((source) => (
                        <option key={source?.id} value={source?.name}>
                          {source?.name}
                        </option>
                      ))
                    : ""}
                  <option value="other">Others</option>
                </select>
              </div>
            </div>
           
            {touched.categoryValue && errors.categoryValue && (
              <small className="errorMessage">{errors.categoryValue}</small>
            )}
          </div>

          {/* <div className="form-group createLeadInput col-xl-4"> 
            <label htmlFor="queryMcatName">
            queryMcat Name <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="queryMcatName"
              className="form-control create_lead_form_input"
              value={values.queryMcatName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="queryMcatName"
              placeholder="Enter queryMcatName"
            />
            {touched.queryMcatName && errors.queryMcatName && (
              <small className="errorMessage">{errors.queryMcatName}</small>
            )}
          </div> */}

          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="productName" style={{marginBottom:"5px"}}>
            Product Name 
            </label>
            <input
              type="text"
              id="productName"
              maxLength={100}
              className="form-control create_lead_form_input"
              value={values.productName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="productName"
              placeholder="Enter product name"
            />
            {touched.productName && errors.productName && (
              <small className="errorMessage">{errors.productName}</small>
            )}
            
          </div>
   */}
          {/*priority  */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="priority" style={{ marginBottom: "5px" }}>
              Priority <span className="required_sign">*</span>
            </label>
            <select
              id="priority"
              className="form-control"
              value={values.priority}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="priority"
              style={{ color: "#666565" }}
            >
              <option value="" hidden>
                Priority
              </option>
              <option value="high">High</option>
              <option value="normal">Normal</option>
              <option value="medium">Medium</option>
            </select>
            {touched.priority && errors.priority && (
              <small className="errorMessage">{errors.priority}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon createform_iconstop" />
          </div>
          {/* lead type */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2 " style={{position:"relative" , marginTop:"10px" , }}>
            <label htmlFor="leadType" style={{ marginBottom: "5px" }}>
              Lead Type <span className="required_sign">*</span>
            </label>
            <select
              id="leadType"
              className="form-control"
              value={values.leadType}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadType"
              style={{ color: "#666565" }}
            >
              <option value="" hidden>
                Lead Type
              </option>
              <option value="unverified">Unverified</option>
              <option value="verified">Verified</option>
            </select>
            {touched.leadType && errors.leadType && (
              <small className="errorMessage">{errors.leadType}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon createform_iconstop" />
          </div>
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callDuration">
            Call Duration <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callDuration"
              className="form-control create_lead_form_input"
              value={values.callDuration}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="callDuration"
              placeholder="Enter callDuration"
            />
            {touched.callDuration && errors.callDuration && (
              <small className="errorMessage">{errors.callDuration}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div> */}
        </div>

        <div className="row">
          <p className="create_lead_section2_company_info">Company Details</p>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="annualRevenue" style={{ marginBottom: "5px" }}>
              Annual Revenue
            </label>
            <input
              type="number"
              id="annualRevenue"
              className="form-control create_lead_form_input"
              value={values.annualRevenue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="annualRevenue"
              placeholder="Enter Annual Revenue"
            />
            {touched.annualRevenue && errors.annualRevenue && (
              <small className="errorMessage">{errors.annualRevenue}</small>
            )}
            <BsCurrencyRupee className="icon_new" />
          </div>
          {/* annual revenue */}
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="annualRevenue" style={{marginBottom:"5px"}}>
            Annual Revenue
            </label>
            <select
              id="priority"
              className="form-control"
              value={values.annualRevenue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="annualRevenue"
              style={{color:"#666565"}}
            >
              <option value="" hidden> Annual Revenue</option>
             <option value="1 lakh - 10 lakh">1 lakh to 10 lakh</option>
             <option value="10 lakh to 50 lakh">10 lakh to 50 lakh</option>
             <option value="50 lakh to 1 cr">50 lakh to 1 cr</option>
             <option value="1 cr to 5 cr">1 cr to 5 cr</option>
            </select>
            {touched.priority && errors.priority && (
              <small className="errorMessage">{errors.priority}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div> */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="companyName" style={{ marginBottom: "5px" }}>
              Company Name
            </label>
            <input
              type="text"
              id="companyName"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.companyName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyName"
              placeholder="Enter Company Name"
            />
            {touched.companyName && errors.companyName && (
              <small className="errorMessage">{errors.companyName}</small>
            )}
            <BsBuildingsFill className="icon_new" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="companyEmail" style={{ marginBottom: "5px" }}>
              Company Email
            </label>
            <input
              type="email"
              id="companyEmail"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.companyEmail}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyEmail"
              placeholder="Enter Comapny Email"
            />
            {touched.companyEmail && errors.companyEmail && (
              <small className="errorMessage">{errors.companyEmail}</small>
            )}
            <CiMail className="create_lead_input_icon createform_iconstop" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="companyContact" style={{ marginBottom: "5px" }}>
              Company Contact
            </label>
            <input
              type="text"
              id="companyContact"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.companyContact}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyContact"
              placeholder="Enter Company Contact"
            />
            {touched.companyContact && errors.companyContact && (
              <small className="errorMessage">{errors.companyContact}</small>
            )}
            <IoCallOutline className="create_lead_input_icon createform_iconstop" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="secondaryContact" style={{ marginBottom: "5px" }}>
              Secondary Contact{" "}
            </label>
            <input
              type="text"
              id="secondaryContact"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.secondaryContact}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="secondaryContact"
              placeholder="Enter Secondary Contact"
            />
            {touched.secondaryContact && errors.secondaryContact && (
              <small className="errorMessage">{errors.secondaryContact}</small>
            )}
            <IoCallOutline className="create_lead_input_icon" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="city" style={{ marginBottom: "5px" }}>
              City
            </label>
            <input
              type="text"
              id="city"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.city}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="city"
              placeholder="Enter City"
            />
            {touched.city && errors.city && (
              <small className="errorMessage">{errors.city}</small>
            )}
            <FaTreeCity className="icon_new" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="district" style={{ marginBottom: "5px" }}>
              District
            </label>
            <input
              type="text"
              id="district"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.district}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="district"
              placeholder="Enter District"
            />
            {touched.district && errors.district && (
              <small className="errorMessage">{errors.district}</small>
            )}
            <FaTreeCity className="icon_new" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="state" style={{ marginBottom: "5px" }}>
              State
            </label>
            <input
              type="text"
              id="state"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.state}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="state"
              placeholder="Enter State"
            />
            {touched.state && errors.state && (
              <small className="errorMessage">{errors.state}</small>
            )}
            <FaTreeCity className="icon_new" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="country" style={{ marginBottom: "5px" }}>
              Country
            </label>
            <input
              type="text"
              id="country"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.country}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="country"
              placeholder="Enter Country Name"
            />
            {touched.country && errors.country && (
              <small className="errorMessage">{errors.country}</small>
            )}
            <FaLandmarkFlag className="icon_new" />
          </div>

          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="pinCode" style={{ marginBottom: "5px" }}>
              Pin Code
            </label>
            <input
              type="text"
              id="pinCode"
              maxLength={6}
              className="form-control create_lead_form_input"
              value={values.pinCode}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="pinCode"
              placeholder="Enter Pin Code"
            />
            {touched.pinCode && errors.pinCode && (
              <small className="errorMessage">{errors.pinCode}</small>
            )}
            <FaLandmarkFlag className="icon_new" />
          </div>
          {/*   
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="address" style={{marginBottom:"5px"}}>Address</label>
            <input
              type="text"
              id="address"
              maxLength={100}
              className="form-control create_lead_form_input"
              value={values.address}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="address"
              placeholder="Enter address"
            />
            {touched.address && errors.address && (
              <small className="errorMessage">{errors.address}</small>
            )}
            <FaLandmarkFlag className="create_lead_input_icon" />
          </div> */}
        </div>
        {/* Description */}
        <div className="row">
          <p className="create_lead_section2_description">
            Description <span className="required_sign">*</span>
          </p>
          <div
            className="form-group col-sm-12 col-md-10 col-xl-12"
            style={{ position: "relative" }}
          >
            {/* <label
              htmlFor="description"
              className="create_lead_section2_description_label"
              style={{marginBottom:"5px"}}
            >
              Description
            </label> */}
            <textarea
              id="description"
              maxLength={150}
              className="form-control create_lead_form_input"
              value={values.description}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="description"
              rows="3"
              placeholder="Enter Description"
            ></textarea>
            {touched.description && errors.description && (
              <small className="errorMessage">{errors.description}</small>
            )}
          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={isSubmitting}
          >
            {navigatingFrom=="update" ? "Update" : "submit"}
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreateLeadForm;
