import React, { useEffect, useState } from "react";
// import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
// css
// import "../styles/signup.page.css";
// import "../styles/login.page.css";

// React Icons
import { FcFeedback } from "react-icons/fc";
import { IoArrowBackCircleOutline } from "react-icons/io5";

// Formik
import { replace, useFormik } from "formik";
import { loginFormSchema } from "../schema/FormValidation";

// Imags
// import orLogin from "../images/orLogin.jpg";
import loginImg from "../images/login-image.png";

// Api Call & Function
// import { loginUser, loginUserThroughGoogle } from "../controller/fetchApi";
import { loginUser } from "../controller/fetchApi";
import Toast from "../components/Toast";
import { MdEmail } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import { BiShow } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setUserData } from "../app/slices";

// import { useAuth0 } from "@auth0/auth0-react";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";

const Login = ({ mobileview }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Toast

  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: loginFormSchema,
    validateOnChange: true,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const loginSuccessFully = await loginUser(values, setShowToast);
      
      if (loginSuccessFully?.status == 200) {
       
        dispatch(setUserData(loginSuccessFully?.data?.data?.currentUser));

        const userRole = loginSuccessFully?.data?.data?.roleAndPermissions?.roles[0]?.role;
        const pinStatus = loginSuccessFully?.data?.data?.pinStatus;
        
        userRole === "SUPERADMIN"
          ? navigate("/companylist", { replace: true, state: { pin: !pinStatus} })
          : navigate("/dashboard", { replace: true, state: { pin: !pinStatus} });

        setSubmitting(false);
        resetForm();
      }
      // const userRole =
      //   loginSuccessFully &&
      //   loginSuccessFully?.data?.data?.roleAndPermissions?.roles[0]?.role;

      // if (
      //   loginSuccessFully?.status === 200 &&
      //   loginSuccessFully?.data?.data?.pinStatus === true
      // ) {
      //   userRole === "SUPERADMIN"
      //     ? navigate("/companylist", { replace: true })
      //     : navigate("/dashboard", { replace: true });
      // } else if (
      //   loginSuccessFully?.status === 200 &&
      //   loginSuccessFully?.data?.data?.pinStatus === false
      // ) {
      //   userRole === "SUPERADMIN"
      //     ? navigate("/companylist", { replace: true, state: { pin: true } })
      //     : navigate("/dashboard", { replace: true, state: { pin: true } });
      // }

      // setSubmitting(false);
      // resetForm();
    },
  });

  // Show & Hide Password
  const [showPassword, setShowPassword] = useState(true);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  const [smallscreen, setsmallscreen] = useState(window.innerWidth <= 600);
  useEffect(() => {
    const mobileview = () => [setsmallscreen(window.innerWidth <= 600)];
    window.addEventListener("resize", mobileview);

    return () => window.removeEventListener("resize", mobileview);
  });

  return (
    <div className="container-fluid LeadRightSectionTable_body table-responsive">
      <div className="signup_firstbox">
        <div className=" signup_left_container">
          <div id="signup_left_mainDiv " className="login_left_mainDiv">
            <form onSubmit={handleSubmit} className="form_field">
              <p className=" signup_text_in_ ">Login</p>
              <div className="formGroup">
                <div className="signup_input_div loginPageMainDiv">
                  <div className="mb-3 position-relative">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label signup_div_input"
                    >
                      User Email.
                    </label>
                    <input
                      type="email"
                      maxLength={64}
                      className={`form-control signup_email_form_control login_page_input ${
                        errors.email && touched.email ? "signup_input_form" : ""
                      }`}
                      id="exampleFormControlInput1"
                      name="email"
                      placeholder="John@example.com"
                      value={values.email}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email && (
                      <small className="loginPageErrorMessage">
                        {errors.email}
                      </small>
                    )}

                    <MdEmail />
                  </div>
                </div>

                <div className="signup_input_div loginPageMainDiv">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label signup_div_input"
                    >
                      Password <span className="required_sign"></span>
                    </label>
                    <input
                      maxLength={15}
                      type={showPassword ? "password" : "text"}
                      className={`form-control signup_email_form_control login_page_input ${
                        errors.password && touched.password
                          ? "signup_input_form"
                          : ""
                      }`}
                      id="exampleFormControlInput2"
                      placeholder="************"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {showPassword ? (
                      <FaRegEyeSlash onClick={handleClickShowPassword} />
                    ) : (
                      <BiShow onClick={handleClickShowPassword} />
                    )}
                  </div>
                  {/* <br /> */}
                  <div>
                    <Link
                      className="login_forgot_password_link"
                      to="/forgotpassword"
                    >
                      <span className="login_forgot_password">
                        Forgot Password?
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="signup_submit_div">
                <button
                  type="submit"
                  className=" signup_submit_button"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* image */}
        {!smallscreen && (
          <div className="signup_mainbox">
            <div className="login_mainimg">
              <img
                src={loginImg}
                alt="signin_random_img"
                className="img-fluid"
              />
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default Login;
