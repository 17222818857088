import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { leadStatusUpdateSchema } from "../../schema/FormValidation";
import { updateSingleLead, verifyLeads } from "../../controller/fetchApi";

function LeadStatusupdate({rect, setupdatedLeadStatus,setupdateStatus, leadId ,setShowToast}) {
 
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;

  const {values,errors,touched,handleFocus,handleBlur,handleChange,isSubmitting,setSubmitting,handleSubmit} = useFormik({
    initialValues:{
      leadStatus:"",
      leadRemark:''
    },
    validationSchema:leadStatusUpdateSchema,
    onSubmit: async (values,{resetForm,setSubmitting})=>{
       const response = await updateSingleLead(values,leadId, setShowToast,tokenId);
       await verifyLeads([leadId], setShowToast, tokenId);
       setupdatedLeadStatus(true)
    }

  })
  useEffect(() => {
    document.addEventListener("click", () => { 
      setupdateStatus(null);
    });

    return () => {
      document.addEventListener("click", () => {
        setupdateStatus(null);
      });
    };
  });
 
  return (
    <div style={{top:rect.top-240>150?"-230px":"89%"}}
      className="lead_status_update"
      onClick={(event) => event.stopPropagation()}
    >
     
      <form onSubmit={handleSubmit} style={{width:"90%"}}>
      <label htmlFor="leadStatus_update" >Update staus:</label>
      <div className="form-group col-xl-12" style={{ position: "relative" ,margin:"0px 0px 9px 0px"}}>
        {/* <label htmlFor="leadStatus_update" style={{marginBottom:'5px'}}>Update staus:</label> */}
        <select
          id="leadStatus_update"
          className="form-control"
          name="leadStatus"
          defaultValue=""
          onChange={handleChange}
          value={values?.leadStatus||""}
        >
          <option value="" hidden>
            Update status 
          </option>
          <option value="Interested">Interested</option>
          <option value="Not Interested">Not Interested</option>
          <option value="No Response">No Response/Buy</option>
          <option value="Requested Info">Requested More Info</option>
          <option value="Requested Call Back">Requested Call Back</option>
          <option value="Invalid Number">Invalid Number</option>
          <option value="others">Others</option>
        </select>
        {errors.leadStatus&&<small style={{color:"red",fontSize:"12px",position:"absolute",top:"27px"}}>{errors.leadStatus}</small>}
        {/* <MdKeyboardArrowDown className="updsate_lead_status create_lead_input_icon"/> */}
        {/* {formik.touched.callResult && formik.errors.callResult&&(<small className="errorMessage">{formik.errors.callResult}</small>)} */}
      </div>
      <label htmlFor="lead_remark">Remark:</label>
      <div className="update_formsection">
       
        <textarea
          cols={30}
          value={values?.leadRemark}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          name="leadRemark"
          id="lead_remark"
        ></textarea>
      </div>
       <div className="updatebtn_section">
        <button type="submit" className="lead_update_status_button">Save</button>
       </div>
      </form>
    </div>
  );
}

export default LeadStatusupdate;


