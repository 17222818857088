import React, { useState, useEffect, useContext } from "react";
import leadIdContext from "../LeadIdContext";
import { FiX } from "react-icons/fi";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
//Import CSS
// import "../../styles/dashboardCss/leads.css";
//Import Components
import FilterSidebar from "../../components/LeadsLeftSection";
import LeadsRightSection from "../../components/LeadsRightSection";
import filter_icon from "../../images/filter_icon.png";
import { PageDetailsContext } from "../../pageDetails";
import Goback from "../../components/goback";
import { MdOutlineClear } from "react-icons/md";


const Leads = () => {
  const location = useLocation();

  const [leadCostumerId, setLeadCostumerId] = useState([]);
  const [filter, setFilter] = useState(false);
  const filterType = location.state?.lead;


  const [filterData, setFilterData] = useState(()=>( localStorage.getItem("filterData") ? JSON.parse(localStorage.getItem("filterData")) : 
    {
    leadType: filterType || "",
    filterBy: "",
    filterValue: "",
    dateRange: "",
    customStartDate: "",
    customEndDate: "",
    leadStatus: "",
  }));
   
  useEffect(()=>{
    localStorage.setItem("filterData", JSON.stringify(filterData))
  },[filterData])
  return (
    <div className="container-fluid dashboard_leads_main_container">
      <div
        className="dashboard_filter_sidebar_row"
        style={{ position: "relative" }}
      >
        {/* filter */}
        <div
          className={`filter_sidebar_col col-md-3 ${
            filter && "filter_sidebar_col_display"
          }`}
        >
          <div
            className={`filter_sidebar_mainDiv`}
            onClick={(Event) => Event.stopPropagation()}
          >
            <div style={{display:"flex", justifyContent:"space-between" , }}>
            <h2 style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>Filters</h2>
              <div
              onClick={() => setFilter(false)}
              style={{
                alignSelf: "flex-start",
                backgroundColor: "#d1d0cd",
                padding: "2px 5px",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              <FiX />
            </div>
            </div>
            
            {/* clear filter */}
            {/* <div style={{display:"flex",alignItems:"center",width:"100%",flexWrap:"wrap"}}>
              {filterData.leadType && (
                <div className="filter_clear flex_center">
                  <span className="flex_center">{filterData.leadType}</span>
                  <span className="flex_center">
                    <MdOutlineClear
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setFilterData((prev) => ({ ...prev, leadType: "" }))
                      }
                    />
                  </span>
                </div>
              )}
              {filterData.filterValue && (
                <div className="filter_clear flex_center">
                  <span className="flex_center">{filterData.filterValue}</span>
                  <span className="flex_center">
                    <MdOutlineClear
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setFilterData((prev) => ({ ...prev, filterValue: "" }))
                      }
                    />
                  </span>
                </div>
              )}
              {filterData.leadStatus && (
                <div className="filter_clear flex_center">
                  <span className="flex_center">{filterData.leadStatus}</span>
                  <span className="flex_center">
                    <MdOutlineClear
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setFilterData((prev) =>{ 
                          return { ...prev, leadStatus: "" };
                        })
                      }
                    />
                  </span>
                </div>
              )}
            </div> */}

            <FilterSidebar
              setFilter={setFilter}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </div>
        </div>

        {/* Right Section Who's Section Have Tables Form Data */}
        <div className="col-xl-12 col-md-12 p-0" style={{ maxHeight: "100%" }}>
          <leadIdContext.Provider value={{ leadCostumerId, setLeadCostumerId }}>
            <LeadsRightSection
              setFilter={setFilter}
              leadCostumerId={leadCostumerId}
              setLeadCostumerId={setLeadCostumerId}
              filterData={filterData}
            />
          </leadIdContext.Provider>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Leads;
