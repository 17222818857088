import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
// React Icon
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsBuildingsFill } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { FaLandmarkFlag } from "react-icons/fa6";
import { updateRegisterSchema } from "../schema/FormValidation";
// Controller Api Methods
import { updateSingleLead } from "../controller/fetchApi";
// Import Redux useSelector
import { useSelector } from "react-redux";
// Import Toast
import Toast from "./Toast";
// import Context
const CreateUpdateForm = ({ leadCostumerId, defaultValue, getLeadsData ,leadData,setupdatedLead,setupdatelead}) => {  
 
 
  // Get Lead Dropdown Data from redux store
  const { leadStatus, leadSources } = useSelector(
    (state) => state.dropDown
  ); 

  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  // Toast
  const [showToast, setShowToast] = useState({ success: false, message: "" });

  if(showToast.message){
    setTimeout(() => {
      setShowToast({ success: false, message: "" })
    }, 4000);
  }

  
  // Form Handle & Validations
  const formik = useFormik({
    initialValues: {
      leadOwner:leadData.leadOwner || "",
      firstName:leadData.firstName || "", 
      lastName:leadData.lastName || "",
      email: leadData.email || "",
      mobileNumber: leadData.mobile || "",
      mobileAlt:  leadData.mobileAlt || "",
      leadSource:leadData.leadSource || "",
      leadStatus: leadData.leadStatus || "",
      annualRevenue: leadData.annualRevenue || "",
      companyName: leadData.companyName || "",
      companyEmail: leadData.companyEmail || "",
      companyContact: leadData.companyContact || "",
      secondaryContact: leadData.secondaryContact || "",
      city: leadData.city || "",
      district: leadData.district || "",
      state: leadData.state || "",
      country: leadData.country || "",
      description: leadData.description || "",
      phone: leadData.phone || "",
      phoneAlt: leadData.phoneAlt || "",
      emailAlt: leadData.emailAlt || "",
      queryMessage: leadData.queryMessage || "",
      subject: leadData.subject || "",
      pinCode: leadData.pinCode || "",
      address: leadData.address || "",
      countryIso: leadData.countryIso || "",
      productName: leadData.productName || "",
      queryMcatName: leadData?.queryMcatName || "",
      callDuration: leadData.callDuration || "",
    },

    validationSchema: updateRegisterSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const updates = Object.keys(values).filter(key=>(values[key]&&values[key]!=leadData[key]));
      let updatedData={}
      try {
        if(updates.length){
          updates.forEach(key=>updatedData[key]=values[key]);
         
        const res = await updateSingleLead(values, leadCostumerId, setShowToast, tokenId);
       
        if (res.data?.status===200) {
          setupdatedLead(true);
          setTimeout(() => {
            setupdatelead(false);
          }, 3000);
        
        }
      }
      } catch (error) {
        console.log("Found Error", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

 

  // useEffect(() => {
  //   if (defaultValue) {
  //     formik.setValues({
  //       ...formik.values,
  //       leadOwner: defaultValue.leadOwner || "",
  //       firstName: defaultValue.firstName || "",
  //       lastName: defaultValue.lastName || "",
  //       email: defaultValue.email || "",
  //       mobileNumber: defaultValue.mobile || "",
  //       secondaryMobileNumber: defaultValue.secondaryMobile || "",
  //       leadSource: defaultValue.leadSource || "",
  //       leadStatus: defaultValue.leadStatus || "",
  //       annualRevenue: defaultValue.annualRevenue || "",
  //       companyName: defaultValue.companyName || "",
  //       companyEmail: defaultValue.companyEmail || "",
  //       companyContact: defaultValue.companyContact || "",
  //       secondaryContact: defaultValue.secondaryContact || "",
  //       city: defaultValue.city || "",
  //       district: defaultValue.district || "",
  //       state: defaultValue.state || "",
  //       country: defaultValue.country || "",
  //       description: defaultValue.description || "",
  //       emailAlt: defaultValue?.emailAlt || "",
  //       queryMessage: defaultValue?.queryMessage || "",
  //       subject: defaultValue?.subject || "",
  //       pinCode: defaultValue?.pinCode || "",
  //       address: defaultValue?.address || "",
  //       countryIso: defaultValue?.countryIso || "",
  //       productName: defaultValue?.productName || "",
  //       queryMcatName: defaultValue?.queryMcatName || "",
  //       callDuration: defaultValue?.callDuration || "",
       
  //     });
  //   }
  // }, [defaultValue]);

  return (
    <div className="create_lead_form_main_div">
      <form onSubmit={formik.handleSubmit}>
        {/* User Information */}
        <div className="row">
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="leadOwner">Lead Owner</label>
            <input
              type="text"
              id="leadOwner"
              className="form-control create_lead_form_input"       
              value={formik.values.leadOwner}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="leadOwner"
              placeholder={
                formik.touched.leadOwner && formik.errors.leadOwner
                  ? formik.errors.leadOwner
                  : null
              }
            />
            <MdAdminPanelSettings className="create_lead_input_iconnew" />
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.leadOwner && formik.errors.leadOwner
                  ? formik.errors.leadOwner
                  : null}
              </small>
            }
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              className="form-control create_lead_form_input"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="firstName"
              placeholder={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
            />
            <FaUserTie className="create_lead_input_iconnew" />
            {
              <small style={{ color: "red", position: "absolute" }}>
                {formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null}
              </small>
            }
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              className="form-control create_lead_form_input"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="lastName"
              placeholder={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
            />
            <FaUserTie className="create_lead_input_iconnew" />
            {
              <small style={{ color: "red", position: "absolute" }}>
                {formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null}
              </small>
            }
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-control create_lead_form_input"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              placeholder={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
            {
              <small style={{ color: "red", position: "absolute" }}>
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null}
              </small>
            }
            <MdEmail className="create_lead_input_iconnew" />
          </div>

          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="emailAlt">Alternative Email</label>
            <input
              type="email"
              id="emailAlt"
              className="form-control create_lead_form_input"
              value={formik.values.emailAlt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="emailAlt"
              placeholder={
                formik.touched.emailAlt && formik.errors.emailAlt
                  ? formik.errors.emailAlt
                  : null
              }
            />
            {
              <small style={{ color: "red", position: "absolute" }}>
                {formik.touched.emailAlt && formik.errors.emailAlt
                  ? formik.errors.emailAlt
                  : null}
              </small>
            }
            <MdEmail className="create_lead_input_iconnew" />
          </div>

          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="mobileNumber">Mobile Number</label>
            <input
              type="text"
              id="mobileNumber"
              className="form-control create_lead_form_input"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="mobileNumber"
              placeholder={
                formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? formik.errors.mobileNumber
                  : null
              }
            />
            <FaPhone className="create_lead_input_iconnew" />
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? formik.errors.mobileNumber
                  : null}
              </small>
            }
          </div>

          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="mobileAlt">
              Secondary Mobile Number
            </label>
            <input
              type="text"
              id="mobileAlt"
              className="form-control create_lead_form_input"
              value={formik.values.mobileAlt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="mobileAlt"
              placeholder={
                formik.touched.mobileAlt &&
                formik.errors.mobileAlt
                  ? formik.errors.mobileAlt
                  : null
              }
            />
            <FaPhone className="create_lead_input_iconnew" />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.mobileAlt &&
              formik.errors.mobileAlt
                ? formik.errors.mobileAlt
                : null}
            </small>
          </div>
{/* 
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="text"
              id="phone"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone"
              placeholder={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null
              }
            />
            <FaPhone className="create_lead_input_iconnew" />
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : null}
              </small>
            }
          </div>

          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="phoneAlt">Alternative Phone Number</label>
            <input
              type="text"
              id="phoneAlt"
              className="form-control create_lead_form_input"
              value={formik.values.phoneAlt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phoneAlt"
              placeholder={
                formik.touched.phoneAlt && formik.errors.phoneAlt
                  ? formik.errors.phoneAlt
                  : null
              }
            />
            <FaPhone className="create_lead_input_iconnew" />
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.phoneAlt && formik.errors.phoneAlt
                  ? formik.errors.phoneAlt
                  : null}
              </small>
            }
          </div> */}
          {/* Lead Sources Drop-down */}
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="leadSource">Lead Source</label>
            <select
              id="leadSource"
              className="form-control"
              value={formik.values.leadSource}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="leadSource"
            >
              
              {/* <option value="">
                {formik.touched.leadSource && formik.errors.leadSource ? (
                  <p className="text-danger">{formik.errors.leadSource}</p>
                ) : (
                  "Select Lead Source"
                )}
              </option> */}
              {leadSources && leadSources?.length > 0 
                ? leadSources?.map((source) => (
                    <option key={source?.id} value={source?.value}>
                      {source?.leadSource}       
                    </option>
                  ))
                : ""}
            </select>
            <MdKeyboardArrowDown className="create_lead_input_iconnew" />
            {/* <small style={{color:"red",position:'absolute'}} >{formik.touched.leadSource && formik.errors.leadSource ? (
                 formik.errors.leadSource
                ) : (
                  "Select Lead Source"
                )}</small> */}
          </div>
          {/* Lead Status dropDown */}
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="leadStatus">Lead Status</label>
            <select
              id="leadStatus"
              className="form-control"
              value={formik.values.leadStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="leadStatus"
            >
              {/* <option value="">
                {formik.touched.leadStatus && formik.errors.leadStatus ? (
                  <p className="text-danger">{formik.errors.leadStatus}</p>
                ) : (
                  "Select Lead Status"
                )}
              </option> */}
 
              {leadStatus && leadStatus?.length > 0
                ? leadStatus?.map((lead) => (
                    <option key={lead.id} value={lead.value}>
                  
                      {lead.leadSource}
                    </option>
                  ))
                : ""}
            </select>
            {/* <small style={{color:"red",position:'absolute'}}>{formik.touched.leadStatus && formik.errors.leadStatus ? (
                 formik.errors.leadStatus
                ) : (
                  "Select Lead Status"
                )}</small> */}
            <MdKeyboardArrowDown className="create_lead_input_iconnew" />
          </div>
          {/* Lead Service */}
          {/* <div className="form-group createLeadInput col-xl-4 costum-select">
            <label htmlFor="leadStatus">
              Lead Service <span className="required_sign">*</span>
            </label>

            <select
              id="leadService"
              className="form-control"
              value={formik.values.leadService}
              onChange={formik.handleChange}
              onFocus={formik.handleFocus}
              onBlur={formik.handleBlur}
              name="leadService"
            >
              <option hidden>
                Select Lead Service
              </option>

             
              {leadServices && leadServices?.length > 0
                ? leadServices?.map((services) => (
                    <option key={services.id} value={services.leadSoruce}>
                      {services.leadSoruce}
                    </option>
                  ))
                : ""}
            </select>
            {formik.touched.leadService && formik.errors.leadService && (
              <small className="errorMessage">
                {formik.errors.leadService}
              </small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_iconnew" />
          </div> */}
          {/* subject */}

          <div className="form-group createLeadInput  col-md-4 col-sm-6">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="subject"
              placeholder={
                formik.touched.subject && formik.errors.subject
                  ? formik.errors.subject
                  : null
              }
            />
            {/* <FaPhone className="create_lead_input_iconnew" /> */}
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.subject && formik.errors.subject
                  ? formik.errors.subject
                  : null}
              </small>
            }
          </div>

          {/* >Product Name< */}

          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="productName">Product Name</label>
            <input
              type="text"
              id="productName"
              className="form-control create_lead_form_input"     
              value={formik.values.productName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="productName"
              placeholder={
                formik.touched.productName && formik.errors.productName
                  ? formik.errors.productName
                  : null
              }
            />
            {/* <FaPhone className="create_lead_input_iconnew" /> */}
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.productName && formik.errors.productName
                  ? formik.errors.productName
                  : null}
              </small>
            }
          </div>
          {/* queryMcatName */}
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="mobileNumber">QueryMcat Name</label>
            <input
              type="text"
              id="queryMcatName"
              className="form-control create_lead_form_input"
              value={formik.values.queryMcatName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="mobileNumber"
              placeholder={
                formik.touched.queryMcatName && formik.errors.queryMcatName
                  ? formik.errors.queryMcatName
                  : null
              }
            />
           
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.queryMcatName && formik.errors.queryMcatName
                  ? formik.errors.queryMcatName
                  : null}
              </small>
            }
          </div> */}
          {/* call duration */}
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="callDuration">Call Duration</label>
            <input
              type="text" 
              id="callDuration"
              className="form-control create_lead_form_input"
              value={formik.values.callDuration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="callDuration"
              placeholder={
                formik.touched.callDuration && formik.errors.callDuration
                  ? formik.errors.callDuration
                  : null
              }
            />
            {/* <FaPhone className="create_lead_input_iconnew" /> */}
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.callDuration && formik.errors.callDuration
                  ? formik.errors.callDuration
                  : null}
              </small>
            }
          </div>

        </div>
        {/* Company Information */}
        <div className="row">
          <p className="create_lead_section2_company_info">Company Details</p>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="annualRevenue">Annual Revenue</label>
            <input
              type="number"
              id="annualRevenue"
              className="form-control create_lead_form_input"
              value={formik.values.annualRevenue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="annualRevenue"
              placeholder={
                formik.touched.annualRevenue && formik.errors.annualRevenue
                  ? formik.errors.annualRevenue
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.annualRevenue && formik.errors.annualRevenue
                ? formik.errors.annualRevenue
                : null}
            </small>
            <BsCurrencyRupee className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              className="form-control create_lead_form_input"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="companyName"
              placeholder={
                formik.touched.companyName && formik.errors.companyName
                  ? formik.errors.companyName
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.companyName && formik.errors.companyName
                ? formik.errors.companyName
                : null}
            </small>
            <BsBuildingsFill className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="companyEmail">Company Email</label>
            <input
              type="email"
              id="companyEmail"
              className="form-control create_lead_form_input"
              value={formik.values.companyEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="companyEmail"
              placeholder={
                formik.touched.companyEmail && formik.errors.companyEmail
                  ? formik.errors.companyEmail
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.companyEmail && formik.errors.companyEmail
                ? formik.errors.companyEmail
                : null}
            </small>
            <MdEmail className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="companyContact">Company Contact</label>
            <input
              type="text"
              id="companyContact"
              className="form-control create_lead_form_input"
              value={formik.values.companyContact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="companyContact"
              placeholder={
                formik.touched.companyContact && formik.errors.companyContact
                  ? formik.errors.companyContact
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.companyContact && formik.errors.companyContact
                ? formik.errors.companyContact
                : null}
            </small>
            <MdEmail className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="secondaryContact">Secondary Contact</label>
            <input
              type="text"
              id="secondaryContact"
              className="form-control create_lead_form_input"
              value={formik.values.secondaryContact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="secondaryContact"
              placeholder={
                formik.touched.secondaryContact &&
                formik.errors.secondaryContact
                  ? formik.errors.secondaryContact
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.secondaryContact && formik.errors.secondaryContact
                ? formik.errors.secondaryContact
                : null}
            </small>
            <FaPhone className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              className="form-control create_lead_form_input"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="city"
              placeholder={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.city && formik.errors.city
                ? formik.errors.city
                : null}
            </small>
            <FaTreeCity className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="district">District</label>
            <input
              type="text"
              id="district"
              className="form-control create_lead_form_input"
              value={formik.values.district}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="district"
              placeholder={
                formik.touched.district && formik.errors.district
                  ? formik.errors.district
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.district && formik.errors.district
                ? formik.errors.district
                : null}
            </small>
            <FaTreeCity className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              className="form-control create_lead_form_input"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="state"
              placeholder={
                formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.state && formik.errors.state
                ? formik.errors.state
                : null}
            </small>
            <FaTreeCity className="create_lead_input_iconnew" />
          </div>
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              className="form-control create_lead_form_input"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="country"
              placeholder={
                formik.touched.country && formik.errors.country
                  ? formik.errors.country
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.country && formik.errors.country
                ? formik.errors.country
                : null}
            </small>
            <FaLandmarkFlag className="create_lead_input_iconnew" />
          </div>

           {/*pinCode  */}
           <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="pinCode">Pin Code</label>
            <input
              type="text"
              id="pinCode"
              className="form-control create_lead_form_input"
              value={formik.values.pinCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="pinCode"
              placeholder={
                formik.touched.pinCode && formik.errors.pinCode
                  ? formik.errors.pinCode
                  : null
              }
            />
            {/* <FaPhone className="create_lead_input_iconnew" /> */}
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.pinCode && formik.errors.pinCode
                  ? formik.errors.pinCode
                  : null}
              </small>
            }
          </div>
          {/* address */}
          <div className="form-group createLeadInput   col-md-4 col-sm-6">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              className="form-control create_lead_form_input"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="address"
              placeholder={
                formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : null
              }
            />
            {/* <FaPhone className="create_lead_input_iconnew" /> */}
            {
              <small style={{ position: "absolute", color: "red" }}>
                {formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : null}
              </small>
            }
          </div>
        </div>
        {/* Description */}
        <div className="row">
   
          <div className="form-group ">
            <label
              htmlFor="description"
              className="create_lead_section2_description_label"
            >
              Description
            </label>
            <textarea
              id="description"
              maxLength={200}
              className="form-control create_lead_form_input"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="description"
              rows="3"
              placeholder={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : null
              }
            />
            <small style={{ color: "red", position: "absolute" }}>
              {formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null}
            </small>
          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Update
          </button>
        </div>
        
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreateUpdateForm;
