import React, { useState, useEffect } from "react";
// React Router Dom
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import Loader from "../pages/Loader";
import { LiaUserEditSolid } from "react-icons/lia";
import { PiProhibit } from "react-icons/pi";

const MeetingTable = ({
  meetingsPermission,
  handleUpdateSuccess,
  pageSize,
  pageNo,
  tblHead,
  redirectLink,
  getAllMeetingData,
  meetCostumerId,
  setMeetCostumerId,
  data,
  loading,
}) => {
  const navigate = useNavigate();
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  // Handle Single Check Box For Single Updateion And Id get and send Start ------
  const handleCheckboxChange = (meetId) => {
    const isSelected = meetCostumerId.includes(meetId);
    if (isSelected) {
      setMeetCostumerId(meetCostumerId.filter((id) => id !== meetId));
    } else {
      setMeetCostumerId([...meetCostumerId, meetId]);
    }
  };
  // Handle Single Check Box For Single Updateion And Id get and send End    ------
  // Handle Master Checkbox Change Start -----
  const handleMasterCheckboxChange = (event) => {
    console.log("Master checkbox clicked");
    const isChecked = event.target.checked;
    setIsMasterChecked(isChecked);
    const allmeetIds = getAllMeetingData?.content?.map((data) => data.id) || [];
    if (isChecked) {
      setMeetCostumerId(allmeetIds);
    } else {
      setMeetCostumerId([]);
    }
  };
  // Handle Master Checkbox Change End   -----
  useEffect(() => {
    const allLeadIds = getAllMeetingData?.content?.map((data) => data.id) || [];
    if (allLeadIds.length === 0) return;
    if (meetCostumerId.length === allLeadIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [meetCostumerId, getAllMeetingData]);
  return (
    <div className="LeadRightSectionTable_body table-responsivep-0 ">
      <div class="LeadRightSectionTable_body table-responsive">
        <table className="table mytable">
          <thead>
            <tr>
            <th scope="col" className=" text_center text-left text-capitalize">
                <div className="wrap-check-29">
                  <div className="cbx">
                    <input
                      id="cbx-29"
                      type="checkbox"
                      onChange={handleMasterCheckboxChange}
                      checked={isMasterChecked}
                    />
                    <label htmlFor="cbx-29" />
                    <svg width={15} height={14} viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2" />
                    </svg>
                  </div>
                  {/* Gooey*/}
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo-12">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation={4}
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                          result="goo-12"
                        />
                        <feBlend in="SourceGraphic" in2="goo-12" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </th>
              <th
                scope="col"
                className="text-left text_center text-capitalize"
                style={{ cursor: "context-menu" }}
              >
                Sr. No
              </th>
              {/* Checkbox Column */}
             
              <th scope="col" className="text-left text_center text-capitalize">
                {tblHead.seventhHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.firstHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.secondHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.thirdHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.fourthHead}
              </th>
              <th scope="col" className=" text_center text-capitalize">
                {tblHead.fifthHead}
              </th>
              <th scope="col" className="text-center text_center text-capitalize">
                {tblHead.sixthHead}
              </th>
              <th className=" text_center text-center text-capitalize">Edit</th>
              {/* <th scope="col" className="text-center">
                {tblHead.seventhHead}  
              </th> */}
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody className="dashboard_section1_tableBody ">
              {getAllMeetingData && getAllMeetingData?.content?.length > 0 ? (
                getAllMeetingData?.content?.map((data, index) => (
                  <tr
                    key={data.id}
                    onClick={() => localStorage.setItem("meetId", data.id)}
                  >
                     <td className=" text_center text-left text-capitalize">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="flexCheckIndeterminate"
                        onChange={() => handleCheckboxChange(data.id)}
                        checked={meetCostumerId?.includes(data.id)}
                      />
                    </td>
                    <td className="text-left text_center text-capitalize">
                      <span className="Link-button-leads">
                        <span
                          style={{
                            cursor: "context-menu",
                            border: "1px solid #9c9c9c",
                            padding: "2px 4px",
                            borderRadius: "3px",
                          }}
                        >
                          {`${
                            pageNo * pageSize + index < 9
                              ? `0${pageNo * pageSize + index + 1}`
                              : pageNo * pageSize + index + 1
                          }`}
                          {/* {pageNo * pageSize + index + 1} */}
                        </span>
                      </span>
                    </td>
                   
                    <td className="text-left text_center text-capitalize">
                        <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                        </Link>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.title}
                    </Link>
                  </td> */}
                    <td className="text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.title || 'No Data'}
                        <span className="tool_tip_box"> {data.title || 'No Data'}</span>
                      </div>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.host}
                    </Link>
                  </td> */}
                    <td className="text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.host || 'No Data'}
                        <span className="tool_tip_box"> {data.host || 'No Data'}</span>
                      </div>
                    </td>
                    <td className=" text_center text-left text-capitalize">
                      <Link to={redirectLink} className="Link-button-leads">
                        {data.date || 'No Data'}
                      </Link>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.location}
                    </Link>
                  </td> */}
                    <td className="text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.location || 'No Data'}
                        <span className="tool_tip_box">{data.location || 'No Data'}</span>
                      </div>
                    </td>
                    <td className=" text_center text-capitalize">
                      <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                        <FaRegEye className="showDetailEye fs-4" />
                      </Link>
                    </td>
                    <td className="text-center text_center text-capitalize">
                      <Link to="/create-deal" state={{ leadId: data.leadId }}>
                        <HiPencilSquare className="lead-table-contact-action-icon fs-4" />
                      </Link>
                    </td>
                    {/* <td className="text-center">
                    {data?.leadId && (
                      <Link to={redirectLink} className="Link-button-leads">
                      
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                      
                      </Link>
                    )}
                  </td> */}
                    <td className="text-center text-capitalize">
                      {meetingsPermission?.includes("Update")?
                      <LiaUserEditSolid
                        className="lead-table-contact-action-icon fs-4"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/create-meeting", { 
                            state: { data, from: "update" },
                          })
                        }
                      />:<PiProhibit style={{ cursor:"crosshair" }}  className="lead-table-contact-action-icon fs-4"/>}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className=" text-capitalize text-center">No Meeting Data At this Time</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default MeetingTable;
