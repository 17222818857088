import React, { useState, useEffect, useCallback, useContext } from "react";
//Import React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPencil, BsTrash } from "react-icons/bs";
import { MdOutlineUploadFile } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import MeetingTable from "../../components/MeetingTable";
import UpdateMeeting from "./UpdateMeeting";
// Import Toast
import Toast from "../../components/Toast";
// Import Context From app.js
import permissionContext from "../PermissionsContext";
// Import api function from controller
import {
  getAllMeetings,
  getSingleMeeting,
  deleteMeetings,
  downloadMeetings,
  uploadMeetings,
} from "../../controller/fetchApi";
// React Router Dom
import { Link, useLocation, useNavigate } from "react-router-dom";
import Deleteuserconfirmation from "../../components/deleteuserconfirmation";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../../components/goback";
import Pagination from "../../components/pagination";
import { PageDetailsContext } from "../../pageDetails";
import Gobackpage from "../gobackpage";

const Meetings = () => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath = location.pathname;
  const navigate = useNavigate()
  //  Get Meetings Permissions
  const { meetingsPermission } = useContext(permissionContext);
  // Start Toast Code-------
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: "" });
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast])

  const [meetCostumerId, setMeetCostumerId] = useState([]);
  // Get User details from local storage
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [getAllMeetingData, setAllMeetingData] = useState([]);
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const meetId = JSON.parse(localStorage.getItem("meetId"));
  const tokenId = userIdTokenData?.data?.token;
  // pagination data
  const totalElements = getAllMeetingData?.totalElements;
  const showingElements = getAllMeetingData?.numberOfElements;
  //set loader
  const [loading, setloading] = useState(true)
  //  Get All Data ----
  const getAllMeetingsData = useCallback(async () => {
    try {
      const res = await getAllMeetings(pageNo, tokenId, pageSize);
      setAllMeetingData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false)
    }
  }, [tokenId, pageNo, pageSize, setAllMeetingData]);

  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null, setLeadId: null
  });
  const [show, setshow] = useState(false)
  // Handle Delete Meeting Api
  const handleDeleteMeetings = async () => {
    if (meetCostumerId.length) {
      try {
        await deleteMeetings(meetCostumerId, setShowToast, tokenId);
        if (deleteMeetings) {
          getAllMeetingsData();
          setMeetCostumerId([]);
        }
      } catch (error) {
        const errorMessage = error.message;
        console.log("Error deleting Single Account", errorMessage);
      }
      finally {
        setMeetCostumerId([])
        setshow(false)
      }
    } else {
      setShowToast({ success: true, message: "Select meeting to delete.", optmessage: "Deleting meetings." })
    }

  };
  // Update Meeting Start--------
  const [defaultValue, setDefaultValue] = useState([]); // Get Single Deal Data Which Fullfill Field Value
  const handleUpdateMeet = async () => {
    try {
      const singDealResult = await getSingleMeeting(meetId, tokenId);
      if (singDealResult) {
        setDefaultValue(singDealResult);
      } else {
        setDefaultValue([]);
      }
    } catch (error) {
      console.log("Update Meet SingleDeal :", error);
      setDefaultValue([]);
    }
  };
  const handleUpdateSuccess = async () => {
    try {
      await getAllMeetingsData();
    } catch (error) {
      console.log("Error fetching updated data", error);
    }
  };
  // Handle Upload File start ----
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    console.log("Selected ");
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadMeetings = async () => {
    if (selectedFile) {
      console.log("file selected: " + selectedFile);
      try {
        await uploadMeetings(selectedFile, setShowToast, tokenId);
        getAllMeetingsData();
      } catch (error) {
        console.log("Meetings Failed Uploading:", error);
      }
    }
  };
  // Download Meeting Api
  const handleDownloadMeetings = async () => {
    try {
      await downloadMeetings(setShowToast, tokenId,meetCostumerId);
    } catch (error) {
      console.log("Error downloading task", error);
    }
  };
  useEffect(() => {
    getAllMeetingsData();
  }, [getAllMeetingsData]);
  // Pagination Function ------
  const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = getAllMeetingData?.totalPages || 1;
  const pagesToShow = 6;
  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      <div className="dashboard_content_wrapper">

        {/* Btn div */}
        <div className="dashboard_leads_btn_mainDiv">
          <Gobackpage pageName={pagedetails[pagePath].pageName} page="meetings" />
          <div className="dashboard_leads_btns_div">
            <div className="dashboard_leads_action_btn_div">
              <div className="actionbtn">

              <button
               class="default-btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Actions
                <ul
                  className="dropdown-menu"
                  aria-labelledby="editDeleteDropdown"
                >
             
                  {/* Delete Btn */}
                  {meetingsPermission?.includes("Delete") ? (
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() =>{
                           if( meetCostumerId?.length){
                            setshow(true)
                            setdellead({
                              leadId:meetCostumerId,setLeadId:setMeetCostumerId
                             })
                          }else{
                            setShowToast({ success: true, message: "Select Contact to delete." ,optmessage:"Deleting contact."})
                          }   
                        }}
                      >
                        <BsTrash className="dashboard_section1_table_deleteBtn" />
                        Delete
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                    {/* Upload Btn */}
                    {meetingsPermission?.includes("Upload") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#fileUploadModal"
                        >
                          <MdOutlineUploadFile className="dashboard_section1_table_deleteBtn" />
                          Upload Meetings
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Download Btn */}
                    {meetingsPermission?.includes("Download") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => handleDownloadMeetings()}
                        >
                          <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                          Download Meetings
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* download selected contact */}

                  </ul>
                </button>
              </div>

            </div>
            {/* Create Btn */}
            {meetingsPermission?.includes("Create") && (
              <div
                className="dashboard_leads_create_btn_div"
                onClickCapture={(event) => {
                  event.stopPropagation();
                  navigate("/create-meeting");
                }}
                style={{  }}
              >
                <button
                  className="default-btn"
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <MdAdd style={{  textAlign: "center" }} />
                  <div style={{ textAlign: "center" }}>Create</div>
                </button>
              </div>
            )}

          </div>
        </div>
        {/* Table Div */}
        <div className="dashboard_leads_table_div">

          <MeetingTable
            meetingsPermission={meetingsPermission}
            pageNo={pageNo}
            pageSize={pageSize}
            loading={loading}
            tblHead={{
              firstHead: "Title",
              secondHead: "Host",
              thirdHead: "Date",
              fourthHead: "Location",
              fifthHead: "View",
              sixthHead: "Deals Action",
              seventhHead: "Lead Id",
            }}
            redirectLink="/meetings-details"
            getAllMeetingData={getAllMeetingData}
            meetCostumerId={meetCostumerId}
            setMeetCostumerId={setMeetCostumerId}
            data="Pankaj Swami Vaishnav"
            handleUpdateSuccess={handleUpdateSuccess}
          />

        </div>
        {/* Pagination Div */}
        <Pagination
          maxData={totalElements}
          totalPages={totalPages}
          totalElements={totalElements}
          showingElements={showingElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
        {/* <div className="dashboard_leads_pagination_div">
          <nav aria-label="...">
            <ul className="pagination">
            
              <li className="page-item dashboard_leads_pagination_pageItem">
                <a
                  className="page-link"
                  href="#!"
                  onClick={handlePreviousPageClick}
                >
                  <IoIosArrowBack />
                </a>
              </li>

             
              {Array.from({ length: pagesToShow }, (_, index) => {
                const pageIndex = pageRangeStart + index;
                return (
                  pageIndex < totalPages && (
                    <li
                      key={pageIndex}
                      className={`page-item ${
                        pageIndex === pageNo ? "active" : ""
                      } dashboard_leads_pagination_pageItem`}
                    >
                      <a
                        className="page-link"
                        href="#!"
                        onClick={() => handlePageClick(pageIndex)}
                      >
                        {pageIndex + 1 < 10
                          ? `0${pageIndex + 1}`
                          : pageIndex + 1}
                      </a>
                    </li>
                  )
                );
              })}

           
              <li className="page-item dashboard_leads_pagination_pageItem">
                <a
                  className="page-link"
                  href="#!"
                  onClick={handleNextPageClick}
                >
                  <IoIosArrowForward className="btn_IoIosArrowForward" />
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
        {/*Update Meeting  Modal */}
        <>
          <div
            className="modal fade modal-xl"
            id="updateMeetModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <UpdateMeeting
                    meetCostumerId={meetCostumerId}
                    defaultValue={defaultValue}
                    onUpdateSuccess={handleUpdateSuccess}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* File Upload Modal */}
        <>
          <div
            className="modal fade"
            id="fileUploadModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <label
                      htmlFor="exampleFormControlFile1"
                      className="form-label"
                    >
                      Choose file
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="exampleFormControlFile1"
                      onChange={handleFileChange}
                    />
                  </form>
                </div>
                <div className="modal-footer" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <button className="btn btn-secondary" >Download Sample file</button>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUploadMeetings}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          handleDelete={handleDeleteMeetings}
          sourceName="Meetings"
          setShowToast={setShowToast}
        />
        <Toast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </div>
  );
};

export default Meetings;
