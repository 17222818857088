// import React, { useState } from "react";
// // css
// // import "../styles/signup.page.css";
// // Formik
// import { useFormik } from "formik";
// import { signupFormSchema } from "../schema/FormValidation";
// // React Icons
// import { FcBusinessman } from "react-icons/fc";
// import { FcFeedback } from "react-icons/fc";
// import { FcCellPhone } from "react-icons/fc";
// import { FcManager } from "react-icons/fc";
// // Redux Toolkit
// import { useDispatch } from "react-redux";
// import { setUserData } from "../app/slices";

// // React Router Dom
// import { Link, useNavigate } from "react-router-dom";

// // Import Api Function
// import { countryCode, signupUser } from "../controller/fetchApi";
// import { FaRegEyeSlash } from "react-icons/fa";
// import { BiShow } from "react-icons/bi";
// import Gobackpage from "./gobackpage";

// // Main Component Function
// const Signup = () => {
//   const dispatch = useDispatch();
//   // TokenId
//   const userIdTokenData = JSON.parse(localStorage.getItem("user"));
//   const tokenId = userIdTokenData?.data?.token;
//   const navigate = useNavigate();
//   // Toast
//   const [showToast, setShowToast] = useState({
//     success: false,
//     message: "",
//     optmessage: "",
//   });

//   // Function to hide the toast after 3 seconds
//   const hideToast = () => {
//     setTimeout(() => {
//       setShowToast({ success: false, message: "" });
//     }, 3000);
//   };
//   if (showToast.message) {
//     hideToast();
//   }
//   // Form Handle & Validations
//   const {
//     values,
//     errors,
//     touched,
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     setFieldTouched,
//     isSubmitting,
//     setSubmitting,
//   } = useFormik({
//     initialValues: {
//       firstName: "",
//       lastName: "",
//       userName: "",
//       email: "",
//       password: "",
//       confirmPassword: "",
//       phone: "",
//     },

//     validationSchema: signupFormSchema,
//     onSubmit: async (values, { resetForm, setSubmitting }) => {
//       const data = await countryCode();
//       const response = await signupUser(
//         values,
//         setSubmitting,
//         setShowToast,
//         tokenId
//       );

//       if (response) {
//         navigate("/otpverification");
//         resetForm();
//         dispatch(
//           setUserData({
//             email: values.email,
//             name: `${values.firstName} ${values.lastName}`,
//           })
//         );
//       }
//     },
//   });

//   // Function to handle input focus
//   const handleFocus = (e) => {
//     const { name } = e.target;
//     setFieldTouched(name, true);
//   };
//   // Show & Hide Password
//   const [showPassword, setShowPassword] = useState(true);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(true);
// const handleClickShowPassword = () => {
//   setShowPassword(!showPassword);
// };
// const handleClickShowConfirmPassword = () => {
//   setShowConfirmPassword(!showConfirmPassword);
// };
//   return (
//     <>
//       <div className="container-fluid signup_body_div">
//         <Gobackpage pageName="Create User" />
//         <div className="row">
//           {/* Left Main Div Start*/}
//           <div className=" col-md-12 col-sm-12">
//             <div className="container signup_left_container" style={{ width: "100%" }}>
//               <div id="signup_left_mainDiv">
//                 <form onSubmit={handleSubmit}>
//                 <p className="usertitle ">Create User</p>
//                   <div className="formGroup">

//                     <div className="signup_fullname_div mb-3">

//                       <div
//                         className="position-relative"

//                       >
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_firstName"
//                             className="form-label signup_div_name"
//                           >
//                             First Name <span className="required_sign">*</span>{" "}
//                           </label>
//                           <input
//                             type="text"
//                             maxlength="20"
//                             className={`form-control signup_name_form_control`}
//                             id="signup_firstName"
//                             placeholder="Enter First Name"
//                             name="firstName"
//                             value={values.firstName}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             onFocus={handleFocus}
//                           />
//                         </div>
//                         {touched.firstName && errors.firstName && (
//                           <small className="errorMessageSignup">
//                             {errors.firstName}
//                           </small>
//                         )}
//                       </div>


//                       <div
//                         className="position-relative"

//                       >
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_lastName"
//                             className="form-label signup_div_name"
//                           >
//                             Last Name <span className="required_sign">*</span>
//                           </label>
//                           <input
//                             type="text"
//                             pattern="[a-zA-Z]{1,30}"
//                             maxlength="20"
//                             className={`form-control signup_name_form_control`}
//                             id="signup_lastName"
//                             placeholder="Enter Last Name"
//                             name="lastName"
//                             value={values.lastName}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             onFocus={handleFocus}
//                           />
//                           {touched.lastName && errors.lastName && (
//                             <small className="errorMessageSignup ">
//                               {errors.lastName}
//                             </small>
//                           )}
//                         </div>
//                       </div>
//                     </div>


//                     <div className="mb-3">
//                       <div className=" position-relative">
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_userName"
//                             className="form-label signup_div_input"
//                           >
//                             UserName <span className="required_sign">*</span>
//                           </label>
//                           <input
//                             type="text"
//                             maxlength="30"
//                             className={`form-control signup_email_form_control userName_input_signup`}
//                             id="signup_userName"
//                             name="userName"
//                             autoComplete="off"
//                             placeholder="Enter User Name"
//                             value={values.userName}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             onFocus={handleFocus}
//                           />
//                         </div>
//                         {touched.userName && errors.userName && (
//                           <small className="errorMessageSignup">
//                             {errors.userName}
//                           </small>
//                         )}
//                       </div>
//                     </div>


//                     <div className="mb-3">
//                       <div className=" position-relative">
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_email"
//                             className="form-label signup_div_input"
//                           >
//                             {" "}
//                             Email
//                             <span className="required_sign">*</span>
//                           </label>
//                           <input
//                             type="email"
//                             maxLength="64"
//                             className={`form-control signup_email_form_control`}
//                             id="signup_email"
//                             name="email"
//                             placeholder="Enter Email"
//                             value={values.email}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             onFocus={handleFocus}
//                           />
//                           {touched.email && errors.email && (
//                             <small className="errorMessageSignup">
//                               {errors.email}
//                             </small>
//                           )}
//                         </div>
//                       </div>
//                     </div>


//                     <div className="mb-3">
//                       <div className="position-relative">
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_phone"
//                             className="form-label signup_div_input"
//                           >
//                        Mobile Number{" "}
//                             <span className="required_sign">*</span>
//                           </label>
//                           <input
//                             type="text"
//                             maxLength="10"
//                             className={`form-control signup_email_form_control`}
//                             id="signup_phone"
//                             placeholder="Enter Mobile Number"
//                             name="phone"
//                             title="please lengthen this to 10 digits"
//                             value={values.phone}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             onFocus={handleFocus}
//                           />
//                         </div>
//                         {touched.phone && errors.phone && (
//                           <small className="errorMessageSignup">
//                             {errors.phone}
//                           </small>
//                         )}
//                       </div>
//                     </div>


//                     <div className="mb-3">
//                       <div className=" position-relative">
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_password"
//                             className="form-label signup_div_input"
//                           >
//                             Password <span className="required_sign">*</span>
//                           </label>
//                           <input
//                             type={showPassword ? "password" : "text"}
//                            maxLength={15}
//                             title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
//                             className={`form-control signup_email_form_control  `}
//                             id="signup_password"
//                             placeholder="Create Password"
//                             name="password"
//                             value={values.password}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             onFocus={handleFocus}
//                             autoComplete="new-password"
//                           />
//                         </div>
//                         {touched.password && errors.password && (
//                           <small className="errorMessageSignup">
//                             {errors.password}
//                           </small>
//                         )}
//                         {showPassword ? (
//                           <FaRegEyeSlash
//                             className="signup_input_icons"
//                             onClick={handleClickShowPassword}
//                           />
//                         ) : (
//                           <BiShow
//                             className="signup_input_icons"
//                             onClick={handleClickShowPassword}
//                           />
//                         )}
//                       </div>
//                     </div>

//                     <div className="mb-3">
//                       <div className="mb-4 position-relative">
//                         <div className="form-group">
//                           <label
//                             htmlFor="signup_confirm_password"
//                             className="form-label signup_div_input"
//                           >
//                             Confirm Password
//                             <span className="required_sign">*</span>
//                           </label>
//                           <input
//                             type={showConfirmPassword ? "password" : "text"}
//                            maxLength={15}
//                             className={`form-control signup_email_form_control `}
//                             id="signup_confirm_password"
//                             placeholder="Confirm password"
//                             name="confirmPassword"
//                             value={values.confirmPassword}
//                             onChange={handleChange}
//                             onFocus={handleFocus}
//                             autoComplete=""
//                             onBlur={handleBlur}
//                           />
//                         </div>
//                         {touched.confirmPassword && errors.confirmPassword && (
//                           <small className="errorMessageSignup">
//                             {errors.confirmPassword}
//                           </small>
//                         )}
//                         {showConfirmPassword ? (
//                           <FaRegEyeSlash
//                             className="signup_input_icons"
//                             onClick={handleClickShowConfirmPassword}
//                           />
//                         ) : (
//                           <BiShow
//                             className="signup_input_icons"
//                             onClick={handleClickShowConfirmPassword}
//                           />
//                         )}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="signup_submit_div">
//                     <button
//                       type="submit"
//                       className=" signup_submit_button"
//                       disabled={isSubmitting}
//                     >
//                       Create
//                     </button>
//                   </div>

//                 </form>
//         {showToast.message && (
//           <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
//             <div
//               className="toast show create_lead_toast"
//               role="alert"
//               aria-live="assertive"
//               aria-atomic="true"
//             >
//               <div className="toast-header create_lead_toast_header">
//                 <strong className="me-auto">

//                   {showToast.success ? showToast?.optmessage : "Error"}
//                 </strong>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   onClick={() =>
//                     setShowToast({ success: false, message: "" })
//                   }
//                 />
//               </div>
//               <div className="toast-body">{showToast.message}</div>
//             </div>
//           </div>
//         )}
//       </div>

//     </div>
//   </div>


// </div>
//       </div>
//     </>
//   );
// };

// export default Signup;

import React, { useState } from "react";
import { useFormik } from "formik";
import { signupFormSchema } from "../schema/FormValidation";
import { FaUser, FaEnvelope, FaPhoneAlt, FaLock } from 'react-icons/fa';
import { useDispatch } from "react-redux";
import { setUserData } from "../app/slices";
import { Link, useNavigate } from "react-router-dom";
import { countryCode, signupUser } from "../controller/fetchApi";
import "bootstrap/dist/css/bootstrap.min.css";
import Gobackpage from "./gobackpage";
import { FaUnlock } from "react-icons/fa6";
import { MdOutlinePhone, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";

const Signup = () => {
  const dispatch = useDispatch();
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: "" });

  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" });
    }, 3000);
  };
  if (showToast.message) {
    hideToast();
  }

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },
    validationSchema: signupFormSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const data = await countryCode();
      const response = await signupUser(values, setSubmitting, setShowToast, tokenId);
      if (response) {
        navigate("/otpverification");
        resetForm();
        dispatch(setUserData({ email: values.email, name: `${values.firstName} ${values.lastName}` }));
      }
    },
  });

  const handleFocus = (e) => {
    setFieldTouched(e.target.name, true);
  };

  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="shadow-md rounded-md p-1">
      <Gobackpage pageName="Create User" />
      <div className="card w-96 p-5 border-0">

        <form onSubmit={handleSubmit}>
          <div className="row">

            {/* First Name */}
            <div className="col-md-6 mb-3">
              <label className="form-label">
                First Name <span className="text-danger">*</span>
              </label>
              <div style={{
                position: "relative"
              }}>
                <input
                  type="text"
                  className="form-control rounded-md p-2 pr-10"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <CiUser  style={{
                  position: "absolute",
                  right: "0.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#6b7280"
                }} />
              </div>
              {touched.firstName && errors.firstName && <div className="text-danger">{errors.firstName}</div>}
            </div>

            {/* Last Name */}
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Last Name <span className="text-danger">*</span>
              </label>
              <div style={{
                position: "relative"
              }}>
                <input
                  type="text"
                  className="form-control rounded-md p-2 pr-10"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <CiUser  style={{
                  position: "absolute",
                  right: "0.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#6b7280"
                }} />
              </div>
              {touched.lastName && errors.lastName && <div className="text-danger">{errors.lastName}</div>}
            </div>
          </div>

          {/* userName */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Username <span className="text-danger">*</span>
              </label>
              <div style={{
                position: "relative"
              }}>
                <input
                  type="text"
                  className="form-control rounded-md p-2 pr-10"
                  name="userName"
                  value={values.userName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <CiUser  style={{
                  position: "absolute",
                  right: "0.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#6b7280"
                }} />
              </div>
              {touched.userName && errors.userName && <div className="text-danger">{errors.userName}</div>}
            </div>

            {/* Email */}
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <div style={{
                position: "relative"
              }}>
                <input
                  type="email"
                  className="form-control rounded-md p-2 pr-10"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <AiOutlineMail  style={{
                  position: "absolute",
                  right: "0.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#6b7280"
                }} />
              </div>
              {touched.email && errors.email && <div className="text-danger">{errors.email}</div>}
            </div>
          </div>
          {/* Mobile Number */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <div style={{
                position: "relative"
              }}>
                <input
                  type="text"
                  className="form-control rounded-md p-2 pr-10"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <MdOutlinePhone  style={{
                  position: "absolute",
                  right: "0.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#6b7280"
                }} />
              </div>
              {touched.phone && errors.phone && <div className="text-danger">{errors.phone}</div>}
            </div>

            {/* Password */}
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "password" : "text"} 
                  className="form-control rounded-md p-2 pr-10"
                  name="Password"
                  value={values.Password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0.75rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#6b7280",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <MdVisibility /> : <MdVisibilityOff />} {/* Toggle Icon */}
                </span>
              </div>
            </div>


            {/* Confirm Password */}
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Confirm Password <span className="text-danger">*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={showConfirmPassword ? "password" : "text"} 
                  className="form-control rounded-md p-2 pr-10"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0.75rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#6b7280",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff   />} {/* Toggle Icon */}
                </span>
              </div>
            </div>


          </div>



          <div className="d-flex justify-content-center gap-3">
            <button
              type="submit"
              className="btn btn-primary w-48"
              disabled={isSubmitting}
            >
              Sign Up
            </button>
            <button
              type="button"
              className="btn btn-secondary w-48"
              onClick={() => navigate(-1)}  // Navigate to the home or any other page
            >
              Cancel
            </button>
          </div>


        </form>
        {showToast.message && (
          <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
            <div
              className={`toast show create_lead_toast ${showToast.success ? 'bg-success' : 'bg-danger'}`}
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header create_lead_toast_header">
                <strong className="me-auto">
                  {showToast.success ? showToast?.optmessage : "Error"}
                </strong>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() =>
                    setShowToast({ success: false, message: "" })
                  }
                />
              </div>
              <div className="toast-body">{showToast.message}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
