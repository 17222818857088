import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";



const Pagination = ({ maxData, totalPages, totalElements, showingElements, pageNo, setPageNo, pageSize, setPagesize }) => {


  const [pageRangeStart, setPageRangeStart] = useState(0);
  const pagesToShow = 5;

  useEffect(() => {
    if (pageNo >= totalPages) {
      setPageNo(totalPages - 1)
    }
  }, [totalPages])

  const handlePagesize = (event) => {
    const number = totalElements;
    const size = event.target.value;
    if (pageNo > number / size) {
      setPageNo(
        number % size !== 0 ? Math.floor(number / size) : number / size - 1
      );
      setPageRangeStart(Math.floor((number % size !== 0 ? Math.floor(number / size) : number / size - 1) / 5) * 5)
    }
    setPagesize(event.target.value);
  };

  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };


  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // paddingTop:"10px"
        borderTop: "1px solid #e6e5e3",
        // padding: "2px 19px",
      }}
    >
      {totalElements > 0 &&
        <div
          className="page_size_control"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "15px",
            fontWeight: "500",
            paddingBottom: "10px",
            justifySelf: "flex-start",
            minWidth: 'fit-content',
            margin: '10px 0px'
          }}
        >
          {/* <span style={{ padding: "0px 10px"}}>Showing: { `${pageNo * pageSize + 1}`}-{ `${pageNo * pageSize + showingElements}`}</span>
        <span>
      
             out of
        </span>
    
        <span  style={{ padding: "0px 10px" }}>
          {totalElements ? totalElements : 0}
        </span> */}
          <div style={{
            display: 'flex',
            alignItems: "center", // Ensures vertical centering
            gap: "8px" // Adjust spacing to match the image
          }}>
            <span className="page_size_control" style={{ color: "#6c757d", fontSize: "14px" }}>Display</span>
            <select
              defaultValue={10}
              onChange={handlePagesize}
              style={{
                padding: "5px 7px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                outline: "none",
                backgroundColor: "white",
                color: "#6c757d",
                fontSize: "14px",
                cursor: "pointer"
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </select>
          </div>

        </div>
      }
      {totalElements > 10 && (
        <div className="dashboard_leads_pagination_div">
          <nav
            aria-label="..."
            style={{ display: "flex", justifySelf: "flex-end", alignItems: "center" }}
          >
            {/* <div style={{display:'flex',alignItems:"center" , }}>
              <span className="page_size_control">Display</span>
              <select defaultValue={10}  onChange={handlePagesize} style={{background:"#E4E8EB"}}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>50</option>  
              </select>
             
            </div> */}
            <span style={{ padding: "0px 10px" }}>Showing {`${pageNo * pageSize + 1}`} to {`${pageNo * pageSize + showingElements}`}</span>
            <span>

              of
            </span>

            <span style={{ padding: "0px 10px" }}>
              {totalElements ? totalElements : 0}  entries
            </span>
            <ul className="pagination" style={{ display: "flex", justifySelf: "flex-end", alignItems: "center", marginBottom: "0px" }}>
              {/* Previous page button */}
              <li className="page-item dashboard_leads_pagination_pageItem">
                <span
                  className="page-link"
                  onClick={handlePreviousPageClick}
                >
                  <IoIosArrowBack />
                </span>
              </li>

              {/* Render page numbers */}
              {Array.from({ length: pagesToShow }, (_, index) => {
                const pageIndex = pageRangeStart + index;

                return (
                  pageIndex < totalPages && (
                    <li
                      key={pageIndex}
                      className={`page-item ${pageIndex === pageNo ? "active" : ""}`}
                      style={{
                        outline: "none",
                        border: "none",
                        background: "none",
                        boxShadow: "none",
                        margin: "0 6px",
                        padding: "5px 8px",
                      }}
                    >
                      <span
                        className="page-link"
                        onClick={() => handlePageClick(pageIndex)}
                        style={{
                          borderRadius: "15%", // Makes it rounded
                          background: pageIndex === pageNo ? "#446FEF" : "none", 
                          color: pageIndex === pageNo ? "white" : "inherit", 
                          // border: pageIndex === pageNo ? "2px solid #0056b3" : "none", 
                          cursor: "pointer",
                          display: "inline-block",
                          transition: "0.3s ease-in-out",
                        }}
                      >
                        {pageIndex + 1 < 10 ? `0${pageIndex + 1}` : pageIndex + 1}
                      </span>
                    </li>
                  )
                );
              })}






              {/* Next page button */}
              <li className="page-item dashboard_leads_pagination_pageItem">
                <span
                  className="page-link"
                  onClick={handleNextPageClick}
                >
                  <IoIosArrowForward className="btn_IoIosArrowForward" />
                </span>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Pagination;
