import React, { useState, useCallback, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// Import CSS
// import "../../styles/dashboardCss/calls.css";
//Import Instance from React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPencil, BsTrash } from "react-icons/bs";
import { MdOutlineUploadFile } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import LogCallTable from "../../components/LogCallTable";
// Import Toast
import Toast from "../../components/Toast";
// Import Permissions Context
import permissionContext from "../PermissionsContext";
// Import api function from controller
import {
  getAllLogCall,
  deleteLogCall,
  downloadLogCalls,
  uploadLogCalls,
  getSingleLogCall,
} from "../../controller/fetchApi";
import UpdateLogCall from "./UpdateLogCall";
import Deleteuserconfirmation from "../../components/deleteuserconfirmation";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Pagination from "../../components/pagination";
import { PageDetailsContext } from "../../pageDetails";
import Goback from "../../components/goback";
import Gobackpage from "../gobackpage";

const CallLogs = () => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath = location.pathname;
  const navigate = useNavigate()
  // Log Calls Permissions From Apps
  const { callsPermission } = useContext(permissionContext);
  // Start Toast Code-------
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: "" });
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast])

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [logCallCostumerId, setLogCallCostumerId] = useState([]);
  const [getAllLogCallData, setAllLogCallData] = useState([]);

  // pagination data
  const totalElements = getAllLogCallData?.totalElements;
  const showingElements = getAllLogCallData?.numberOfElements
  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const logCallId = JSON.parse(localStorage.getItem("logCallId"));
  const tokenId = userIdTokenData?.data?.token;
  //set loader
  const [loading, setloading] = useState(true);
  //  Get All Schedule Call Data
  const getLogCallData = useCallback(async () => {
    try {
      const res = await getAllLogCall(pageNo, tokenId, pageSize);
      setAllLogCallData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false)
    }
  }, [tokenId, pageNo, pageSize, setAllLogCallData]);

  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null, setLeadId: null
  });
  const [show, setshow] = useState(false)
  // Handle Delete Log Call Api
  const handleDeleteLogCall = async () => {
    if (logCallCostumerId.length) {
      try {
        await deleteLogCall(logCallCostumerId, setShowToast, tokenId);
        if (deleteLogCall) {
          getLogCallData();
          setLogCallCostumerId([]);
        }
      } catch (error) {
        const errorMessage = error.message;
        console.log("Error deleting Single Account", errorMessage);
      } finally {
        setshow(false)
      }
    } else {
      setShowToast({ success: true, message: "select call-logs to delete.", optmessage: "Deleting call-logs." })
    }
  };
  // Download Log Call Api
  const handleDownloadLogCalls = async () => {
    try {
      await downloadLogCalls(setShowToast, tokenId,logCallCostumerId);
    } catch (error) {
      console.log("Error downloading Log Call", error);
    }
  };
  // Handle Upload File start ----
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    console.log("Selected ");
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadLogCalls = async () => {
    console.log("Start handle Log Calls");
    if (selectedFile) {
      console.log("file selected: " + selectedFile);
      try {
        await uploadLogCalls(selectedFile, setShowToast, tokenId);
        getLogCallData();
      } catch (error) {
        console.log("Upload Log Calls Failed Uploading:", error);
      }
    }
  };
  // Update Log Call Start--------
  const [defaultValue, setDefaultValue] = useState([]); // Get Single Log Call Data Which Fullfill Field Value
  const handleUpdateLogCall = async () => {
    try {
      const singLogCallResult = await getSingleLogCall(logCallId, tokenId);
      if (singLogCallResult) {
        setDefaultValue(singLogCallResult);
      } else {
        setDefaultValue([]);
      }
    } catch (error) {
      console.log("Schedule Update Call Error :", error);
      setDefaultValue([]);
    }
  };
  const handleUpdateSuccess = async () => {
    try {
      console.log("get log call dta function");
      await getLogCallData();
      await getLogCallData();
      console.log("again call");
    } catch (error) {
      console.log("Error fetching updated data", error);
    }
  };
  // Pagination Function Code------
  const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = getAllLogCallData?.totalPages || 1;
  const pagesToShow = 6;
  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };

  useEffect(() => {
    getLogCallData();
  }, [getLogCallData]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      {/* <Link onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </Link> */}
      <div className="dashboard_content_wrapper">
        {/* Btn Div */}
        <div className="dashboard_leads_btn_mainDiv">
          <Gobackpage pageName={pagedetails[pagePath].pageName} />
          {/* <div>  <Goback/> <span style={{fontSize:"21px",fontWeight:"700"}}>{pagedetails[pagePath].pageName}</span></div> */}
          <div className="dashboard_leads_btns_div">
            <div class="leads_table_id_col">
              <div class="actionbtn">
                <button
                  className="default-btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  Actions
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="editDeleteDropdown"
                  >
                    {/* Update Btn */}
                    {/* {callsPermission?.includes("Update") ? (
                    <li
                      data-bs-toggle="modal"
                      data-bs-target="#updateLogCallModal"
                    >
                      <span
                        className="dropdown-item"
                        onClick={() => handleUpdateLogCall()}
                      >
                        <BsPencil className="dashboard_section1_table_editBtn" />
                        Edit
                      </span>
                    </li>
                  ) : (
                    ""
                  )} */}

                  {/* Delete Btn */}
                  {callsPermission?.includes("Delete") ? (
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => {   
                        //  handleDeleteLogCall(logCallCostumerId)
                          if( logCallCostumerId?.length){
                            setshow(true)
                           
                          }else{
                            setShowToast({ success: true, message: "Select Contact to delete." ,optmessage:"Deleting contact."})
                          }  
                        }}
                      >
                        <BsTrash className="dashboard_section1_table_deleteBtn" />
                        Delete
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                    {/* Upload Btn */}
                    {callsPermission?.includes("Upload") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#fileUploadModal"
                        >
                          <MdOutlineUploadFile className="dashboard_section1_table_deleteBtn" />
                          Upload Calls
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Downloads Btn */}
                    {callsPermission?.includes("Download") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => handleDownloadLogCalls()}
                        >
                          <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                          Download Calls
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </button>
              </div>

            </div>
            {/* Create Btn */}
            {callsPermission?.includes("Create") ? (
              <div
                className="dashboard_leads_create_btn_div"
                onClickCapture={(event) => {
                  event.stopPropagation();
                  navigate("/log-call");
                }}
              >
                <button
                  className="default-btn"
                  style={{
                    width: "85px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px", // Icon aur text ke beech space ke liye
                  }}
                >
                  <span
                    className="dashboard_leads_create_link"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <MdAdd />
                    <span>Create</span>
                  </span>
                </button>
              </div>
            ) : (
              ""
            )}

          </div>
        </div>
        {/* Table Div */}
        <div className="dashboard_leads_table_div">
          {/* {loading?<Loader />: */}
          <LogCallTable
            callsPermission={callsPermission}
            pageNo={pageNo}
            pageSize={pageSize}
            loading={loading}
            tblHead={{
              firstHead: "Subject",
              secondHead: "Call Purpose",
              thirdHead: "Call Type",
              fourthHead: "Call Result",
              fifthHead: "Status",
              sixthHead: "View",
              seventhHead: "Action",
              eighthHead: "Lead Id",
            }}
            redirectLink="/log-call-details"
            getAllLogCallData={getAllLogCallData}
            logCallCostumerId={logCallCostumerId}
            setLogCallCostumerId={setLogCallCostumerId}
          />
          {/* } */}
        </div>
        {/* Pagination Div */}
        <Pagination
          maxData={totalElements}
          totalPages={totalPages}
          totalElements={totalElements}
          showingElements={showingElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
        {/* <div className="dashboard_leads_pagination_div">
          <nav aria-label="...">
            <ul className="pagination">
            
              <li className="page-item dashboard_leads_pagination_pageItem">
                <a
                  className="page-link"
                  href="#!"
                  onClick={handlePreviousPageClick}
                >
                  <IoIosArrowBack />
                </a>
              </li>

           
              {Array.from({ length: pagesToShow }, (_, index) => {
                const pageIndex = pageRangeStart + index;
                return (
                  pageIndex < totalPages && (
                    <li
                      key={pageIndex}
                      className={`page-item ${
                        pageIndex === pageNo ? "active" : ""
                      } dashboard_leads_pagination_pageItem`}
                    >
                      <a
                        className="page-link"
                        href="#!"
                        onClick={() => handlePageClick(pageIndex)}
                      >
                        {pageIndex + 1 < 10
                          ? `0${pageIndex + 1}`
                          : pageIndex + 1}
                      </a>
                    </li>
                  )
                );
              })}

              
              <li className="page-item dashboard_leads_pagination_pageItem">
                <a
                  className="page-link"
                  href="#!"
                  onClick={handleNextPageClick}
                >
                  <IoIosArrowForward className="btn_IoIosArrowForward" />
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
        {/* File Upload Modal */}
        <>
          <div
            className="modal fade"
            id="fileUploadModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <label
                      htmlFor="exampleFormControlFile1"
                      className="form-label"
                    >
                      Choose file
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="exampleFormControlFile1"
                      onChange={handleFileChange}
                    />
                  </form>
                </div>
                <div className="modal-footer" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <button className="btn btn-secondary" >Download Sample file</button>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUploadLogCalls}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/*Update call log Modal */}
        <>
          <div
            className="modal fade modal-xl"
            id="updateLogCallModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <UpdateLogCall
                    logCallCostumerId={logCallCostumerId}
                    defaultValue={defaultValue}
                    onUpdateSuccess={handleUpdateSuccess}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>

        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          handleDelete={handleDeleteLogCall}
          sourceName="CallLogs"
          setShowToast={setShowToast}
        />
        <Toast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </div>
  );
};

export default CallLogs;
