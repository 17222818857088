import React, { useState, useEffect, useCallback, useContext } from "react";
// React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPencil, BsTrash } from "react-icons/bs";
import { MdOutlineUploadFile } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import TaskTables from "../../components/TaskTables";
import UpdateTask from "../dashboardPages/UpdateTask";
// Import Toast
import Toast from "../../components/Toast";
// Import Permission Context
import permissionContext from "../PermissionsContext";
// React Router Dom
import { Link, useLocation, useNavigate } from "react-router-dom";
// Controller Method Api
import {
  getAllTask,
  deleteTasks,
  downloadTasks,
  getSingleTask,
  uploadTask,
} from "../../controller/fetchApi";
import Deleteuserconfirmation from "../../components/deleteuserconfirmation";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../../components/goback";
import Pagination from "../../components/pagination";
import { PageDetailsContext } from "../../pageDetails";
import Gobackpage from "../gobackpage";

const Task = () => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath = location.pathname;
  const navigate = useNavigate();
  // Get Task Permission
  const { tasksPermission } = useContext(permissionContext);

  // Start Toast -------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);
  // Set Task Costumer Id  to send table
  const [taskCostumerId, setTaskCostumerId] = useState([]);
  // User Id And Token
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const taskId = JSON.parse(localStorage.getItem("taskId"));
  // const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  const [getAllTaskData, setAllTaskData] = useState([]);

  //set loader
  const [loading, setloading] = useState(true);
  //  Get All Account Data
  const getTaskData = useCallback(async () => {
    try {
      const res = await getAllTask(tokenId, pageNo, pageSize);
      setAllTaskData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [tokenId, pageNo, pageSize, setAllTaskData]);

  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null,
    setLeadId: null,
  });
  const [show, setshow] = useState(false);
  // Handle Delete Account Api
  const handleDeleteTask = async () => {
    if (taskCostumerId.length) {
      try {
        await deleteTasks(taskCostumerId, setShowToast, tokenId);
        if (deleteTasks) {
          getTaskData();
          setTaskCostumerId([]);
        }
      } catch (error) {
        const errorMessage = error.message;
        console.log("Error deleting Single Account", errorMessage);
      } finally {
        setTaskCostumerId([]);
        setshow(false);
      }
    } else {
      setShowToast({
        success: true,
        message: "Select a task to delete.",
        optmessage: "Deleting task.",
      });
    }
  };
  // Download Account Api
  const handleDownloadTasks = async () => {
    try {
      await downloadTasks(setShowToast, tokenId, taskCostumerId);
    } catch (error) {
      console.log("Error downloading task", error);
    }
  };
  // Update Btn Click Action Start--------
  const [defaultValue, setDefaultValue] = useState([]);
  const handleUpdateTask = async () => {
    try {
      console.log("Enter FUnction SingleLead Update Account", taskId);
      const singleLeadResult = await getSingleTask(taskId, tokenId);
      if (singleLeadResult) {
        setDefaultValue(singleLeadResult);
      } else {
        setDefaultValue([]);
      }
    } catch (error) {
      console.log("LeadRightSection SingleLead :", error);
      setDefaultValue([]);
    }
  };
  // Define the callback function to fetch updated data
  const handleUpdateSuccess = async () => {
    try {
      await getTaskData();
    } catch (error) {
      console.log("Error fetching updated data", error);
    }
  };
  // Update Btn Click Action End ---------
  // Handle Upload File start ----
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    console.log("Selected ");
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadTask = async () => {
    if (selectedFile) {
      console.log("file selected: " + selectedFile);
      try {
        await uploadTask(selectedFile, setShowToast, tokenId);
        getTaskData();
      } catch (error) {
        console.log("Task Failed Uploading:", error);
      }
    }
  };

  // Pagination Function ------
  // const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = getAllTaskData?.totalPages || 1;
  const totalElements = getAllTaskData?.totalElements;
  const showingElements = getAllTaskData?.numberOfElements;

  useEffect(() => {
    getTaskData();
  }, [getTaskData]);
  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      <div className="dashboard_content_wrapper">
        <div className="dashboard_leads_btn_mainDiv">
          <Gobackpage pageName={pagedetails[pagePath].pageName} page="task"/>
          <div className="dashboard_leads_btns_div">
            <div className="leads_table_id_col">
              <div className="actionbtn">
                <button
                  class="default-btn dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px", // Icon aur text ke beech spacing ke liye
                  }}
                >
                  Actions
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="editDeleteDropdown"
                  >
                   

                    {/* Delete Btn */}
                    {tasksPermission?.includes("Delete") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            // handleDeleteTask(taskCostumerId,setTaskCostumerId)
                            if(getAllTaskData.length){
                              if (taskCostumerId?.length) {
                                setshow(true);
                                setdellead({
                                  leadId: taskCostumerId,
                                  setLeadId: setTaskCostumerId,
                                });
                              } else {
                                setShowToast({
                                  success: true,
                                  message: "Select Contact to delete.",
                                  optmessage: "Deleting contact.",
                                });
                              }
                            }else{
                              alert("No data availale to delete")
                            }
                           
                          }}
                        >
                          <BsTrash className="dashboard_section1_table_deleteBtn" />
                          Delete
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Upload Btn */}
                    {tasksPermission?.includes("Upload") ? (
                      <li
                        data-bs-toggle="modal"
                        data-bs-target="#fileUploadModal"
                      >
                        <span className="dropdown-item">
                          <MdOutlineUploadFile className="dashboard_section1_table_deleteBtn" />
                          Upload Task
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* Download Btn */}
                    {tasksPermission?.includes("Download") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => handleDownloadTasks()}
                        >
                          <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                          Download Task
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </button>
              </div>
            </div>

            {/* Create Btn */}
            {tasksPermission?.includes("Create") && (
              <div
                className="dashboard_leads_create_btn_div"
                onClickCapture={(event) => {
                  event.stopPropagation();
                  navigate("/create-task");
                }}
              >
                <button
                  className="default-btn"
                  style={{
                    width: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <MdAdd style={{ textAlign: "center" }} />
                  <span>Create</span>
                </button>
              </div>
            )}

          </div>
        </div>
        {/* Table Div */}
        <div className="dashboard_leads_table_div">
          <TaskTables
            tasksPermission={tasksPermission}
            pageNo={pageNo}
            pageSize={pageSize}
            loading={loading}
            tblHead={{
              firstHead: "Task Owner",
              secondHead: "Due Date",
              thirdHead: "Contact",
              fourthHead: "Subject",
              fifthHead: "Status",
              sixthHead: "View",
              seventhHead: "Action",
              eighthHead: "Lead Id",
            }}
            redirectLink="/task-details"
            getAllTaskData={getAllTaskData}
            taskCostumerId={taskCostumerId}
            setTaskCostumerId={setTaskCostumerId}
          />
        </div>
        {/* Pagination Div */}
        <Pagination
          maxData={totalElements}
          totalPages={totalPages}
          totalElements={totalElements}
          showingElements={showingElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />

        {/*Update Account  Modal */}
        <>
          <div
            className="modal fade modal-xl"
            id="updateTaskModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <UpdateTask
                    taskCostumerId={taskCostumerId}
                    defaultValue={defaultValue}
                    onUpdateSuccess={handleUpdateSuccess}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* File Upload Modal */}
        <>
          <div
            className="modal fade"
            id="fileUploadModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <label
                      htmlFor="exampleFormControlFile1"
                      className="form-label"
                    >
                      Choose file
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="exampleFormControlFile1"
                      onChange={handleFileChange}
                    />
                  </form>
                </div>
                <div className="modal-footer" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <button className="btn btn-secondary" >Download Sample file</button>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUploadTask}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          handleDelete={handleDeleteTask}
          sourceName="Task"
          setShowToast={setShowToast}
        />
        <Toast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </div>
  );
};

export default Task;
