import Toast from "./Toast";
import { useFormik } from "formik";
import { BsBuildingsFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { createCompany, updateCompanyDetail } from "../controller/fetchApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CreateCompanySchema } from "../schema/FormValidation";
import userIcon from "../images/usernewicon.svg";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { PageDetailsContext } from "../pageDetails";
import Goback from "./goback";

 
const CreateCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const companydetail = location.state?.userData;
  const pagedetails = useContext(PageDetailsContext);
  const pagePath=location.pathname;

  // Start Toast Code -------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  // Function to hide the toast after 3 seconds
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);
  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      confirmPassword: "",
      email: "",
      mobile: "",
      companyName: "",
      companyEmail: "",
      companyContact: "",
      companyAddress: "",
      // numberOfOffice: "",
      // numberOfDepartment: "",
      // numberOfDesignation: "",
    },

    validationSchema: CreateCompanySchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        await createCompany(values, setShowToast, tokenId);

        if (createCompany) {
          resetForm();
        }
      } catch (error) {
        console.log("Did Not Create Company", error);
      } finally {
        setSubmitting(false);
        setTimeout(() => {
          navigate("/companylist");
        }, 3000);
      }
    },
  });

  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };
  // Show & Hide Password
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <div>  <Goback/> <span style={{fontSize:"21px",fontWeight:"700"}}>{pagedetails[pagePath].pageName}</span></div>
      <form onSubmit={handleSubmit}>
        <div>
          <p className="create_lead_section2_company_info mt-3">
            Company Details
          </p>
        </div>
        {/* Company Information */}
        <div className="row" style={{borderBottom:"1px dotted grey",paddingBottom:"30px" ,marginBottom:"30px"}}>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="companyName">
              Company Name  <span className={touched.companyName && errors.companyName &&"required_sign"}>*</span>
            </label>
            <input
              type="text"
              id="companyName"
              maxLength={40}
              className="form-control create_lead_form_input"
              value={values.companyName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyName"
              placeholder="Enter Company Name"
            />
            {touched.companyName && errors.companyName && (
              <small className="errorMessage">{errors.companyName}</small>
            )}
            <BsBuildingsFill className="icon" />
          </div>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="companyEmail">
              Company Email <span className={touched.companyEmail && errors.companyEmail &&"required_sign"}>*</span>
            </label>
            <input
              type="email"
              id="companyEmail"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.companyEmail}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyEmail"
              placeholder="Enter Company Email"
            />
            {touched.companyEmail && errors.companyEmail && (
              <small className="errorMessage">{errors.companyEmail}</small>
            )}
            <MdEmail className="icon" />
          </div>
          <div className="form-group createLeadInput  col-lg-4 col-sm-6 ">
            <label htmlFor="companyContact">
              Company Contact <span className={touched.companyContact && errors.companyContact &&"required_sign"}>*</span>
            </label>
            <input
              type="text"
              id="companyContact"
              maxlength="10"
              className="form-control create_lead_form_input"
              value={values.companyContact}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyContact"
              placeholder="Enter Company Contact"
            />
            {touched.companyContact && errors.companyContact && (
              <small className="errorMessage">{errors.companyContact}</small>
            )}
            <FaPhone className="icon" />
          </div>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="companyAddress">
              Company Address <span className={touched.companyAddress && errors.companyAddress &&"required_sign"}>*</span>
            </label>
            <textarea
              maxLength={250}
              id="companyAddress"
              className="form-control create_lead_form_input"
              value={values.companyAddress}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyAddress"
              placeholder="Enter Company Address"
            />

            {touched.companyAddress && errors.companyAddress && (
              <small className="errorMessage">{errors.companyAddress}</small>
            )}

<MdOutlineLocationOn className="icon"   />
          
          </div>
          {/* <div className="form-group createLeadInput  col-lg-4">
            <label htmlFor="numberOfOffice">
              Number of Offices<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="numberOfOffice"
              className="form-control create_lead_form_input"
              value={values.numberOfOffice}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="numberOfOffice"
              placeholder="Enter Number of Offices"
            />
            {touched.numberOfOffice && errors.numberOfOffice && (
              <small className="errorMessage">{errors.numberOfOffice}</small>
            )}
            <FaTreeCity className="create_new_icon" />
          </div>

          <div className="form-group createLeadInput  col-lg-4">
            <label htmlFor="numberOfDepartment">
              Number of Department<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="numberOfDepartment"
              className="form-control create_lead_form_input"
              value={values.numberOfDepartment}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="numberOfDepartment"
              placeholder="Enter Number of Department"
            />
            {touched.numberOfDepartment && errors.numberOfDepartment && (
              <small className="errorMessage">{errors.numberOfDepartment}</small>
            )}
            <FaTreeCity className="create_new_icon" />
          </div>

          <div className="form-group createLeadInput  col-lg-4">
            <label htmlFor="numberOfDesignation">
              Number of Designation<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="numberOfDesignation"
              className="form-control create_lead_form_input"
              value={values.numberOfDesignation}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="numberOfDesignation"
              placeholder="Enter Number of Designation"
            />
            {touched.numberOfDesignation && errors.numberOfDesignation && (
              <small className="errorMessage">{errors.numberOfDesignation}</small>
            )}
            <FaTreeCity className="create_new_icon" />
          </div> */}
        </div>
        {/* admin detail */}
       
        <div>
          <p className="create_lead_section2_company_info mt-3">
            Admin Details
          </p>
          {/* <hr style={{height:"2px"}}/> */}
        </div>
        <div className="row">
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="firstName">
              FirstName <span className={touched.firstName && errors.firstName &&"required_sign"}>*</span>
            </label>
            <input
            autoComplete="off"
              type="text"
              // id="firstName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.firstName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="firstName"
              placeholder="Enter Firstname"
            />
            {touched.firstName && errors.firstName && (
              <small className="errorMessage">{errors.firstName}</small>
            )}
            <FaRegUser className="icon" />

            {/* <BsBuildingsFill className="create_new_icon" /> */}
          </div>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="lastName">
              LastName <span className={touched.lastName && errors.lastName &&"required_sign"}>*</span>
            </label>
            <input 
            autoComplete="off"
              type="text"
              // id="lastName"
              maxLength={20}
              className="form-control create_lead_form_input"
              value={values.lastName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="lastName"
              placeholder="Enter Lastname "
            />
            {touched.lastName && errors.lastName && (
              <small className="errorMessage">{errors.lastName}</small>
            )}
            <FaRegUser className="icon" />
            {/* <BsBuildingsFill className="create_new_icon" /> */}
          </div>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="userName">
              UserName  <span className={touched.userName && errors.userName &&"required_sign"}>*</span>
            </label>
            <input
            autoComplete="off"
              type="text"
              id="userName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.userName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="userName"
              placeholder="Enter Username "
            />
            {touched.userName && errors.userName && (
              <small className="errorMessage">{errors.userName}</small>
            )}
            <FaRegUser className="icon" />
            {/* <BsBuildingsFill className="create_new_icon" /> */}
          </div>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="email">
              Email <span className={touched.email && errors.email &&"required_sign"}>*</span>
            </label>
            <input
            autoComplete="off"
              type="email"
              // id="email"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.email}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="email"
              placeholder="Enter Email"
            />
            {touched.email && errors.email && (
              <small className="errorMessage">{errors.email}</small>
            )}
            <MdEmail className="icon" />
          </div>
          <div className="form-group createLeadInput  col-lg-4  col-sm-6">
            <label htmlFor="mobile">
              Mobile Number<span className={touched.mobile && errors.mobile &&"required_sign"}>*</span>
            </label>
            <input
            autoComplete="off"
              type="text"
              // id="mobile"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobile}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobile"
              placeholder="Enter Mobile Number"
            />
            {touched.mobile && errors.mobile && (
              <small className="errorMessage">{errors.mobile}</small>
            )}
            <FaPhone className="icon" />
          </div>

          {!companydetail && (
            <div className="form-group createLeadInput  col-lg-4  col-sm-6">
              {/* <div className="mb-xl-4 position-relative"> */}
                <label htmlFor="password">
                  Password <span className={touched.password && errors.password &&"required_sign"}>*</span>
                </label>
                <input
                  type={showPassword ? "password" : "text"}
                  minLength="6"
                  maxLength="14"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                  title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
                  className={`form-control signup_email_form_control`}
                  id="password"
                  placeholder="Create Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  autoComplete="new-password"
                  style={{marginTop:"5px"}}
                />

                {touched.password && errors.password && (
                  <small className="errorMessageSignup">
                    {errors.password}
                  </small>
                )}
                
                {showPassword ? (
                  <IoEyeOffOutline
                    onClick={handleClickShowPassword}
                    style={{ cursor: "pointer" }}
                    className="create_new_icon"
                  />
                ) : (
                  <IoEyeOutline
                    onClick={handleClickShowPassword}
                    style={{ cursor: "pointer" }}
                    className="create_new_icon"
                  />
                )}
               
              </div>
            // </div>
          )}
          {!companydetail && (
            <div className="form-group createLeadInput  col-lg-4 col-sm-6">
              {/* <div className="mb-4 position-relative"> */}
                <label htmlFor="confirmPassword">
                  Confirm Password
                  <span className={touched.confirmPassword && errors.confirmPassword &&"required_sign"}>*</span>
                </label>
                <input
                  type={showConfirmPassword ? "password" : "text"}
                  minLength={6}
                  maxLength={14}
                  className={`form-control signup_email_form_control `}
                  // id="confirmPassword"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onFocus={handleFocus}
                 autoComplete="off"
                  onBlur={handleBlur}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <small className="errorMessageSignup">
                    {errors.confirmPassword}
                  </small>
                )}

                {showConfirmPassword ? (
                  <IoEyeOffOutline
                    onClick={handleClickShowConfirmPassword}
                    className="create_new_icon"
                  />
                ) : (
                  <IoEyeOutline
                    onClick={handleClickShowConfirmPassword}
                    style={{ cursor: "pointer" }}
                    className="create_new_icon"
                  />
                )}
              </div>
            // </div>
          )}
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={isSubmitting}
          >
            {companydetail ? "Update" : "Submit"}
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};
export default CreateCompany;
