import { Link, useLocation } from "react-router-dom";
import Goback from "../components/goback";
import Pagination from "../components/pagination";
import { MdAdd } from "react-icons/md";
import ProductServicesTable from "../components/ProductServicesTable";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AddProductService from "../components/addProduct&service";
import { getProductServices } from "../controller/fetchApi";
import Toast from "../components/Toast";
import { PageDetailsContext } from "../pageDetails";
import { MdOutlineDelete } from "react-icons/md";
import Gobackpage from "./gobackpage";

const Productlist = ({ mobileview }) => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath = location.pathname;
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [addproduct, setAddProduct] = useState(false);
  const [productServicesData, setProductServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prDeleted, setPrDeleted] = useState(false);
  const [prAdded, setPrAdded] = useState(false);
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);
  //  get token from local storage
  const userIdTokenData = useMemo(
    () => JSON.parse(localStorage.getItem("user") || "{}"),
    []
  );
  const token = userIdTokenData?.data?.token;
  //  get product and services data
  const productServicesDataA = useCallback(async () => {
    try {
      const response = await getProductServices(token, pageNo, pageSize);
      setProductServicesData(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [pageNo, pageSize]);

  useEffect(() => {
    productServicesDataA();
    setPrDeleted(false);
    setPrAdded(false);
  }, [prDeleted, prAdded, pageNo, pageSize]);

  const totalElements = productServicesData?.totalElements;
  const showingElements = productServicesData?.numberOfElements;
  const totalPages = productServicesData?.totalPages;

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      <div
        className="dashboard_content_wrapper"
        style={{ paddingBottom: "20px" }}
      >
        <div id="ProductServicesTable" className="dashboard_leads_btn_mainDiv">
          <Gobackpage pageName={pagedetails[pagePath].pageName} />

          <div className="dashboard_leads_btns_div">
            {/* Create Button */}
            <div
              className="dashboard_leads_create_btn_div"
              onClick={() => setAddProduct(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="default-btn product_add newbtn"
                style={{
                  width: "90px",
                  padding: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px", // Icon aur text ke beech proper space
                }}
              >
                <MdAdd />
                <span>Add</span>
              </button>
            </div>
          </div>

        </div>
        {/* Table Div */}
        <div
          className="dashboard_leads_table_div"
          style={{ minWidth: "980px" }}
        >
          <ProductServicesTable
            pageNo={pageNo}
            pageSize={pageSize}
            setPrDeleted={setPrDeleted}
            token={token}
            loading={loading}
            productServicesData={productServicesData?.content}
            tblHead={{
              firstHead: "Sr No.",
              secondHead: "Type",
              thirdHead: "Name",
              fourthHead: "Delete",
            }}
          />
        </div>
        {/* pagination */}
        <Pagination
          maxData={totalElements}
          totalPages={totalPages}
          totalElements={totalElements}
          showingElements={showingElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
      </div>
      {addproduct && (
        <AddProductService
          setAddProduct={setAddProduct}
          setPrAdded={setPrAdded}
          token={token}
          setShowToast={setShowToast}
        />
      )}
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default Productlist;
