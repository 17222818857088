import React, { useEffect, useState, useCallback } from "react";

// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// CSS
// import "../../styles/component_css/common_css/dashboardNavbar.common.css";
// React Icon
import { IoMdNotifications } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { BiLogOutCircle } from "react-icons/bi";
import { FcAlarmClock } from "react-icons/fc";
import { CiSearch } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import { setuserImage } from "../../app/slices";
import profileImage from "../../images/dummy-profile-image.png"
// Components
import UpdateProfile from "../../pages/UpdateProfile";
import dummylogo from "../../images/dummylogo.png"
// Controller Api
import {
  logoutUser,
  getCurrentUser,
  monthlyMeetings,
} from "../../controller/fetchApi";
import { getUserImage } from "../../controller/fetchApi";
import Logo from "./logo";
import { useDispatch, useSelector } from "react-redux";

const DashboardNavbar = ({
  isSidebar,
  setIsSidebar,
  setShowSidebarSmallScreen,
  updatelogo,
  setupdatelogo,
  mobileview,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Page Name -----Start-----
  const location = useLocation();
  const pathname = location.pathname;
  const [pageName, setPageName] = useState("");

  // update profile
  const [updateProfile, setUpdateProfile] = useState(false);

  //  Sidebar Functionality-----
  const sidebarState = () => {
    setIsSidebar((prev) => !prev);
  };

  // Logout User Api Call
  const logoutUserSubmit = async () => {
    await logoutUser(tokenId);
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  // Get Current User Details
  const [getCurrentUserData, setCurrentUserData] = useState();
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
  const tokenId = userIdTokenData?.data?.token;
  const uid = userIdTokenData?.data?.userId;

  
  //  Get Current User Data
  const getUser = useCallback(async () => {
    try {
      const res = await getCurrentUser(tokenId);
      setCurrentUserData(res);
    } catch (error) {
      console.log(error);
    }
  }, [tokenId, setCurrentUserData]);

  //  console.log('new Date().toISOString().split("T")[0]',new Date().toISOString().split("T")[0])
  // Get Current Day & Time
  const getCurrentDayMeetings = (meetings) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
    return meetings?.filter((meeting) => {
      const meetingDate = new Date(meeting?.date).toISOString().split("T")[0];
      return meetingDate === today;
    });
  };
  const [currentDayMeetings, setCurrentDayMeetings] = useState([]);

  useEffect(() => {
    if (roles !== "SUPERADMIN") {
      (async () => {
        try {
          const result = await monthlyMeetings(uid, tokenId);

          if (result) {
            const todayMeetings = getCurrentDayMeetings(result);
            setCurrentDayMeetings(todayMeetings);
          } else {
            setCurrentDayMeetings([]);
          }
        } catch (error) {
          console.log(error);
          setCurrentDayMeetings([]);
        }
      })();
    }
  }, [uid, tokenId]);

  // Show Reminder
  // Show Toast When Meeting is start before 2 min
  const [showToast, setShowToast] = useState(false);
  // Second useEffect to handle meeting reminders
  const [meetPerson, setMeetPerson] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  useEffect(() => {
    const timeouts = [];

    currentDayMeetings.forEach((meeting) => {
      const meetingTime = new Date(meeting?.date);
      const currentTime = new Date();
      const twoMinutesBefore = 2 * 60 * 1000;
      const timeDifference = meetingTime - currentTime - twoMinutesBefore;
      if (timeDifference > 0) {
        const timeoutToShow = setTimeout(() => {
          setMeetPerson(meeting?.title);
          setShowToast(true);
        }, timeDifference);

        const timeoutToHide = setTimeout(() => {
          setShowToast(false);
        }, timeDifference + 15000);

        timeouts.push(timeoutToShow, timeoutToHide);
      }
    });

    // Clean up timeouts on component unmount
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [currentDayMeetings]);

  // Use Effect Function
  useEffect(() => {
    getUser();
    switch (pathname) {
      case "/dashboard":
        document.title = "Dashboard";
        setPageName("Dashboard");
        break;
      case "/created-users":
        document.title = "Created User";
        setPageName("Created User");
        break;
      case "/leads":
        document.title = "Leads";
        setPageName("Leads");
        break;
      case "/create-lead":
        document.title = "Create Lead";
        setPageName("Create Lead");
        break;
      case "/lead-details":
        document.title = "Lead Details";
        setPageName("Lead Details");
        break;
      case "/contact":
        document.title = "Contact";
        setPageName("Contact");
        break;
      case "/create-contact":
        document.title = "Create Contact";
        setPageName("Create Contact");
        break;
      case "/contact-details":
        document.title = "Contact Details";
        setPageName("Contact Details");
        break;
      case "/accounts":
        document.title = "Accounts";
        setPageName("Accounts");
        break;
      case "/create-account":
        document.title = "Create Account";
        setPageName("Create Account");
        break;
      case "/account-details":
        document.title = "Account Details";
        setPageName("Account Details");
        break;
      case "/deals":
        document.title = "Deals";
        setPageName("Deals");
        break;
      case "/create-deal":
        document.title = "Create Deal";
        setPageName("Create Deal");
        break;
      case "/deal-details":
        document.title = "Deal Details";
        setPageName("Deal Details");
        break;
      case "/tasks":
        document.title = "Tasks";
        setPageName("Tasks");
        break;
      case "/create-task":
        document.title = "Create Task";
        setPageName("Create Task");
        break;
      case "/task-details":
        document.title = "Task Details";
        setPageName("Task Details");
        break;
      case "/meetings":
        document.title = "Meetings";
        setPageName("Meetings");
        break;
      case "/create-meeting":
        document.title = "Create Meeting";
        setPageName("Create Meeting");
        break;
      case "/meetings-details":
        document.title = "Meeting Details";
        setPageName("Meeting Details");
        break;
      case "/calls":
        document.title = "Calls";
        setPageName("Calls");
        break;
      case "/call-logs":
        document.title = "Log Calls";
        setPageName("Log Calls");
        break;
      case "/call-schedule":
        document.title = "Schedule Calls";
        setPageName("Schedule Calls");
        break;
      case "/create-call":
        document.title = "Create Call";
        setPageName("Create Call");
        break;
      case "/call-details":
        document.title = "Call Details";
        setPageName("Call Details");
        break;
      case "/reports":
        document.title = "Reports";
        setPageName("Reports");
        break;
      case "/create-report":
        document.title = "Create Report";
        setPageName("Create Report");
        break;
      case "/report-details":
        document.title = "Report Details";
        setPageName("Report Details");
        break;
      case "/companylist":
        document.title = "Company List";
        setPageName("Company List");
        break;
      case "/companydetails":
        document.title = "Company Detais";
        setPageName("Company Details");
        break;
      case "/updatecompanydetails":
        document.title = "Update Details";
        setPageName("Update Details");
        break;
      case "/create-company":
        document.title = "Create Company";
        setPageName("Create Company");
        break;
      case "/role&permission":
        document.title = "Update Permission";
        setPageName("Update Permission");
        break;
      case "/schedule-call-details":
        document.title = "Schedule-Call";
        setPageName("Schedule-Call Detail");
        break;
      case "/userLogs":
        document.title = "History";
        setPageName("User Log");
        break;
      case "/log-call-details":
        document.title = "Log-Call";
        setPageName("Log-Call Detail");
        break;
      case "/product&services":
        document.title = "Product&Services";
        setPageName("Product&Service");
        break;
      default:
        document.title = "CRM - Singhsoft Product";
        setPageName("Singhsoft Product");
    }
  }, [getUser, pathname]);

  //****************get user image*****************
  const { userImage,imageUpdated} = useSelector((state) => state?.userData);

  const getuserImage = async () => {
    const res = await getUserImage(tokenId);
    if (res?.data?.status == 200) {
      dispatch(setuserImage(res?.data?.data));
    }
  };
  useEffect(() => {
    getuserImage();
  }, [updatelogo,imageUpdated]);

  return (
    <nav className="navbar navbar-expand-lg  dashboard_navbar">
      <div className="container-fluid dashboard_navbar_container_fluid">
        {!mobileview && <Logo companyLogo={userImage?.companyLogo || dummylogo} />}

        {mobileview && (
          <button className="navbar-toggler" onClick={sidebarState}>
            <span className="navbar-toggler-icon" />
          </button>
        )}
        {/* <div
          className="collapse navbar-collapse ms-xl-5"
          id="navbarSupportedContent"
      
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-right-menu-mannual">
            <li className="nav-item">
              <span
                className="nav-link dashboard_navbar_dashboard_text"
                style={{
                  color: "#353536",
                  cursor: "context-menu",
                  paddingLeft: "58px",
                }}
              >
                {pageName}
              </span>
            </li>
          </ul>
          </div> */}
        {/* Right Menu  */}
        <div className="ms-auto">
          <ul className="mb-2 mb-lg-0 navbar-right-menu-mannual">
            {/* <li className="nav-item">
                <div class="search_main">
                  <input
                    type="text" 
                    name="input"
                    class="input"
                    placeholder="Search Something....."
                  />
                  <button class="icon">
                    <CiSearch />
                  </button>
                </div>
              </li> */}

            {/* <li className="nav-item">
                <div class="notification_section">
                  <button type="button" class="icon-button">
                    <IoIosNotificationsOutline />
                    <span class="material-icons"> </span>
                    <span class="icon-button__badge">25 </span>
                  </button>   
                </div>
              </li>   */}

            <li className="nav-item">
              <div className="rounded-pill dashboard_navbar_userImg">
                <img
                  src={userImage?.userImage || profileImage}
                  alt="admin_img"
                  className="navbar_right_menu_img"
                />
              </div>
            </li>
            <li className="nav-item">
              <div className="dashboard_navbar_user_admin_text">
                <span className="dashboard_navbar_userName">
                  {getCurrentUserData?.fullName}
                </span>

                <span className="dashboard_navbar_panel">{roles}</span>
              </div>
            </li>
            <li
              className="nav-item align-content-center dropstart"
              data-bs-toggle="dropdown"
            >
              <RiArrowDropDownLine className="dashboard_navbar_drop_icon" />
              <ul className="dropdown-menu navbar_dropdown_menu">
                <li
                  // data-bs-toggle="modal"
                  // data-bs-target="#updateProfileModal"
                  // onClick={() => setUpdateProfile(true)}
                  onClick={() => navigate("updateprofile")}
                >
                  <span className="dropdown-item" style={{ cursor: "pointer" }}>
                    <CgProfile className="me-1 navbar_dropdown_icon" />
                    Update Profile
                  </span>
                </li>
                {/* </Link> */}
                <li>
                  <a
                    className="dropdown-item"
                    href="/signin"
                    onClick={logoutUserSubmit}
                  >
                    <BiLogOutCircle className="me-2 navbar_dropdown_icon" />
                    Logout
                  </a>
                </li>
                {/* </Link> */}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {/*Update Profile  Modal */}
      {/* <UpdateProfile
                setUpdateProfile={setUpdateProfile}            
                image={image}
                setPreviewImage={setPreviewImage}
                getCurrentUserData={getCurrentUserData}
                setupdateimg={setupdateimg}
                setupdatelogo={setupdatelogo}
              /> */}
      {/* {updateProfile && (
        <div className="NEWMODALupdate">
          <div className="actmodalupdate">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setUpdateProfile(false)}
              >
                X
              </button>
            </div>
            <div className="modalContent">
              <UpdateProfile
                setUpdateProfile={setUpdateProfile}            
                image={image}
                setPreviewImage={setPreviewImage}
                getCurrentUserData={getCurrentUserData}
                setupdateimg={setupdateimg}
                setupdatelogo={setupdatelogo}
              />
            </div>
          </div>
        </div>
      )} */}
      {/* Toast */}
      {showToast && (
        <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
          <div
            className="toast show create_lead_toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header create_lead_toast_header">
              <FcAlarmClock className="fs-3" />
              &nbsp;
              <strong className="me-auto">Meeting Reminder</strong>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast(false)}
              />
            </div>
            {/* <div className="toast-body">Sign In successfully.</div> */}
            <div className="toast-body">
              <small>
                Your meeting is scheduled in 2 minutes with
                <br />
                {meetPerson}
              </small>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default DashboardNavbar;
