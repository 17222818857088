import React, { useEffect, useState } from "react";
// React Router Dom
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import Loader from "../pages/Loader";
import { PiProhibit } from "react-icons/pi";
import { LiaUserEditSolid } from "react-icons/lia";
const DealsTable = ({
  dealsPermission,
  pageNo,
  pageSize,
  tblHead,
  redirectLink,
  getAllDealsData,
  dealCostumerId,
  setDealCostumerId,
  loading
}) => {
  const navigate = useNavigate();
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  // Handle Single Check Box For Single Updateion And Id get and send Start ------
  const handleCheckboxChange = (dealId) => {
    const isSelected = dealCostumerId.includes(dealId);
    if (isSelected) {
      setDealCostumerId(dealCostumerId.filter((id) => id !== dealId));
    } else {
      setDealCostumerId([...dealCostumerId, dealId]);
    }
  };

  // Handle Master Checkbox Change Start -----
  const handleMasterCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const allDealIds = getAllDealsData?.content?.map((data) => data.id) || [];
    if (isChecked) {
      setDealCostumerId(allDealIds);
    } else {
      setDealCostumerId([]);
    }
  };
  useEffect(() => {
    const allLeadIds = getAllDealsData?.content?.map((data) => data.id) || [];
    if (allLeadIds.length === 0) return;
    if (dealCostumerId.length === allLeadIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [dealCostumerId, getAllDealsData]);
  return (
    <div className="LeadRightSectionTable_body table-responsive p-0">
   
      <div className="LeadRightSectionTable_body table-responsive">
        <table className="table mytable">
          <thead>
            <tr>
            <th className=" text_center text-left text-capitalize" scope="col">
                <div className="wrap-check-29">
                  <div className="cbx">
                    <input
                      id="cbx-29"
                      type="checkbox"
                      onChange={handleMasterCheckboxChange}
                      // onClick={handleMasterCheckboxChange}
                      checked={isMasterChecked}
                    />
                    <label htmlFor="cbx-29" />
                    <svg width={15} height={14} viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2" />
                    </svg>
                  </div>
                  {/* Gooey*/}
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo-12">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation={4}
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                          result="goo-12"
                        />
                        <feBlend in="SourceGraphic" in2="goo-12" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </th>
            <th
              scope="col"
              className="text-left text_center"
              style={{ cursor: "context-menu" }}
            >
              Sr. No
            </th>
              {/* Checkbox Column */}
            
              <th scope="col" className="text-left  text_center text-capitalize">
                {tblHead.ninethHead}
              </th>
              <th className=" text_center text-left text-capitalize" scope="col">{tblHead.firstHead}</th>
              <th className=" text_center text-left text-capitalize" scope="col">{tblHead.secondHead}</th>
              <th className=" text_center text-left text-capitalize" scope="col">{tblHead.thirdHead}</th>
              <th className=" text_center text-left text-capitalize" scope="col">{tblHead.fourthHead}</th>
              <th className=" text_center text-left text-capitalize" scope="col">{tblHead.fifthHead}</th>
              <th className=" text_center text-left text-capitalize" scope="col">{tblHead.sixthHead}</th>
              <th className=" text_center text-capitalize" scope="col">{tblHead.seventhHead}</th>
              <th scope="col" className="text-center text_center text-capitalize">
                {tblHead.eighthHead}
              </th>
              <th className=" text_center text-center text-capitalize">Edit</th>
              {/* <th scope="col" className="text-center">
                {tblHead.ninethHead}
              </th> */}
            </tr>
          </thead>
          {loading ? (
        <Loader />
      ) : (
          <tbody className="dashboard_section1_tableBody ">
            {getAllDealsData && getAllDealsData?.content?.length > 0 ? (
              getAllDealsData?.content?.map((data,index) => (
                <tr
                  key={data.id}
                  onClick={() => localStorage.setItem("dealId", data.id)}
                > 
                 <td className=" text_center text-left text-capitalize">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckIndeterminate"
                      onChange={() => handleCheckboxChange(data.id)}
                      checked={dealCostumerId?.includes(data.id)}
                    />
                  </td>
                <td className="text-left text_center text-capitalize">
                <span className="Link-button-leads">
                  <span
                    style={{
                      cursor: "context-menu",
                      border: "1px solid #9c9c9c",
                      padding: "2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                      { `${pageNo * pageSize + index<9?`0${pageNo * pageSize + index + 1}`:pageNo * pageSize + index + 1}`}
                    {/* {pageNo * pageSize + index + 1} */}
                  </span>
                </span>
              </td>
                 
                  <td className="text-left text_center text-capitalize">
                      <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                      </Link>
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.dealOwner}
                    </Link>
                  </td> */}
                   <td className="text-left text-capitalize" style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.dealOwner || "No Data"}
                      <span className="tool_tip_box">  {data.dealOwner || "No Data"}</span>
                    </div>
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.dealName}
                    </Link>
                  </td> */}
                   <td className="text-left text-capitalize" style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.dealName || "No Data"}
                      <span className="tool_tip_box">  {data.dealName || "No Data"}</span>
                    </div>
                  </td>
                  <td className=" text_center text-left text-capitalize">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.amount || "No Data"}
                    </Link>
                  </td>
                  <td className=" text_center text-left text-capitalize">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.closingDate || "No Data"}
                    </Link>
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.contactName}
                    </Link>
                  </td> */}
                   <td className="text-left text-capitalize" style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.contactName || "No Data"}
                      <span className="tool_tip_box"> {data.contactName || "No Data"}</span>
                    </div>
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.stage}
                    </Link>
                  </td> */} 
                   <td className="text-left text-capitalize" style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.stage || "No Data"}
                      <span className="tool_tip_box"> {data.stage || "No Data"}</span>
                    </div>
                  </td>
                  <td className=" text_center text-capitalize">
                    <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                      <FaRegEye className="showDetailEye fs-4" />
                    </Link>
                  </td>
                  <td className="text-center text_center text-capitalize">
                    <Link to="/create-account" state={{ leadId: data.leadId }}>
                      <HiPencilSquare className="lead-table-contact-action-icon fs-4" />
                    </Link>
                  </td>
                  {/* <td className="text-center">
                    {data?.leadId && (
                      <Link to={redirectLink} className="Link-button-leads">
                      
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                       
                      </Link>
                    )}
                  </td> */}
                    <td className="text-center text-capitalize">
                      {dealsPermission?.includes("Update")?
                      <LiaUserEditSolid
                        className="lead-table-contact-action-icon fs-4"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/create-deal", {
                            state: { data, from: "update" },
                          })
                        }
                      />:<PiProhibit style={{ cursor:"crosshair" }}  className="lead-table-contact-action-icon fs-4"/>}
                    </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className=" text-capitalize text-center">No Deals Data At this Time</td>
              </tr>
            )}
          </tbody>
            )}
        </table>
      </div>
      </div>

  );
};

export default DealsTable;
