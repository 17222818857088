import React, { useState, useEffect, useRef } from "react";
// import "../styles/updateProfile.css";
// Formik
import { useFormik } from "formik";
import { updateProfileFormSchema } from "../schema/FormValidation";
import {
  updateProfile,
  uploadLogo,
  uploadUserImg,
} from "../controller/fetchApi";
import { useDispatch, useSelector } from "react-redux";
import { setImageUpdated } from "../app/slices";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import ChangePassword from "./changePasword";
import PersonalInfoUpdate from "./personalInfoUpdate";
import DummyProfile from '../images/dummy-profile-image.png'

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Handleprofile Image &&  handle logo
  const [personalInfo, setpersonalInfo] = useState(true);
  const [changePassword, setchangePassword] = useState(false);

  const [profileError, setprofileError] = useState("");
  const profileRef = useRef(null);


  //  handle user data
  const { user, userImage, imageUpdated } = useSelector(
    (state) => state?.userData
  );
  const userIdTokenData = React.useMemo(
    () => JSON.parse(localStorage.getItem("user")),
    []
  );
  const tokenId = userIdTokenData?.data?.token;
  const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // Toast Handling
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // handle profile image change
  const handleprofileImageChange = async (event) => { 
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file.size < maxSize) {
      // setSelectedImage(event.target.files[0]);
      const response = await uploadUserImg(
        event.target.files[0],
        setShowToast,
        tokenId
      );
      if (response) {
        dispatch(setImageUpdated(true));
      }
    } else {
      setprofileError("Please select small file(max size 2MB)");
    }
  };





  return (
    <div className=" px-4 mt-4">
      <div className="col-xl-6 mb-4 profile_image">
        <div style={{ position: "relative" }}>
          <div className="Upload_image_box">
            <img
              className="Upload_image"
              src={userImage?.userImage ? userImage?.userImage : DummyProfile}
              alt="Profile image"
            />
          </div>
          <div className="upload_userImage">
            <AiOutlineCamera onClick={() => profileRef.current?.click()} />
          </div>
        </div>
        {profileError && <small style={{ color: "red" }}>{profileError}</small>}
        <div className="Upload_image_button">
          <div className="fw-bold">{user.fullName}</div>
          <div style={{ fontSize: "13px" }}>{user.email}</div>
        </div>
      </div>
      <div className="mb-3 col-xl-5" style={{ display: "none" }}>
        <div className="mb-3 col-xl-12">
          <input
            ref={profileRef}
            type="file"
            className="form-control"
            id="profilePicture"
            accept=".jpg,.jpeg, .png"
            onChange={handleprofileImageChange}
          />
        </div>
      </div>
      <div className="user_profile_link" style={{
        // position:"relative",
        marginTop: "50px",
        marginLeft:"50px",
        bottom: "15px",
      }}>
        <div
          style={{
            color: personalInfo ? "blue" : "black"
          }}
          className={`set_personal_ ${personalInfo && "set_personal_Info"}`}
          onClick={() => {
            setpersonalInfo(true);
            setchangePassword(false);
          }}
        >
          personal Info.
        </div>
        <div
          className={`set_personal_ ${changePassword && "set_personal_Info"}`}
          style={{
            color: changePassword ? "blue" : "black"
          }}
          onClick={() => {
            setpersonalInfo(false);
            setchangePassword(true);
          }}
        >
          Change Password
        </div>
      </div>
     


      {/* <div className="col-xl-6 mb-4 profile_image">
        <div style={{ position: "relative", textAlign: "center" }}>
          <div
            style={{
              width: "130px",
              height: "130px",
              borderRadius: "50%",
              overflow: "hidden",
              border: "2px solid #ccc",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              margin: "0 auto",
            }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={userImage?.userImage}
              alt="Profile"
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              right: "5px",
              backgroundColor: "#f1f1f1",
              padding: "8px",
              borderRadius: "50%",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => profileRef.current?.click()}
          >
            <AiOutlineCamera style={{ fontSize: "18px", color: "#333" }} />
          </div>
        </div>

        {profileError && (
          <small
            style={{
              color: "red",
              fontSize: "12px",
              marginTop: "5px",
              display: "block",
              textAlign: "center",
            }}
          >
            {profileError}
          </small>
        )}

        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>{user.fullName}</div>
          <div style={{ fontSize: "13px", color: "#777" }}>{user.email}</div>
        </div>
      </div>

     
      <input
        ref={profileRef}
        type="file"
        className="form-control"
        id="profilePicture"
        accept=".jpg,.jpeg, .png"
        onChange={handleprofileImageChange}
        style={{ display: "none" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            padding: "10px 15px",
            borderRadius: "5px",
            backgroundColor:  "#007bff" ,
            color: personalInfo ? "#fff" : "#333",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => {
            setpersonalInfo(true);
            setchangePassword(false);
          }}
        >
          Personal Info
        </div>
        <div
          style={{
            padding: "10px 15px",
            borderRadius: "5px",
            backgroundColor: changePassword ? "#007bff" : "#f1f1f1",
            color: changePassword ? "#fff" : "#333",
            fontWeight: "bold",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onClick={() => {
            setpersonalInfo(false);
            setchangePassword(true);
          }}
        >
          Change Password
        </div>
      </div> */}

      {/* from part */}
      <hr className="mt-0 mb-4 " style={{marginLeft:"50px"}}  />
      <div className="row" style={{ paddingBottom: "40px" }}>
        <div className="col-xl-12">
          <div className=" mb-4">
            {personalInfo && (
              <PersonalInfoUpdate setShowToast={setShowToast} />
            )}
            {changePassword && (
              <ChangePassword setpersonalInfo={setpersonalInfo} setchangePassword={setchangePassword} setShowToast={setShowToast} />
            )}
          </div>
        </div>
      </div>
      {/* Toast */}
      {showToast.message && (
        <div
          className="toast-container position-fixed  end-0 p-3 "
          style={{ top: "5%" }}
        >
          <div
            className="toast show create_lead_toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header create_lead_toast_header">
              <strong className="me-auto">
                {/* Form Submitted Successfully */}
                {showToast.success ? showToast?.optmessage : "Error"}
              </strong>
              <button
                type="button"
                className="btn-close"
                onClick={() =>
                  setShowToast({ success: false, message: "", optmessage: "" })
                }
              />
            </div>
            <div className="toast-body">{showToast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateProfile;
