import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, Link } from "react-router-dom";
//Import React Icon
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaTreeCity } from "react-icons/fa6";
import { PiUserListFill } from "react-icons/pi";
import { MdCurrencyRupee } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
// Schema
import { DealFormSchema } from "../../schema/FormValidation";
// Import Toast
import Toast from "../../components/Toast";
// Import api function from controller
import { createDeal, dealStagesDropdowns, updateDeal } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage";

const CreateDeal = () => {
  const navigate = useNavigate();
  // Get Lead Id
  const location = useLocation();
  const leadId = location.state?.leadId;
  const data = location.state?.data;
  const navigatingFrom = location.state?.from;
  // Start Toast Code -------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  // Function to hide the toast after 3 seconds
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);
  const dealId = JSON.parse(localStorage.getItem("dealId"));
  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      dealOwner: userIdTokenData?.data?.fullName,
      dealName: data?data?.dealName:"",
      amount: data?data?.amount:"",
      closingDate: data?data?.closingDate:"",
      accountName:data?data?.accountName:"",
      stage:data?data?.stage:"",
      type: data?data?.type:"",
      nextStep:data?data?.nextStep:"",
      expectedRevenue:data?data?.expectedRevenue:"",
      leadSource: data?data?.leadSource:"",
      contactName:data?data?.contactName:"",
      // leadId: leadId,
      leadId:data?.leadId || leadId,
      description: data?data?.description:"",
    },

    validationSchema: DealFormSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (navigatingFrom == "update") {
          const updates = Object.keys(values).filter((key) => {
            if (Array.isArray(values[key])) {
              return values[key].length == data[key].length
                ? values[key].length
                  ? values[key].some((elm) => !data[key].includes(elm))
                    ? true
                    : false
                  : false
                : true;
            } else {
              return values[key] && values[key] != data[key];
            }
          });

          let updatedData = {};
          if (updates.length) {
            updates.forEach((key) => (updatedData[key] = values[key]));
          await updateDeal(dealId, updatedData, setShowToast, tokenId);
          setTimeout(() => {
            navigate("/deals");
          }, 2000);
          } else {
            alert("No updated data.");
          }
        } else {
          const response = await createDeal(uid, values, setShowToast, tokenId);
          if (response.data?.status === 200) {
            resetForm();
            setTimeout(() => {
              navigate("/deals");
            }, 2000);
          }
        }
      } catch (error) {
        console.log("Found Error", error);
      } finally {
        setSubmitting(false);
       
      }
    },
  });
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  // Deal Stages Dropdown
  const [dealStage, setDealSatge] = useState();
  const getdealStagesDropdown = useCallback(async () => {
    try {
      const dealStagesDropdown = await dealStagesDropdowns(tokenId);
      setDealSatge(dealStagesDropdown);
    } catch (error) {
      console.log(error);
    }
  }, [tokenId]);

  // *******************
  // console.log(dealStage,'dealStage dealStage dealStage')
  useEffect(() => {
    getdealStagesDropdown();
  }, [getdealStagesDropdown]);
  return (
    <div className="create_lead_form_main_div dashboard_create_lead_main_container">
      <Gobackpage pageName= {navigatingFrom=="update" ? "Update Deal" : "Create Deal"}/>
    
      <form onSubmit={handleSubmit}>
        {/* User Account Information */}
        <div className="row">
          <p className="create_lead_section2_company_info ">Deal Information</p>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="dealOwner">
              Deal Owner <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="dealOwner"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.dealOwner}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="dealOwner"
              placeholder="Enter Owner Name"
              disabled
            />
            {touched.dealOwner && errors.dealOwner && (
              <small className="errorMessage">{errors.dealOwner}</small>
            )}
            {/* <MdAdminPanelSettings className="create_lead_input_icon" /> */}
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="dealName">
              Deal Name <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="dealName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.dealName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="dealName"
              placeholder="Enter Deal Name"
            />
            {touched.dealName && errors.dealName && (
              <small className="errorMessage">{errors.dealName}</small>
            )}
            <PiUserListFill className="icon" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="amount">
              Amount <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="amount"
              maxLength={12}
              className="form-control create_lead_form_input"
              value={values.amount}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="amount"
              placeholder="Enter Amount"
            />
            {touched.amount && errors.amount && (
              <small className="errorMessage">{errors.amount}</small>
            )}
            <MdCurrencyRupee  className="create_lead_input_iconnew icon" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="closingDate">
              Closing Date <span className="required_sign">*</span>
            </label>
            <input
              type="date"
              id="closingDate"
              className="form-control create_lead_form_input deal_form_input"
              value={values.closingDate}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="closingDate"
              placeholder="Enter Closing Date"
            />
            {touched.closingDate && errors.closingDate && (
              <small className="errorMessage">{errors.closingDate}</small>
            )}
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="accountName">
              Account Name<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="accountName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.accountName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="accountName"
              placeholder="Enter Account Name"
            />
            {touched.accountName && errors.accountName && (
              <small className="errorMessage">{errors.accountName}</small>
            )}
            <PiUserListFill className="icon" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="stage">
              Stage <span className="required_sign">*</span>
            </label>
            <select
              id="stage"
              className="form-control create_lead_form_input"
              value={values.stage}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="stage"
            >
              <option value="" hidden>
                {/* {touched.stage && errors.stage ? (
                  <p className="text-danger">{errors.stage}</p>
                ) : (
                  "Qualification"
                )} */}
                Choose Stage
              </option>
              {dealStage && dealStage?.length > 0
                ? dealStage.map((dealStage) => (
                    <option key={dealStage?.id} value={dealStage?.value}>
                      {dealStage?.stage}
                    </option>
                  ))
                : ""}
            </select>
            {touched.stage && errors.stage && (
              <small className="errorMessage">{errors.stage}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_icon" /> */}
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="type">
              Type <span className="required_sign">*</span>
            </label>
            <select
              id="type"
              className="form-control create_lead_form_input"
              value={values.type}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="type"
            >
              <option value="" hidden>
                {/* {touched.type && errors.type ? (
                  <p className="text-danger">{errors.type}</p>
                ) : (
                  "Qualification"
                )} */}
                Choose Business Type
              </option>
              <option value="existing-business">Existing Business</option>
              <option value="new-business">New Business</option>
            </select>
            {touched.type && errors.type && (
              <small className="errorMessage">{errors.type}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_icon" /> */}
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="nextStep">
              Next Step <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="nextStep"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.nextStep}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="nextStep"
              placeholder="Enter Next Step"
            />
            {touched.nextStep && errors.nextStep && (
              <small className="errorMessage">{errors.nextStep}</small>
            )}
            {/* <FaPhone className="create_lead_input_icon" /> */}
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="expectedRevenue">
              Expected Revenue <span className="required_sign">*</span>
            </label>
            <input
              type="number"
              id="expectedRevenue"
              maxLength={12}
              className="form-control create_lead_form_input"
              value={values.expectedRevenue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="expectedRevenue"
              placeholder="Enter Expected Revenue"
            />
            {touched.expectedRevenue && errors.expectedRevenue && (
              <small className="errorMessage">{errors.expectedRevenue}</small>
            )}
            <MdCurrencyRupee  className="icon" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="leadSource">
              Lead Source <span className="required_sign">*</span>
            </label>
            <select
              id="leadSource"
              className="form-control create_lead_form_input"
              value={values.leadSource}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadSource"
              placeholder="Enter Lead Source"
            >
              <option value="" hidden>
                {/* {touched.leadSource && errors.leadSource ? (
                  <p className="text-danger">{errors.leadSource}</p>
                ) : (
                  "Select Source"
                )} */}
               Enter Lead Source
              </option>
              <option value="advertisement">Advertisement</option>
              <option value="cold-call">Cold Call</option>
              <option value="employee-referral">Employee Referral</option>
              <option value="external-referral">External Refferal</option>
              <option value="other">Other</option>
            </select>
            {touched.leadSource && errors.leadSource && (
              <small className="errorMessage">{errors.leadSource}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_icon" /> */}
          </div>
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="campaignSource">
              Campaign Source <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="campaignSource"
              className="form-control create_lead_form_input"
              value={values.campaignSource}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="campaignSource"
              placeholder="Enter Campaign Source"
            />
            {touched.campaignSource && errors.campaignSource && (
              <small className="errorMessage">{errors.campaignSource}</small>
            )}
           
          </div> */}
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="contactName">
              Contact Name <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="contactName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.contactName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="contactName"
              placeholder="Enter Contact Name"
            />
            {touched.contactName && errors.contactName && (
              <small className="errorMessage">{errors.contactName}</small>
            )}
            <FaRegUser className="icon" />
          </div>
          <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="leadId">
              Lead Id <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="leadId"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.leadId}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadId"
              placeholder="Enter Lead Id"
              disabled
            />
            {touched.leadId && errors.leadId && (
              <small className="errorMessage">{errors.leadId}</small>
            )}
            {/* <FaTreeCity className="create_lead_input_icon" /> */}
          </div>
        </div>
        {/* Description */}
        <div className="row">
          {/* <p className="create_lead_section2_description">
            Description Information
          </p> */}
          <div className="form-group ">
            <label
              htmlFor="description"
              className="create_lead_section2_description_label"
            >
              Description
            </label>
            <textarea
              id="description"
              maxLength={250}
              className="form-control create_lead_form_input"
              value={values.description}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="description"
              rows="3"
              placeholder={
                touched.description && errors.description
                  ? errors.description
                  : null
              }
            ></textarea>
          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center mb-2">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={isSubmitting}
          >
           {navigatingFrom=="update" ? "Update" : "submit"}
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreateDeal;
