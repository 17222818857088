import React, { useState, useEffect, useCallback, lazy, Suspense, startTransition, useContext } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CreatedUserTable from "../components/CreatedUserTable";
// Controller Method
import { getAllUsersMadeByAdmin } from "../controller/fetchApi";
import Loader from "./Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import Signup from "./Signup";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../components/goback";
// import { Pagination } from "react-bootstrap";
import Pagination from "../components/pagination";
import { PageDetailsContext } from "../pageDetails";
import Gobackpage from "./gobackpage";
// const Signup = lazy(() => import("./Signup"));

const CreatedUser = () => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath = location.pathname;

  const navigate = useNavigate();
  const [createUser, setcreateUser] = useState(false);
  // Start Toast -------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(10);

  //reset user data
  const [currdata, setcurrdata] = useState(false);
  //  Get All Users Api
  const [getAllUsers, setAlluser] = useState(null);
  //set loader
  const [loading, setloading] = useState(true);

  const getAllUser = useCallback(async () => {
    const result = await getAllUsersMadeByAdmin(tokenId, pageNo, pageSize);
    if (result) {
      // startTransition(()=>{ setAlluser(result);})
      setAlluser(result);
      setloading(false);
    }
    setloading(false);
  }, [tokenId, setAlluser, pageNo, pageSize]);

  useEffect(() => {
    getAllUser();
  }, [getAllUser, currdata]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">

      <div className="dashboard_content_wrapper">
        <div className="dashboard_leads_btn_mainDiv">
          <Gobackpage pageName={pagedetails[pagePath].pageName} />
          {/* <div>  <Goback /> <span style={{ fontSize: "21px", fontWeight: "700" }}>{pagedetails[pagePath].pageName}</span></div> */}
          {["SUPERADMIN", "ADMIN"].includes(userrole) && (
            <div className="dashboard_leads_btns_div">
              <div className="actionbtn">
                <button
                  className="default-btn"
                  onClick={() => navigate("/createuser")}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px", // Icon aur text ke beech proper spacing
                  }}
                >
                  <MdAdd />
                  <span>Create User</span>
                </button>
              </div>
            </div>
          )}

        </div>
        {/* Table Div */}


        <div class="responsivetable">
          <table class="table mytable">
            <CreatedUserTable
              loading={loading}
              setcurrdata={setcurrdata}
              tblHead={{
                firstHead: "Sr. No",
                secondHead: "Name",
                thirdHead: "Email",
                seventhHead: "Role",
                fourthHead: "Contact",
                fifthHead: "View",
                sixthHead: "Permissions",
                eighthHead: "Log",
                ninthHead: "Activity",
              }}
              data={getAllUsers?.data}
              getAllUser={getAllUser}
              redirectLink="/role&permission"
            />
          </table>
        </div>




        <Pagination
          maxData={getAllUsers?.data?.totalElements}
          totalPages={getAllUsers?.data?.totalPages}
          totalElements={getAllUsers?.data?.totalElements}
          showingElements={getAllUsers?.data?.currentPageElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
      </div>


      {/* {createUser && (
        <div className="NEWMODALupdate">
          <div className="actmodalupdate">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setcreateUser(null)}
              >
                X
              </button>
            </div>
            <div className="modalContent">
              <Suspense fallback={<div>Loading...</div>}>
                <Signup />
              </Suspense>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CreatedUser;
