import React, { useCallback, useEffect, useState} from "react";
import Loader from "../pages/Loader";
import { AiTwotoneDelete } from "react-icons/ai";
import { deleteProductServices } from "../controller/fetchApi";
import Toast from "./Toast";
import Deleteuserconfirmation from "./deleteuserconfirmation";
import { MdDelete, MdOutlineDelete } from "react-icons/md";

function ProductServicesTable({
  pageSize,pageNo,
  setPrDeleted,
  token,
  tblHead,
  productServicesData,
  loading,
}) {
  const [productId,setproductId] = useState(null)
     const [show, setshow] = useState(false);
      const [showToast, setShowToast] = useState({
            success: false,
            message: "",
            optmessage: "",
          });
        
          useEffect(() => {
            if (showToast.message) {
              const timer = setTimeout(() => {
                setShowToast({ success: false, message: "", optmessage: "" });
              }, 3000);
        
              return () => clearTimeout(timer);
            }
          }, [showToast]);

  const deleteProductandservices = useCallback(async (mpin) => {

    const res = await deleteProductServices(token,mpin, productId);
      if (res?.status == 200) {
        setPrDeleted(true);
        setshow(false)
        setShowToast({ success: true, message: "Deleted successfully.", optmessage: "Deleting product&service..." })
      }
      else{
        setShowToast({ success: true, message: "Please enter correct mpin.", optmessage: "Deleting product&service..." })
      }
   
  }, [productId]);


  return (
    <div className="LeadRightSectionTable_body table-responsive p-0" style={{height:"100%",overflow:"hidden"}}>
      <div className="LeadRightSectionTable_body table-responsive" style={{height:"100%"}}>
        <table className="table mytable">
          <thead>
            <tr>
              <th scope="col"  style={{ width: "25%" }} className="text_center text-capitalize">{tblHead.firstHead}</th>
              <th scope="col"  style={{ width: "25%" }} className="text_center text-capitalize">{tblHead.secondHead}</th>
              <th scope="col"  style={{ width: "25%" }} className="text_center text-capitalize">{tblHead.thirdHead}</th>
              <th scope="col"  style={{ width: "25%" }} className="text_center text-capitalize">{tblHead.fourthHead}</th>
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody className="dashboard_section1_tableBody ">
              {productServicesData && productServicesData.length > 0 ? (
                productServicesData?.map((data, index) => (
                  <tr key={data.id}>
                   {/* <td className="text-left text_center">
                    <span className="Link-button-leads">
                      <span
                        style={{
                          cursor: "context-menu",
                          border: "1px solid #9c9c9c",
                          padding: "2px 4px",
                          borderRadius: "3px",
                        }}
                      >
                        { `${index<9?`0${index+1}`:index+1}`}
                      </span>
                    </span>
                  </td> */}
                  <td className="text-left text_center text-capitalize">
                    <span className="Link-button-leads">
                      <span
                        style={{
                          cursor: "context-menu",
                          border: "1px solid #9c9c9c",
                          padding: "2px 4px",
                          borderRadius: "3px",
                        }}
                      >
                        {`${
                          pageNo * pageSize + index < 9
                            ? `0${pageNo * pageSize + index + 1}`
                            : pageNo * pageSize + index + 1
                        }`}
                      </span>
                    </span>
                  </td>
                    <td className="text_center text-capitalize">{data?.type}</td>
                    <td style={{ position: "relative" ,textTransform:"capitalize"}}>
                  <div className="tool_tip">
                  {data?.name}
                    <span className="tool_tip_box">{data?.name}</span>
                  </div>
                </td>
                    {/* <td className="text_center">{data?.name}</td> */}
                    <td className="text_center text-capitalize">
                    <MdOutlineDelete  style={{
                          height: "30px",
                          width: "25px",
                          cursor: "pointer",
                          color:"#ba0610",
                        }}
                        onClick={() =>{ setshow(data?.id);
                          setproductId(data?.id)
                        }}/>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12"className="text-center" style={{ paddingLeft: "30px" ,textTransform:"capitalize"}}>
                    No product and service available{" "}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {show && (
        <Deleteuserconfirmation
        setShowToast={setShowToast}
          show={show}
          setShow={setshow}
          handleDelete={deleteProductandservices}
          deleteId={show}
          sourceName="product and service"
        />
      )}
       <Toast showToast={showToast} setShowToast={setShowToast}/>
    </div>
  );
}

export default ProductServicesTable;
