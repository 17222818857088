import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
// React Icon
import { MdEmail } from "react-icons/md";
import { BsBuildingsFill } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { TaskFormSchema } from "../../schema/FormValidation";
import { MdKeyboardArrowDown } from "react-icons/md";
// CSS
// import "../../styles/dashboardCss/createTask.css";
// Import Toast
import Toast from "../../components/Toast";
// Controller Methods
import { createTask, taskStatusDropdowns, updateTask } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { AiOutlineUser, AiOutlineCalendar, AiOutlinePhone, AiOutlineIdcard } from "react-icons/ai";
import { MdSubject, MdOutlinePriorityHigh } from "react-icons/md";
import { FaRegListAlt } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage";

const CreateTask = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const leadId = location.state?.leadId;
  const data = location.state?.data;
  const navigatingFrom = location.state?.from;
  


  // const leadId = location.state?.leadId;
  // Toast
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: '' });
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast])
  //task submit
  //const [tasksum,setsum]= useState(false);
  // Get TokenId and Uid
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting
  } = useFormik({
    initialValues: {
      taskOwner: userIdTokenData?.data?.fullName,
      subject: data ? data?.subject : "",
      dueDate: data ? data?.dueDate : "",
      contact: data ? data?.contact : "",
      status: data ? data?.status : "",
      priority: data ? data?.priority : "",
      // leadId: leadId,
      leadId: data ? data?.leadId : "",
      description: data ? data?.description : "",
      accountType: data ? data?.accountType : "",
      reminder: data ? data?.reminder : "",
    },

    validationSchema: TaskFormSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      console.log('updating task', values);

      try {
        if (navigatingFrom == "update") {
          const updates = Object.keys(values).filter(key => {
            if (Array.isArray(values[key])) {
              console.log('participants is array')
              return values[key].length == data[key].length ? values[key].length ? values[key].some(elm => !data[key].includes(elm)) ? true : false : false : true;
            }
            else {
              return (values[key] && values[key] != data[key]);
            }
          });

          let updatedData = {}
          if (updates.length) {
            updates.forEach(key => updatedData[key] = values[key]);
            await updateTask(data?.id, updatedData, setShowToast, tokenId);
          }
          else {
            alert("No updated data.")
          }
        } else {
          const response = await createTask(uid, values, setShowToast, tokenId);
          if (response?.data?.status === 200) {
            navigate("/tasks")
          }
          resetForm();
        }




      } catch (error) {
        console.log("Found Error", error);

      } finally {
        setSubmitting(false);
      }
    },
  });

  // Controll Reminder Open Close
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  // Task Status Dropdown
  const [taskStatus, setTaskStatus] = useState();

  const getTaskStatusDropdown = useCallback(async () => {
    try {
      const taskStatusDropdown = await taskStatusDropdowns(tokenId);
      setTaskStatus(taskStatusDropdown);
    } catch (error) {
      console.log(error);
    }
  }, [tokenId]);

  useEffect(() => {
    getTaskStatusDropdown();
  }, [getTaskStatusDropdown]);

  return (
    <div>
      <Gobackpage pageName= {navigatingFrom=="update" ? "Update Task" : "Create Task"}/>
     
      {/* <form onSubmit={handleSubmit} style={{ padding: '20px'}} >
        
        <div className="row">
        <p className="create_lead_section2_company_info mt-3">
            Company Details
          </p>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="taskOwner">
              Task Owner <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="taskOwner"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.taskOwner}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="taskOwner"
              placeholder="Enter owner name"
              disabled
            />
            {touched.taskOwner && errors.taskOwner && (
              <small className="errorMessage">{errors.taskOwner}</small>
            )}
           
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="taskSubject">
              Subject <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="taskSubject"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.subject}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="subject"
              placeholder="Enter Subject"
            />
            {touched.subject && errors.subject && (
              <small className="errorMessage">{errors.subject}</small>
            )}
            
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="dueDate">
              Due Date <span className="required_sign">*</span>
            </label>
            <input
              type="date"
              id="dueDate"
              className="form-control create_lead_form_input"
              value={values.dueDate}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="dueDate"
            />
            {touched.dueDate && errors.dueDate && (
              <small className="errorMessage">{errors.dueDate}</small>
            )}
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="contact">
              Contact <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="contact"
              maxLength={10}
              className="form-control create_lead_form_input" 
              value={values.contact}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="contact"
              placeholder="Enter Contact Number"
            />
            {touched.contact && errors.contact && (
              <small className="errorMessage">{errors.contact}</small>
            )}
            
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="accountType">
              Account Type <span className="required_sign">*</span>
            </label>
            <select
              id="accountType"
              className="form-control"
              value={values.accountType}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="accountType"
              style={{color:'#6b6c6e'}}
            >
              <option value="" hidden>
                
                Select Account Type
              </option>
              <option value="web-download">Client</option>
              <option value="web-search">Reseller</option>
              <option value="advertisement">Competitor</option>
              <option value="employee-referral">Analyst</option>
            </select>
            {touched.accountType && errors.accountType && (
              <small className="errorMessage">{errors.accountType}</small>
            )}
            
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="status">
              Status <span className="required_sign">*</span>
            </label>
            <select
              id="status"
              className="form-control"
              value={values.status}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="status"
              style={{color:'#6b6c6e'}}
            >
              <option value="" hidden>
               
                Select Status
              </option>
              {taskStatus && taskStatus?.length > 0
                ? taskStatus.map((status) => (
                    <option key={status.id} value={status.value}>
                      {status.status}
                    </option>
                  ))
                : ""}
            </select>
            {touched.status && errors.status && (
              <small className="errorMessage">{errors.status}</small>
            )}
           
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6 ">
            <label htmlFor="priority">
               Priority <span className="required_sign">*</span>
            </label>
            <select
              id="priority"
              className="form-control"
              value={values.priority}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="priority"
              style={{color:'#6b6c6e'}}
            >
              <option value="" hidden>
               
                Select Priority
              </option>
              <option value="not-started">Normal</option>
              <option value="deffered">Low</option>
              <option value="in-progress">High</option>
            </select>
            {touched.priority && errors.priority && (
              <small className="errorMessage">{errors.priority}</small>
            )}
            
          </div>
          <div className="form-group createLeadInput col-md-4 col-sm-6">
            <label htmlFor="leadId">
              Lead Id 
            </label>
            <input
              type="text"
              id="leadId"
              className="form-control create_lead_form_input"
              value={values.leadId}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadId"
              placeholder="Enter LeadId"
              disabled
            />
            {touched.leadId && errors.leadId && (
              <small className="errorMessage">{errors.leadId}</small>
            )}
            
          </div>
         
          <div className="form-group createLeadInput col-md-4 col-sm-6 create_task_reminder">
            <div className="d-xl-flex justify-content-between align-items-center" style={{marginTop:'24px'}}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isOpen}
                  onChange={handleToggle}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Reminder <span className="required_sign">*</span>
                </label>
              </div>
              {isOpen && (
                <div className="ml-3">
                  <label htmlFor="reminderDateTime">Notify Email: &nbsp;</label>
                  <input
                    type="datetime-local"
                    id="reminderDateTime"
                    name="reminder"
                    value={values.dateTime}
                    // onChange={(e) => setDateTime(e.target.value)}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    // value={reminderTime}
                    // onBlur={handleDateTimeChange}
                  />
                </div>
              )}
            </div>
          </div>

        </div>
      
        <div className="row">
          <div className="form-group ">
            <label
              htmlFor="description"
              className="create_lead_section2_description_label"
            >
              Description <span className="required_sign">*</span>
            </label>
            <textarea
              id="description"
              className="form-control create_lead_form_input"
              value={values.description}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="description"
              rows="3"
              placeholder={
                touched.description && errors.description
                  ? errors.description
                  : null
              }
            ></textarea>
          </div>
        </div>
      
        <div className="text-center">
          <button className="create_lead_form_submitBtn" type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </div>
      </form> */}

      <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
        {/* Company Information */}
        <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Company Details</p>

        <div
          className="form-flex"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            justifyContent: 'space-between'
          }}
        >
          {[
            { label: 'Task Owner', name: 'taskOwner', type: 'text', placeholder: 'Enter owner name', disabled: true, icon: <FaRegUser /> },
            { label: 'Subject', name: 'subject', type: 'text', placeholder: 'Enter Subject', icon: <MdSubject /> },
            { label: 'Due Date', name: 'dueDate', type: 'date', },
            { label: 'Contact', name: 'contact', type: 'text', placeholder: 'Enter Contact Number', maxLength: 10, icon: <AiOutlinePhone /> },
            { label: 'Lead Id', name: 'leadId', type: 'text', placeholder: 'Enter LeadId', disabled: true, icon: <AiOutlineIdcard /> }
          ].map(({ label, name, icon, ...rest }) => (
            <div key={name} style={{ flex: '1 1 calc(33.333% - 15px)', minWidth: '250px', position: 'relative' }}>
              <label htmlFor={name}>{label} <span style={{ color: 'red' }}>*</span></label>
              <div style={{ position: 'relative' }}>
                <span cclassName="icon" style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', color: '#888' }}>
                  {icon}
                </span>
                <input
                  id={name}
                  className="form-control"
                  value={values[name]}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  name={name}
                  style={{  width: '100%' }} 
                  {...rest}
                />
              </div>
              {touched[name] && errors[name] && (
                <small style={{ color: 'red', fontSize: '12px', marginTop: '4px', display: 'block' }}>{errors[name]}</small>
              )}
            </div>
          ))}

          {[
            {
              label: 'Account Type', name: 'accountType', icon: <FaRegListAlt className=""/>, options: [
                { value: '', label: 'Select Account Type', hidden: true },
                { value: 'web-download', label: 'Client' },
                { value: 'web-search', label: 'Reseller' },
                { value: 'advertisement', label: 'Competitor' },
                { value: 'employee-referral', label: 'Analyst' }
              ]
            },
            { label: 'Status', name: 'status', icon: <FaRegListAlt />, options: taskStatus?.map(status => ({ value: status.value, label: status.status })) || [] },
            {
              label: 'Priority', name: 'priority', icon: <MdOutlinePriorityHigh />, options: [
                { value: '', label: 'Select Priority', hidden: true },
                { value: 'not-started', label: 'Normal' },
                { value: 'deferred', label: 'Low' },
                { value: 'in-progress', label: 'High' }
              ]
            }
          ].map(({ label, name, icon, options }) => (
            <div key={name} style={{ flex: '1 1 calc(33.333% - 15px)', minWidth: '250px', position: 'relative' }}>
              <label htmlFor={name}>{label} <span style={{ color: 'red' }}>*</span></label>
              <div style={{ position: 'relative' }}>
                <span  className=" icon"style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', color: '#888' }}>
                  {icon}
                </span>
                <select
                  id={name}
                  className="form-control"
                  value={values[name]}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  name={name}
                  style={{  width: '100%' }}
                >
                  {options.map(({ value, label, hidden }) => (
                    <option key={value} value={value} hidden={hidden}>{label}</option>
                  ))}
                </select>
              </div>
              {touched[name] && errors[name] && (
                <small style={{ color: 'red', fontSize: '12px', marginTop: '4px', display: 'block' }}>{errors[name]}</small>
              )}
            </div>
          ))}
        </div>

        {/* Reminder */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
          <input type="checkbox" id="reminder" checked={isOpen} onChange={handleToggle} />
          <label htmlFor="reminder">Reminder <span style={{ color: 'red' }}>*</span></label>
          {isOpen && (
            <input
              type="datetime-local"
              id="reminderDateTime"
              name="reminder"
              value={values.dateTime}
              onChange={handleChange}
              onFocus={handleFocus}
              style={{ padding: '5px' }}
            />
          )}
        </div>

        {/* Description */}
        <div style={{ marginTop: '15px' }}>
          <label htmlFor="description">Description <span style={{ color: 'red' }}>*</span></label>
          <textarea
            id="description"
            className="form-control"
            value={values.description}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name="description"
            rows="3"
            style={{ padding: '8px', resize: 'none', width: '100%' }}
          />
        </div>

        {/* Submit Button */}
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <button type="submit" disabled={isSubmitting} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          {navigatingFrom=="update" ? "Update" : "submit"}
          </button>
        </div>
      </form>




      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreateTask;
