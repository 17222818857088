

import { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const ValidateRoute = ({ children, requiredRoles = [], requiredModule }) => {
  const location = useLocation();
 
  const userIdTokenData = useMemo(
    () => JSON.parse(localStorage.getItem("user") || "{}"),
    []
  );

 
  const user = userIdTokenData?.data;
  const token = user?.token;
  const permission =
    userIdTokenData?.data?.roleAndPermissions?.roles[0]?.modules || [];
  const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0];
  // Get Module Array From Local Storage Data
  let moduleArray = [];
  if (Array.isArray(permission)) {
    for (let i of permission) {
      moduleArray.push(i.module);
    }
  }
  if (!token&&location.pathname!=='/') {
    return <Navigate to="/login" />;
  }

 
  if(token&&location.pathname==='/'){
    return roles?.role==='SUPERADMIN'?<Navigate to="/companylist" />:<Navigate to='/dashboard' />
  }

  if (requiredRoles.length && !requiredRoles?.includes(roles?.role)) {
    return <Navigate to="/error-page" />;
  }

  if (requiredModule && !moduleArray.includes(requiredModule)) {
    return <Navigate to="/error-page" />;
  }

  return children;
};

export default ValidateRoute;
