import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// Controller Api's --js data
import { monthlyTask } from "../../controller/fetchApi";
import Loader2 from "../../pages/Loader2";
import { CiFlag1 } from "react-icons/ci";
import { HiFlag } from "react-icons/hi";
const DashboardSection2Table = () => {
  // Get Specific User Id who see the dashboard
  const location = useLocation();
  const userId = location.state?.userId;
  const [loading, setLoading] = useState();
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const [monthlyTaskData, setMonthlyTaskData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await monthlyTask(userId, tokenId);
        if (result === null || result === undefined) {
          setMonthlyTaskData();
          setLoading(false);
        } else {
          setMonthlyTaskData(result);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
    // monthlyMeetings(uid, tokenId).then((res) => {
    //   setMonthlyMeetingsData(res);
    // });
  }, [userId, tokenId]);
  // useEffect(() => {
  //   monthlyTask(uid, tokenId).then((res) => {
  //     setMonthlyTaskData(res);
  //   });
  // }, [uid, tokenId]);
  // Function to return the class name based on status
  const getStatusClassName = (status) => {
    switch (status.toLowerCase()) {
      case "not-started":
        return "status-not-started";
      case "success":
        return "status-success";
      case "in-progress":
        return "status-in-progress";
      case "deffered":
        return "status-deffered";
      case "waiting-for-input":
        return "waiting-for-input";
      case "completed":
        return "status-completed";
      default:
        return "";
    }
  };

  let colorObj = {
    'not-started': {
      label: 'Normal', color: "#E4E7FF", textcolor: '#3545D3'
    },
    'deffered': {
      label: 'Low', color: "#F0EDE9", textcolor: '#78736C'
    },
    'in-progress': {
      label: 'High', color: '#FFE9E9', textcolor: '#FE0606'
    }
  }


  let colorObj1 = {
    'Not Started': {
      label: 'not-started', color: "#C9CACE", textcolor: '#FFF'
    },
    'Deffered': {
      label: 'deffered', color: "#6171FF", textcolor: '#FFF'
    },
    'In Progress': {
      label: 'in-progress', color: '#FAB111 ', textcolor: '#FFF'
    },
    'Completed': {
      label: 'completed', color: '#2BB92B', textcolor: '#FFF'
    },
    'waiting-for-input': {
      label: 'waiting-for-input', color: '#69C2E5', textcolor: '#FFF'
    }
  }
  return (
    <div className="table_maincard">
    <div className="dashtitle" >
        My Open Task This Month
      </div>

      <div className="dashboard_section2_table_mainDiv">

        <div className="LeadRightSectionTable_body table-responsive ">
          <table className="table mytable">

            <thead>
              <tr >
                <th scope="col" style={{ textAlign: "center" , whiteSpace:"nowrap" }}>Contact</th>
                <th scope="col" style={{ textAlign: "center" , whiteSpace:"nowrap" }}>Subject</th>
                <th scope="col" style={{ textAlign: "center" , whiteSpace:"nowrap" }}>Priority</th>
                <th scope="col" style={{ textAlign: "center" , whiteSpace:"nowrap" }}>Due Date</th>
                <th scope="col" style={{ textAlign: "center" , whiteSpace:"nowrap" }}>Status</th>
              </tr>
            </thead>
            {loading ? (
              <Loader2 />
            ) : (

              <tbody>
                {Array.isArray(monthlyTaskData) && monthlyTaskData.length > 0 ? (
                  monthlyTaskData.map((deal, index) => (
                    <tr key={index} style={{ borderstyle: "solid"  , bordercolor: "inherit"}}>
                      <td style={{ textAlign: "center" , whiteSpace:"nowrap"   }}>{deal?.contact}</td>
                      <td style={{ textAlign: "center" , whiteSpace:"nowrap"   }} className="text-capitalize">{deal?.subject}</td>

                      <td style={{ textAlign: "center" , whiteSpace:"nowrap"    }}>
                        <span
                        className="text-capitalize"
                          style={{
                            background: `${colorObj[deal?.priority]?.color}`,
                            color: `${colorObj[deal?.priority]?.textcolor}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "2px 0px",
                            borderRadius: "4px",
                            width: "100px",
                            margin: "auto",
                            textAlign: "center" ,
                          
                            
                          }}
                        >
                          <HiFlag 
                            style={{
                              color: `${colorObj[deal?.priority]?.textcolor}`,
                              fontSize: "20px", 
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center", 
                              
                            }}
                          />

                          {colorObj[deal?.priority]?.label}
                        </span>
                      </td>

                      <td style={{ textAlign: "center" , whiteSpace:"nowrap"   }}>{deal?.dueDate}</td>

                      <td
                        className={getStatusClassName(deal?.status)}
                        style={{ textAlign: "center" , whiteSpace:"nowrap"   }}
                      >
                        <span
                          style={{
                            background: `${colorObj1[deal?.status]?.color}`,
                            color: `${colorObj1[deal?.status]?.textcolor}`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "2px 0px",
                            borderRadius: "4px",
                            width: "109px",
                            margin: "auto",
                            whiteSpace: "nowrap", 
                          // padding:"0px 5px"
                          }}
                        >

                          {deal?.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center" style={{ textAlign: "center", textTransform: "capitalize", border: "1px solid black" }}>
                      No Open Task This Month
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardSection2Table;
