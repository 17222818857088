import React, { useState, useEffect } from "react";
// Import CSS
// import "../../styles/dashboardCss/accountCostumerDetails.css";
// Import api function from controller
import { getSingleAccount } from "../../controller/fetchApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage";

const AccountCostumerDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const accountId = location.state;
  const [getSingleAccountData, setSingleAccountData] = useState([]);
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  
  useEffect(() => {
    getSingleAccount(accountId, tokenId).then((res) => {
      setSingleAccountData(res);
    });
  }, []);
  return (
    <div className="container-fluid account_view_details_main_container" style={{minWidth:"400px"}}>
      <Gobackpage pageName={"Account Details"}/>
      
      {/* Account Information */}
      <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px" ,marginTop:"20px"}}>
        <h5 style={{ marginTop: '8px', marginLeft: '8px', fontWeight: 'bold' }}>Account Information</h5>
        <p style={{ marginLeft: '8px', fontSize: '14px', color: '#555' , fontWeight: "bold"}}>
          Lead Id : LI-{getSingleAccountData?.leadId}
        </p>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Account Owner', value: getSingleAccountData?.accountOwner },
            { label: 'Parent Account', value: getSingleAccountData?.parentAccount },
            { label: 'Pan Card', value: getSingleAccountData?.panCard },
            { label: 'Annual Revenue', value: getSingleAccountData?.annualRevenue },
            { label: 'Account Name', value: getSingleAccountData?.accountName },
            { label: 'Account Number', value: getSingleAccountData?.accountNumber },
            { label: 'Account Type', value: getSingleAccountData?.accountType },
            { label: 'Account Site', value: getSingleAccountData?.accountSite },
            { label: 'Aadhar Card', value: getSingleAccountData?.aadharCard },
            { label: 'Industry', value: getSingleAccountData?.industry }
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word' , marginLeft:"10px" }}>{ getSingleAccountData?.accountOwner ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>


      {/* Billing Information  */}
      <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Billing Address', value: getSingleAccountData?.billingAddress },
            { label: 'Billing City', value: getSingleAccountData?.billingCity },
            { label: 'Billing State', value: getSingleAccountData?.billingState },
            { label: 'Billing Code', value: getSingleAccountData?.billingCode },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word' , marginLeft:"10px" }}>{getSingleAccountData?.billingAddress ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>


      {/* Shipping Information */}
      <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Shipping Street', value: getSingleAccountData?.shippingStreet },
            { label: ' Shipping City', value: getSingleAccountData?.shippingCity },
            { label: 'Shipping State', value: getSingleAccountData?.shippingState },
            { label: ' Shipping Code', value: getSingleAccountData?.shippingCode },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word' ,  marginLeft:"10px" }}>{getSingleAccountData?.shippingStreet ? item.value || 'No data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*  data of issues */}
      
      <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Date of Issue', value: getSingleAccountData?.dateOfIssue },
            { label: 'Date of Billing ', value: getSingleAccountData?.dateOfBilling },
            { label: 'Date Of Shipment', value: getSingleAccountData?.dateOfShipment },
            { label: ' Deal Description', value: getSingleAccountData?.dealDescription },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word',  marginLeft:"10px"}}>{getSingleAccountData?.dateOfIssue ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default AccountCostumerDetails;
