import React, { useCallback, useContext, useEffect, useState } from "react";
// CSS
// import "../../styles/dashboardCss/report.css";
// Components
import ReportLeadGenFirstSection from "../../components/ReportLeadGenFirstSection";
import ReportCallsSecondSection from "../../components/ReportCallsSecondSection";
import ReportDealSection from "../../components/ReportDealSection";
// Controller MEthods
import {
  getGenratedLeads,
  getDealsDone,
  getCallsDone,
} from "../../controller/fetchApi";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate,Link, useLocation } from "react-router-dom";
import Goback from "../../components/goback";
import { PageDetailsContext } from "../../pageDetails";
import Gobackpage from "../gobackpage";


const Reports = () => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath=location.pathname;
  const navigate = useNavigate()
  // Get TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  //set loder
  const [loading,setloading] = useState(true)
  // Get Genrated Leads Data
  const [getGenratedLeadsData, setGenratedLeadsData] = useState();
  const [leadBy, setLeadBy] = useState("month");
  const allGenratedLeads = useCallback(async () => {
    try {
      const res = await getGenratedLeads(tokenId, leadBy);
      setGenratedLeadsData(res);
    } catch (error) {
      console.log(error);
    }finally{
      setloading(false);
    }
  }, [tokenId, leadBy]);

  // Get Deals Done Data
  const [getDealsDoneData, setDealsDoneData] = useState();
  const [dealBy, setDealBy] = useState("month");
  const allDealsDone = useCallback(async () => {
    try {
      const res = await getDealsDone(tokenId, dealBy);
      setDealsDoneData(res);
    } catch (error) {
      console.log(error);
    }
  }, [tokenId, dealBy]);

  // Get Calls Done Data
  const [getCallsDoneData, setCallsDoneData] = useState();
  const [callBy, setCallBy] = useState("month");
  const allCallsDone = useCallback(async () => {
    try {
      const res = await getCallsDone(tokenId, callBy);
      setCallsDoneData(res);
    } catch (error) {
      console.log(error);
    }
  }, [tokenId, callBy]);

  // Use Effect Function
  useEffect(() => {
    allGenratedLeads();
    allDealsDone();
    allCallsDone();
  }, [allGenratedLeads, allDealsDone, allCallsDone]);
  return ( 
    <>{loading?<Loader />:
      <div>
           {/* <Link onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </Link> */}
                <Gobackpage pageName={pagedetails[pagePath].pageName} page="report"/>
               {/* <div>  <Goback /> <span style={{fontSize:"21px",fontWeight:"700"}}>{pagedetails[pagePath].pageName}</span></div> */}
         {/* Section 1  Where Present Genrated Lead Graph */}
      <div className="account_view_details_Row">
        <ReportLeadGenFirstSection
          getGenratedLeadsData={getGenratedLeadsData}
          leadBy={leadBy}
          setLeadBy={setLeadBy}
        />
      </div>
      {/* Section 2 */}
      <div className="account_view_details_Row">
        <div className="row " >
          {/* Left Column Call Chart*/}
          <ReportCallsSecondSection
            getCallsDoneData={getCallsDoneData}
            callBy={callBy}
            setCallBy={setCallBy}
          />
          {/* Right Column Deals Chart */}
          <ReportDealSection
            getDealsDoneData={getDealsDoneData}
            dealBy={dealBy}
            setDealBy={setDealBy}
          />
        </div>
      </div>
      </div>
    }
    </>
  
  );
};

export default Reports;
