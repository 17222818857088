import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { MdOutlineAccountBalance } from "react-icons/md";
// React Icon
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsBuildingsFill } from "react-icons/bs";
import { accountFormSchema } from "../../schema/FormValidation";
import { FaTreeCity } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa6";
import { PiUserListFill } from "react-icons/pi";
import { BsCalendar2Plus } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import { MdDialpad } from "react-icons/md";
import { BsCalendar2 } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";
import { PiMapPinLineDuotone } from "react-icons/pi";
import { FaCity } from "react-icons/fa";
import { PiCityLight } from "react-icons/pi";
import { MdOutlineClosedCaption } from "react-icons/md";
import { TbMapPinCode } from "react-icons/tb";
import { CiShop } from "react-icons/ci";
// Controller Api Methods
import { createAccount, updateSingleAccount } from "../../controller/fetchApi";
// Import Toast
import Toast from "../../components/Toast";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage";

const CreateAccount = () => {
  const navigate = useNavigate();
   const location = useLocation();
    const leadId = location.state?.leadId; 
    const data = location.state?.data;
    const navigatingFrom = location.state?.from;

    console.log('data data',data)
  // Toast
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  useEffect(() => {
     if (showToast.message) {
       const timer = setTimeout(() => {
         setShowToast({ success: false, message: "", optmessage: "" });
       }, 3000);
 
       return () => clearTimeout(timer);
     }
   }, [showToast])

   const accountId = JSON.parse(localStorage.getItem("accountId"));
  // Get TokenId and Uid
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  //disable submit button while submitting form
  const [disable, setdisable] = useState(false);

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      accountOwner: userIdTokenData?.data?.fullName,
      accountName: data?data?.accountName:"",
      accountSite: data?data?.accountSite:"",
      parentAccount: data?data?.parentAccount:"",
      accountNumber: data?data?.accountNumber:"",
      aadharCard: data?data?.aadharCard:"",
      panCard: data?data?.panCard:"",
      accountType: data?data?.accountType:"",
      industry: data?data?.industry:"",
      annualRevenue: data?data?.annualRevenue:"",
      addressInformation: data?data?.addressInformation:"",
      billingAddress: data?data?.billingAddress:"",
      billingCity: data?data?.billingCity:"",
      billingState: data?data?.billingState:"",
      billingCode: data?data?.billingCode:"",
      shippingStreet: data?data?.shippingStreet:"",
      shippingCity: data?data?.shippingCity:"",
      shippingState: data?data?.shippingState:"",
      shippingCode: data?data?.shippingCode:"",
      shippingAddress: data?data?.shippingAddress:"",
      dateOfIssue: data?data?.dateOfIssue:"", // for date
      dateOfBilling: data?data?.dateOfBilling:"", //new Date(Date.now()),
      dateOfShipment: data?data?.dateOfShipment:"",
      dealDescription: data?data?.dealDescription:"",
      leadId:data?.leadId || leadId,  
    },
    validationSchema: accountFormSchema,
    onSubmit: async (values, { resetForm }) => {
      try{
         if(navigatingFrom=="update"){
                  const updates = Object.keys(values).filter(key=>{ 
                    if(Array.isArray(values[key])){ 
                return values[key].length==data[key].length ? values[key].length ? values[key].some(elm=>!data[key].includes(elm))? true:false:false : true;
                    }
                    else{
                      return(values[key]&&values[key]!=data[key]);
                    }
                  });
        
                  let updatedData={}
                  if(updates.length){
                    updates.forEach(key=>updatedData[key]=values[key]);
                    await updateSingleAccount(updatedData, accountId, setShowToast, tokenId);
                    setTimeout(() => {
                      navigate("/accounts");
                    }, 2000);
                  }
                  else{
                    alert("No updated data.")
                  }
                 }else{
                  await createAccount(values, uid, setShowToast, tokenId);
                  if (createAccount) {
                    resetForm();
                    setTimeout(() => {
                      navigate("/accounts");
                    }, 2000);
                    setdisable(!disable);
                  }
                 }

      }
      catch(error){
   console.log('something went wrong')
      }
      finally{

      }
    
     
    },
  });
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <Gobackpage pageName= {navigatingFrom=="update" ? "Update Account" : "Create Account"}/>
      
      <div className="create_lead_form_main_div ">
        <form onSubmit={handleSubmit}>
          {/* User Account Information */}
          <div className="row">

            <div className="col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}} >
              <label htmlFor="accountOwner">
                Account Owner <span className="required_sign">*</span>
              </label>
              <input
              disabled
                type="text"
                id="accountOwner"
                maxLength={50}
                className="form-control create_lead_form_input"
                value={values.accountOwner}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="accountOwner"
                placeholder="Enter Owner Name"
              />
              {touched.accountOwner && errors.accountOwner && (
                <small className="errorMessage">{errors.accountOwner}</small>
              )}
              <FaRegUser className="icon" />
              {/* <MdAdminPanelSettings className="create_lead_input_iconnew" /> */}
            </div>
            <div className="   col-12 col-sm-6 col-md-4" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="accountName">
                Account Name <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                maxLength={50}
                id="accountName"
                className="form-control create_lead_form_input"
                value={values.accountName}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="accountName"
                placeholder="Enter Account Name"
              />
              {touched.accountName && errors.accountName && (
                <small className="errorMessage" style={{marginTop:"-15px"}}>{errors.accountName}</small>
              )}
              <PiUserListFill className="icon" />
              {/* <MdOutlineAccountBalance className="sidebar_navItem_icon" /> */}
              {/* <FaUserTie className="create_lead_input_iconnew" /> */}
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="accountSite">
                Account Site <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="accountSite"
                className="form-control create_lead_form_input"
                value={values.accountSite}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="accountSite"
                placeholder="Enter Account Site"
              />
              {touched.accountSite && errors.accountSite && (
                <small className="errorMessage">{errors.accountSite}</small>
              )}
              <BsCalendar2Plus className="create_lead_input_iconnew" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="parentAccount">
                Parent Account <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="parentAccount"
                className="form-control create_lead_form_input"
                value={values.parentAccount}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="parentAccount"
                placeholder="Enter Parent Account Number"
              />
              {touched.parentAccount && errors.parentAccount && (
                <small className="errorMessage">{errors.parentAccount}</small>
              )}
              <FaUserFriends  className="icon" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="accountNumber">
                Account Number <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="accountNumber"
                className="form-control create_lead_form_input"
                value={values.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                name="accountNumber"
                onFocus={handleFocus}
                placeholder="Enter Account Number"
              />
              {touched.accountNumber && errors.accountNumber && (
                <small className="errorMessage">{errors.accountNumber}</small>
              )}
              <MdDialpad  className="icon" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="aadharNumber">
                Aadhar Card Number <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="aadharNumber"
                maxlength="12"
                minlength="12"
                className="form-control create_lead_form_input"
                value={values.aadharCard}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="aadharCard"
                placeholder="Enter Adhar Number"
              />
              {touched.aadharCard && errors.aadharCard && (
                <small className="errorMessage">{errors.aadharCard}</small>
              )}
              <BsCalendar2  className="create_lead_input_iconnew" />
            </div>
            <div className=" col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="panCardNumber">
                PAN Card Number <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="panCardNumber"
                maxlength="10"
                minlength="10"
                className="form-control create_lead_form_input"
                value={values.panCard}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="panCard"
                placeholder="Enter PAN Card Number"
              />
              {touched.panCard && errors.panCard && (
                <small className="errorMessage">{errors.panCardNupanCardmber}</small>
              )}
              <BsCalendar2 className="create_lead_input_iconnew" />
            </div>
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2"
              style={{ color: "#737475"  , position:"relative" , marginTop:"10px"}}
            >
              <label htmlFor="accountType" >
                Account Type <span className="required_sign">*</span>
              </label>
              <select
                id="accountType"
                className="form-control create_lead_form_input"
                value={values.accountType}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="accountType"
                style={{ color: "#737475" }}
              >
                <option value="" hidden>
                  {/* {touched.accountType && errors.accountType ? (
                    <p className="text-danger">{errors.accountType}</p>
                  ) : (
                    "Select Account Type"
                  )} */}
                  Select Account Type
                </option>
                <option value="web-download">Client</option>
                <option value="web-search">Reseller</option>
                <option value="advertisement">Competitor</option>
                <option value="employee-referral">Analyst</option>
              </select>
              {touched.accountType && errors.accountType && (
                <small className="errorMessage">{errors.accountType}</small>
              )}
              <MdKeyboardArrowDown className="create_lead_input_iconnew" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2"
              style={{ color: "#737475" , position:"relative" ,marginTop:"10px" }}
            >
              <label htmlFor="industry" >
                Industry Type <span className="required_sign">*</span>
              </label>
              <select
                id="industry"
                className="form-control create_lead_form_input"
                value={values.industry}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="industry"
                style={{ color: "#737475" }}
              >
                <option value="" hidden>
                  {/* {touched.industry && errors.industry ? (
                    <p className="text-danger">{errors.industry}</p>
                  ) : (
                    "Select Industry"
                  )} */}
                  Select Industry
                </option>

                {/* <option value="lead">Select Lead Status</option> */}
                <option value="lead">Government</option>
                <option value="contacted">Private Sector</option>
                <option value="deal">ERP(Enterprises)</option>
              </select>
              {touched.industry && errors.industry && (
                <small className="errorMessage">{errors.industry}</small>
              )}
              <MdKeyboardArrowDown className="create_lead_input_iconnew" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="annualRevenue">
                Annual Revenue <span className="required_sign">*</span>
              </label>
              <input
                type="number"
                id="annualRevenue"
                className="form-control create_lead_form_input"
                value={values.annualRevenue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="annualRevenue"
                placeholder="Enter Annual Revenue"
              />
              {touched.annualRevenue && errors.annualRevenue && (
                <small className="errorMessage">{errors.annualRevenue}</small>
              )}
              <MdCurrencyRupee  className="icon" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="address">
                Address <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="address"
                className="form-control create_lead_form_input"
                value={values.addressInformation}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="addressInformation"
                placeholder="Enter Address"
              />
              {touched.addressInformation && errors.addressInformation && (
                <small className="errorMessage">{errors.addressInformation}</small>
              )}

              <MdAdminPanelSettings className="icon" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px" }}>
              <label htmlFor="leadId">
                Lead Id <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="leadId"
                disabled
                className="form-control create_lead_form_input"
                value={values.leadId}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="leadId"
                placeholder="Enter Lead Id"
              />
              {touched.leadId && errors.leadId && (
                <small className="errorMessage">{errors.leadId}</small>
              )}
              <FaTreeCity className="icon" />
            </div>
          </div>
          {/*  billing address */}
          <div className="row">
            <p className="create_lead_section2_company_info">
               
            </p>
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="billingAddress">
                Billing Address <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="billingAddress"
                className="form-control create_lead_form_input"
                value={values.billingAddress}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="billingAddress"
                placeholder="Enter Billing Address"
              />
              {touched.billingAddress && errors.billingAddress && (
                <small className="errorMessage">{errors.billingAddress}</small>
              )}
              <PiMapPinLineDuotone  className="create_lead_input_iconnew" />
            </div>
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="billingCity">
                Billing City <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="billingCity"
                className="form-control create_lead_form_input"
                value={values.billingCity}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="billingCity"
                placeholder="Enter Billing City"
              />
              {touched.billingCity && errors.billingCity && (
                <small className="errorMessage">{errors.billingCity}</small>
              )}
              {/* <FaCity  className="create_lead_input_iconnew" /> */}
            </div> 
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="billingState">
                Billing State <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="billingState"
                className="form-control create_lead_form_input"
                value={values.billingState}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="billingState"
                placeholder="Enter Billing State"
              />
              {touched.billingState && errors.billingState && (
                <small className="errorMessage">{errors.billingState}</small>
              )}
              <PiCityLight  className="icon" />
            </div>
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="billingCode">
                Billing Code <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="billingCode"
                maxlength="10"
                minlength="10"
                className="form-control create_lead_form_input"
                value={values.billingCode}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="billingCode"
                placeholder="Enter Billing Code"
              />
              {touched.billingCode && errors.billingCode && (
                <small className="errorMessage">{errors.billingCode}</small>
              )}
              <MdOutlineClosedCaption  className="icon" />
            </div>
          </div>
          {/* Shipping Information */}
          <div className="row">
            <p className="create_lead_section2_company_info" style={{marginTop:"10px"}}>
              Shipping Information
            </p>
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="shippingStreet">
                Shipping Street <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="shippingStreet"
                className="form-control create_lead_form_input"
                value={values.shippingStreet}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="shippingStreet"
                placeholder="Enter Shipping Street"
              />
              {touched.shippingStreet && errors.shippingStreet && (
                <small className="errorMessage">{errors.shippingStreet}</small>
              )}
              <CiShop  className="create_lead_input_iconnew" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="shippingCity">
                Shipping City <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="shippingCity"
                className="form-control create_lead_form_input"
                value={values.shippingCity}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="shippingCity"
                placeholder="Enter Shipping City"
              />
              {touched.shippingCity && errors.shippingCity && (
                <small className="errorMessage">{errors.shippingCity}</small>
              )}
               <FaCity  className=" icon" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="shippingState">
                Shipping State <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="shippingState"
                className="form-control create_lead_form_input"
                value={values.shippingState}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="shippingState"
                placeholder="Enter Shipping State"
              />
              {touched.shippingState && errors.shippingState && (
                <small className="errorMessage">{errors.shippingState}</small>
              )}
             <PiCityLight  className="create_lead_input_iconnew" />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="shippingCode">
                PIN Code <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="shippingCode"
                maxlength="6"
                minlength="6"
                className="form-control create_lead_form_input"
                value={values.shippingCode}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="shippingCode"
                placeholder="Enter Pin Code"
              />
              {touched.shippingCode && errors.shippingCode && (
                <small className="errorMessage">{errors.shippingCode}</small>
              )}
              <TbMapPinCode  className="icon  " />
            </div>
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" , marginTop:"10px"}}>
              <label htmlFor="shippingAddress">
                Shipping Address <span className="required_sign">*</span>
              </label>
              <input
                type="text"
                id="shippingAddress"
                className="form-control create_lead_form_input"
                value={values.shippingAddress}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="shippingAddress"
                placeholder="Enter Shipping Address"
              />
              {touched.shippingAddress && errors.shippingAddress && (
                <small className="errorMessage">{errors.shippingAddress}</small>
              )}
               <PiMapPinLineDuotone  className="create_lead_input_iconnew" />
            </div>

            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="dateOfIssue">
                Date of Issue <span className="required_sign">*</span>
              </label>
              <input
                type="date"
                id="dateOfIssue"
                style={{paddingRight:"10px"}}
                className="form-control create_lead_form_input pr-0"
                value={values.dateOfIssue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="dateOfIssue"
              />
              {touched.dateOfIssue && errors.dateOfIssue && (
                <small className="errorMessage">{errors.dateOfIssue}</small>
              )}
              {/* <BsCurrencyRupee className="create_lead_input_iconnew" /> */}
            </div>
          </div>
          {/*  date of billing */}
          <div className="row">
         
            
            <div className="   col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="dateOfBilling">
                Date of Billing <span className="required_sign">*</span>
              </label>
              <input
                type="date"
                id="dateOfBilling"
                style={{paddingRight:"10px"}}
                className="form-control create_lead_form_input pr-0"
                value={values.dateOfBilling}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="dateOfBilling"
                placeholder="Enter Date Of Billing"
              />
              {touched.dateOfBilling && errors.dateOfBilling && (
                <small className="errorMessage">{errors.dateOfBilling}</small>
              )}
              {/* <BsCurrencyRupee className="create_lead_input_iconnew" /> */}
            </div>
            <div className="  col-12 col-sm-6 col-md-4 mb-3 px-2" style={{position:"relative" ,marginTop:"10px"}}>
              <label htmlFor="dateOfShipment">
                Date of Shipment <span className="required_sign">*</span>
              </label>
              <input
                type="date"
                id="dateOfShipment"
                style={{paddingRight:"10px"}}
                className="form-control create_lead_form_input"
                value={values.dateOfShipment}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="dateOfShipment"
                placeholder={
                  touched.dateOfShipment && errors.dateOfShipment
                    ? errors.dateOfShipment
                    : null
                }
              />
              {touched.dateOfShipment && errors.dateOfShipment && (
                <small className="errorMessage">{errors.dateOfShipment}</small>
              )}
              {/* <BsCurrencyRupee className="create_lead_input_iconnew" /> */}
            </div>
          </div>
          {/* Description */}
          <div className="row">
         
            <div className="form-group " style={{position:"relative", marginTop:"10px"}}>
              <label
                htmlFor="description"
                className="create_lead_section2_description_label"
              >
                Description
              </label>
              <textarea
                id="description"
                className="form-control create_lead_form_input"
                value={values.dealDescription}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name="dealDescription"
                rows="3"
                placeholder={
                  touched.dealDescription && errors.dealDescription
                    ? errors.dealDescription
                    : null
                }
              ></textarea>
              {touched.dealDescription && errors.dealDescription && (
                <small className="errorMessage">{errors.dealDescription}</small>
              )}
            </div>
          </div>
          {/* Submit Button */}
          <div className="text-center mb-2">
            <button
              disabled={isSubmitting}
              className="create_lead_form_submitBtn"
              type="submit"
            >
              {navigatingFrom=="update" ? "Update" : "submit"}
            </button>
          </div>
        </form>
        <Toast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </div>
  );
};

export default CreateAccount;
