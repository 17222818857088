import React, { useEffect, useState } from "react";

// React Icons
import { SiGoogleadmob } from "react-icons/si";
import { MdTask } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaHandshakeSimple } from "react-icons/fa6";
import dasboard_Deals from "../images/DashboardMyOpenDeals.png"
import dasboard_Leads from "../images/DashboardVerifiedLeads.png"
import dasboard_Task from "../images/DashboardMyTask.png"
// import dasboard_Call from "../images/.png"
// Import Shared Component
import DashboardSection1Table from "./shared/DashboardSection1Table";
import { Link } from "react-router-dom";

// import DashboardSection2Table from "./shared/DashboardSection2Table";
const DashboardSection1 = ({ getCurrentUserData }) => {
  const [elmwid, setelmwid] = useState(document.getElementById('super_admin_section_first_card2')?.offsetWidth)

  useEffect(() => {
    // const element = document.getElementById('super_admin_section_first_card1')
    // setelmwid(element.offsetWidth)
    const elementWidth = () => {
      const element = document.getElementById('super_admin_section_first_card1')
      setelmwid(element.offsetWidth)
    }
    window.addEventListener("resize", elementWidth)


    return () => window.removeEventListener("resize", elementWidth)
  }, [])

  return (

    <div className="super_admin_first_section_cards_div ">
      {/* first Card */}

      {/* <div className="super_admin_first_section_single_card  super_admin_first_section_first_card">
      <div className="super_admin_first_section_card_icon_div">
        {
         <FaHandshakeSimple className="dashboard_card_icon dashboard_firstCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link to='/deals' className="super_admin_first_section_card_link">
          My Open Deals
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalDeal}
        </p>
      </div>
    </div> */}

      <div className="super_admin_first_section_single_card col  super_admin_first_section_first_card" id="super_admin_section_first_card1" 
      style={{
        background:"#FFF8DF"
      }}>
        <div className="super_admin_first_section_card_icon_div">
          {
            //  <FaHandshakeSimple className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            <img src={dasboard_Deals} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
          }
             
        </div>

        <div className="super_admin_first_section_text_div">
          <p className="super_admin_first_section_card_text">
            <Link to='/deals' className="super_admin_first_section_card_link">
              My Open Deals
            </Link>
          </p>
          <p className="super_admin_first_section_card_number">
            {getCurrentUserData?.totalDeal || 0}
          </p>
        </div>
      </div>
      {/* Second Card */}

      {/* <div className="super_admin_first_section_single_card  super_admin_first_section_second_card">
      <div className="super_admin_first_section_card_icon_div">
        {
        <SiGoogleadmob className="dashboard_card_icon dashboard_secondCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link className="super_admin_first_section_card_link" to="/leads" state={{lead:"verified"}}>
          Varified Leads
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalLead}
        </p>
      </div>
    </div> */}

      <div className="super_admin_first_section_single_card col  super_admin_first_section_second_card" id="super_admin_section_first_card2"
      style={{
        background:"#F2FFE2"
      }}>
        <div className="super_admin_first_section_card_icon_div">
          {
            // <SiGoogleadmob className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            <img src={dasboard_Leads} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
          }
        </div>

        <div className="super_admin_first_section_text_div">
          <p className="super_admin_first_section_card_text">
            <Link className="super_admin_first_section_card_link" to="/leads" state={{ lead: "verified" }}>
              Verified Leads
            </Link>
          </p>
          <p className="super_admin_first_section_card_number">
            {getCurrentUserData?.totalLead || 0}
          </p>
        </div>
      </div>

      {/* Third Card */}

      {/* <div className="super_admin_first_section_single_card super_admin_first_section_third_card">
      <div className="super_admin_first_section_card_icon_div">
        {
          <MdTask className="dashboard_card_icon dashboard_thirdCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link className="super_admin_first_section_card_link" to="/tasks">
          My Task
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalTask} 
        </p>
      </div>
    </div> */}

      <div className="super_admin_first_section_single_card col  super_admin_first_section_third_card" id="super_admin_section_first_card3"
      style={{
        background:"#E1FDFA"
      }}>
        <div className="super_admin_first_section_card_icon_div">
          {
            // <MdTask className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            <img src={dasboard_Task} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
          }
        </div>

        <div className="super_admin_first_section_text_div">
          <p className="super_admin_first_section_card_text">
            <Link className="super_admin_first_section_card_link" to="/tasks">
              My Task
            </Link>
          </p>
          <p className="super_admin_first_section_card_number">
            {getCurrentUserData?.totalTask || 0}
          </p>
        </div>
      </div>
      {/* Fourth Card */}

      {/* <div className="super_admin_first_section_single_card super_admin_first_section_fourth_card">
      <div className="super_admin_first_section_card_icon_div">
        {
           <BiSolidPhoneCall className="dashboard_card_icon dashboard_fourthCard_icon" />
        }
      </div>
      <div className="super_admin_first_section_text_div">
        <p className="super_admin_first_section_card_text">
          <Link className="super_admin_first_section_card_link" to="/call-schedule">
          My Calls Today
          </Link>
        </p>
        <p className="super_admin_first_section_card_number">
        {getCurrentUserData?.totalCall} 
       
        </p>
      </div>
    </div> */}

      <div className="super_admin_first_section_single_card col  super_admin_first_section_fourth_card" id="super_admin_section_first_card4" style={{ maxWidth: `${elmwid}px` , background:"#E6F5FD"}}>
        <div className="super_admin_first_section_card_icon_div">
          {
            <BiSolidPhoneCall className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            // <img src={dasboard_Task} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
          }
        </div>

        <div className="super_admin_first_section_text_div">
          <p className="super_admin_first_section_card_text">
            <Link className="super_admin_first_section_card_link" to="/call-schedule">
              My Calls Today
            </Link>
          </p>
          <p className="super_admin_first_section_card_number">
            {getCurrentUserData?.totalCall || 0}
          </p>
        </div>
      </div>
    </div>



  );
};

export default DashboardSection1;
