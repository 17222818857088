import React, { useState, useEffect, useContext } from "react";
import leadIdContext from "../../pages/LeadIdContext";
import { assignLeads } from "../../controller/fetchApi";
import { CiSearch } from "react-icons/ci";
import { BsSearch } from "react-icons/bs";
const AssignLeadTableAdmin = ({
  tblHead,
  getAllAdminsData,
  adminId,
  setAdminId,
  getLeadsData,
  setAssignLead,
  setLeadCostumerId
}) => {

  const [alluserdata, setalluserdata] = useState(null)
  useEffect(() => {
    setalluserdata([...getAllAdminsData])
  }, [getAllAdminsData])

  // Start Toast Code-------
  const [showToast, setShowToast] = useState({ success: false, message: "", optmessage: "" });
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" });
    }, 3000);
  };

  if (showToast.message) {
    hideToast();
  }
  const { leadCostumerId } = useContext(leadIdContext) || [];
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  // Handle Single Check Box For Single Updateion And Id get and send Start ------
  const handleCheckboxChange = (assignUserId) => {
    setAdminId([assignUserId])
    // const isSelected = adminId.includes(assignUserId);

    // if (isSelected) {
    //   setAdminId(adminId.filter((id) => id !== assignUserId));
    // } else {
    //   setAdminId([...adminId, assignUserId]);
    // }
  };

  const handleMasterCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    console.log("master checkbox changed", isChecked);
    setIsMasterChecked(isChecked);
    const allAdminIds = alluserdata?.map((data) => data.id) || [];
    if (isChecked) {
      setAdminId(allAdminIds);
    } else {
      setAdminId([]);
    }
  };

  useEffect(() => {
    // const allAdminIds = getAllAdminsData?.map((data) => data.id) || [];
    const allAdminIds = alluserdata?.map((data) => data.id) || [];
    if (allAdminIds.length === 0) return;
    if (adminId.length === allAdminIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [adminId, alluserdata]);
  // TokenId
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  // Send Assign Leads data
  const handleAssignLeads = async () => {
    const result = await assignLeads(
      tokenId,
      adminId,
      leadCostumerId,
      setShowToast,
      setAssignLead
    );
    if (result) {
      getLeadsData();
      setLeadCostumerId([])
    }

  };

  const FindUser = ({ target: { value } }) => {
    const data = getAllAdminsData.filter(userdetail => userdetail?.firstName.toLowerCase().includes(value.toLowerCase()))
    setalluserdata([...data])
  }
  return (
    <div className="container-fluid ">
      {/* <div><input type="search" onInput={FindUser}/><span>Assign user</span></div> */}
     
<div style={{display:"flex" , justifyContent:"flex-end"}}>
   <div style={{ position: "relative", display: 'inline-flex', alignItems:"center"}}>
        <input
          type="search"
          onInput={FindUser}
          placeholder="Search user..."
          style={{
   
            paddingRight: "35px",  // Space for icon inside input
            width: "200px",
            height: "35px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            outline:"none"
          }}
        />
        <CiSearch 
          style={{
            position: "absolute",
            right: "10px",
            // top: "50%",
            // transform: "translateY(-50%)",
            fontSize: "20px",
            color: "#888",
            cursor: "pointer"
          }}
          onClick={FindUser} 
        />
      </div>
</div>
     

      <div className="LeadRightSectionTable_body table-responsive" style={{marginTop:"15px"}}>
        <div style={{maxHeight:"600px" , overflow:"hidden"}}>
          <div style={{maxHeight:"600px", overflow:"auto"}}> 
           <table className="table assign_leads_table table-hover">
          <thead>
            <tr className="table-secondary dashboard_section1_tableHead_tr">
              {/* Checkbox Column */}
              <th scope="col">
                <div className="wrap-check-29">

                </div>
              </th>
              <th scope="col" className="text-center">
                {tblHead.firstHead}
              </th>
              <th scope="col" className="text-center">
                {tblHead.secondHead}
              </th>

              <th scope="col">{tblHead.fifthHead}</th>
              <th scope="col" className="text-center">
                {tblHead.sixthHead}
              </th>
            </tr>
          </thead>
          <tbody className="dashboard_section1_tableBody ">
            {alluserdata && alluserdata?.length > 0 ? (
              alluserdata?.map((data) => (
                <tr className="asignlead_border"
                  key={data.id}
                  onClick={() => localStorage.setItem("assignUserId", data?.id)}
                >
                  <td scope="col" >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="assign"
                      defaultValue=""
                      id="flexCheckIndeterminate"
                      onChange={() => handleCheckboxChange(data?.id)}
                      checked={adminId?.includes(data?.id)}
                    />
                  </td>
                  <td scope="col" className="text-center">{`${data?.id}`}</td>
                  <td scope="col" className="text-center">{`${data?.firstName} ${data?.lastName}`}</td>
                  <td scope="col" className="text-center">{data?.totalLeads}</td>
                  <td scope="col" className="text-center">{data?.role}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No Admins</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        </div>
        
       
        {/* Assign */}
        <div className="text-center">
          <button
            className="assign_lead_table1_btn"
            onClick={handleAssignLeads}
          >
            Assign
          </button>
        </div>
      </div>
      {/* Toast */}
      {showToast.message && (
        <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
          <div
            className="toast show create_lead_toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header create_lead_toast_header">
              <strong className="me-auto">{showToast?.optmessage ? showToast?.optmessage : "Form Submitted Successfully"}</strong>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast({ success: false, message: "" })}
              />
            </div>
            <div className="toast-body">{showToast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignLeadTableAdmin;
