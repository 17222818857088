// import React, { useContext } from "react";
import React, { useEffect, useState } from "react";
// React Router Dom
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import Loader from "../pages/Loader";
const  ContactRightSectionTable = ({
  pageSize,
  pageNo,
  tblHead,
  redirectLink,
  getAllContactData,
  contactCostumerId,
  setContactCostumerId,
  loading
}) => { 
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  // Handle Single Check Box For Single Updation And Id get and send Start ------
  const handleCheckboxChange = (contactId) => {
    const isSelected = contactCostumerId.includes(contactId);

    if (isSelected) {
      setContactCostumerId(contactCostumerId.filter((id) => id !== contactId));
    } else {
      setContactCostumerId([...contactCostumerId, contactId]);
    }
  };
 
  const handleMasterCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsMasterChecked(isChecked);
    const allContactIds =
      getAllContactData?.content?.map((data) => data.id) || [];
    if (isChecked) {
      setContactCostumerId(allContactIds);
    } else {
      setContactCostumerId([]);
    } 
  };
  useEffect(() => {
    const allLeadIds = getAllContactData?.content?.map((data) => data.id) || [];
    if (allLeadIds.length === 0) return;
    if (contactCostumerId.length === allLeadIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [contactCostumerId, getAllContactData]);
  return (
    <div className="container-fluid p-0">
  
      <div className="LeadRightSectionTable_body table-responsive">
        <table className="table mytable">
          <thead>
            <tr>
            <th scope="col" className=" text_center text-left">
                <div className="wrap-check-29">
                  <div className="cbx">
                    <input
                      id="cbx-29"
                      type="checkbox"
                      // onClick={handleMasterCheckboxChange}
                      checked={isMasterChecked}
                      onChange={handleMasterCheckboxChange}
                    />
                    <label htmlFor="cbx-29" />
                    <svg width={15} height={14} viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2" />
                    </svg>
                  </div>
                  {/* Gooey*/}
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo-12">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation={4}
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                          result="goo-12"
                        />
                        <feBlend in="SourceGraphic" in2="goo-12" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </th>
            <th
              scope="col"
              className="text-left text_center"
              style={{ cursor: "context-menu" }}
            >
              Sr. No
            </th>
             
              <th scope="col" className=" text-left  text_center">
                {tblHead.seventhHead}
              </th>
              <th scope="col" className=" text_center text-left">{tblHead.firstHead}</th>
              <th scope="col" className=" text_center text-left">{tblHead.secondHead}</th>
              <th scope="col" className=" text_center text-left">{tblHead.thirdHead}</th>
              <th scope="col" className=" text_center text-left">{tblHead.fourthHead}</th>
              <th scope="col" className=" text_center text-center">{tblHead.fifthHead}</th>
              <th scope="col" className="text-center  text_center">
                {tblHead.sixthHead}
              </th>
              {/* <th scope="col" className="text-center">
                {tblHead.seventhHead}
              </th> */}
              {/* <th scope="col">{tblHead.fifthHead}</th> */}
            </tr>
          </thead>
          {loading ? (
        <Loader />
      ) : (
          <tbody className="dashboard_section1_tableBody ">
            {getAllContactData && getAllContactData?.content?.length > 0 ? (
              getAllContactData?.content?.map((data,index) => (
                <tr
                  key={data.id}
                  onClick={() => localStorage.setItem("contactId", data.id)}
                >
                   <td className=" text_center text-left text-capitalize">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckIndeterminate"
                      onChange={() => handleCheckboxChange(data.id)}
                      checked={contactCostumerId?.includes(data.id)}
                    />
                  </td>
                   <td className="text-left text_center text-capitalize">
                    <span className="Link-button-leads">
                      <span
                        style={{
                          cursor: "context-menu",
                          border: "1px solid #9c9c9c",
                          padding: "2px 4px",
                          borderRadius: "3px",
                        }}
                      >
                          { `${pageNo * pageSize + index<9?`0${pageNo * pageSize + index + 1}`:pageNo * pageSize + index + 1}`}
                        {/* {pageNo * pageSize + index + 1} */}
                      </span>
                    </span>
                  </td>
                  {/* <td>{index+1}</td> */}
                 
                  <td className=" text-left text_center text-capitalize">
                    {data?.leadId && (
                      <Link to={redirectLink} className="Link-button-leads">
                        {/* <div className="leads_table_id_col rounded"> */}
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                        {/* </div> */}
                      </Link>
                    )}
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.companyName}
                    </Link>
                  </td> */} 
                  <td className=" text-left text-capitalize" style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.companyName || 'No Data'}
                      <span className="tool_tip_box">  {data.companyName || 'No Data'}</span>
                    </div>
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.companyEmail}
                    </Link>
                  </td> */}
                  <td className=" text-left " style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.companyEmail || 'No Data'}
                      <span className="tool_tip_box">  {data.companyEmail || 'No Data'}</span>
                    </div>
                  </td>
                  <td className=" text_center  text-left text-capitalize">
                    <span className="Link-button-leads">
                      {data.companyContact || 'No Data'}
                    </span>
                  </td>
                  {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.companyAddress}
                    </Link>
                  </td> */}
                   <td className=" text-left text-capitalize" style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {data.companyAddress || 'No Data'}
                      <span className="tool_tip_box"> {data.companyAddress || 'No Data'}</span>
                    </div>
                  </td>
                  <td className=" text_center text-center text-capitalize">
                    <Link to={redirectLink} className="Link-button-leads">
                      <FaRegEye className="showDetailEye fs-4" />
                    </Link>
                  </td>
                  <td className="text-center text_center text-capitalize">
                    <Link to="/create-account" state={{ leadId: data.leadId }}>
                      <HiPencilSquare className="lead-table-contact-action-icon fs-4" />
                    </Link>
                    {/* &nbsp;&nbsp;
                    <Link to="/account-details">
                      <FaRegEye className="fs-5" />
                    </Link> */}
                  </td>
                  {/* <td className="text-center">
                    {data?.leadId && (
                      <Link to={redirectLink} className="Link-button-leads">
                        
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                     
                      </Link>
                    )}
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className=" text-capitalize text-center">No Contact Data At this Time</td>
              </tr>
            )}
          </tbody>
            )}
        </table>
      </div>
    </div>
  );
};

export default ContactRightSectionTable;
