import React, { useEffect, useState } from "react";
import Signup from "../pages/Signup";
// import SignupForm from "../pages/SignupForm";
// React Router
import { Link } from "react-router-dom";
// React Icons
import { MdAdd } from "react-icons/md";
import { SiGoogleads } from "react-icons/si";
import { MdAdminPanelSettings } from "react-icons/md";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { MdAssignmentAdd } from "react-icons/md";
import Lead_Image from "../images/DashboardToatalLeads.png"
import Total_Sub_domain from "../images/DashboardToatalSubDomain.png"
import Total_Project_Manger from "../images/DashboardToatalProjectManger.png"
import Total_sales_Executives from "../images/DashboardTotalSalesExecutive.png"

const SuperAdminSection1 = ({
  totalLeads,
  allRoles,
  userrole,
  getCurrentUserData,
}) => {
  const [createUser, setcreateUser] = useState(false);

  const [elmwid2, setelmwid] = useState(
    document.getElementById("super_admin_section_first_card2")?.offsetWidth
  );

  useEffect(() => {
    // const element = document.getElementById('super_admin_section_first_card2')
    // setelmwid(element.offsetWidth)
    const element2Width = () => {
      const element = document.getElementById(
        "super_admin_section_first_card2"
      );
      setelmwid(element.offsetWidth);
    };
    window.addEventListener("resize", element2Width);

    return () => window.removeEventListener("resize", element2Width);
  }, []);

  return (
    <div className="super_admin_first_div ">
      <div className="dashboard_username_div ">
        {/* Left Div */}
        <div className="super_admin_first_section_left_div">
          <p className="super_admin_first_section_user_name">
            {!getCurrentUserData?.fullName ? (
              <div
                className="skeleton-loader"
                style={{ width: "100%", height: "20px", background: "#000000", borderRadius: "5px" }}
              ></div>
            ) : (
              `Welcome ${getCurrentUserData.fullName}`
            )}
          </p>
        </div>


      </div>
      {/* Cards Div */}
      <div className="super_admin_first_section_cards_div  mb-4 mt-4">
        {/* first Card */}
        <div
          className="super_admin_first_section_single_card col  super_admin_first_section_first_card"
          style={{
            background: "#F6F1FF"
          }}
          id="super_admin_section_first_card2"
        >
          <div className="super_admin_first_section_card_icon_div">
            {
              // <SiGoogleads className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
              <img src={Lead_Image} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            }
          </div>

          <div className="super_admin_first_section_text_div">
            <p className="super_admin_first_section_card_text">
              <Link
                to="/leads"
                className="super_admin_first_section_card_link"
                style={{ whiteSpace: "nowrap" }}
              >
                Total Leads
              </Link>
            </p>
            <p className="super_admin_first_section_card_number">
              {totalLeads != null || undefined ? totalLeads : "0"}
            </p>
          </div>
        </div>

        {/* second Card */}
        {userrole === "ADMIN" && (
          <div className="super_admin_first_section_single_card col  super_admin_first_section_fourth_card"
            style={{
              background: "#FEF4EC"
            }}>
            <div className="super_admin_first_section_card_icon_div">
              {
                // <MdAssignmentAdd className="dashboard_card_icon super_admin_first_section_fourthCard_icon" />
                <img src={Total_Sub_domain} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
              }
            </div>
            <div className="super_admin_first_section_text_div">
              <p className="super_admin_first_section_card_text">
                <span
                  className="super_admin_first_section_card_link"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Total Sub-Admin
                </span>
              </p>
              <p className="super_admin_first_section_card_number">
                {allRoles?.SUBADMIN || "0"}
              </p>
            </div>
          </div>
        )}
        {/* Third Card */}

        <div className="super_admin_first_section_single_card col  super_admin_first_section_third_card"
          style={{
            background: "#EBF0FE"
          }}>
          <div className="super_admin_first_section_card_icon_div">
            {
              // <MdAssignmentTurnedIn className="dashboard_card_icon super_admin_first_section_thirdCard_icon" />
              <img src={Total_Project_Manger} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            }
          </div>
          <div className="super_admin_first_section_text_div">
            <p className="super_admin_first_section_card_text">
              <span
                className="super_admin_first_section_card_link"
                style={{ whiteSpace: "nowrap" }}
              >
                Total Project Manager
              </span>
            </p>
            <p className="super_admin_first_section_card_number">
              {allRoles?.PROJECTMANAGER || "0"}
            </p>
          </div>
        </div>

        {/* Fourth Card */}

        <div
          className="super_admin_first_section_single_card col  super_admin_first_section_fourth_card"
          style={{ maxWidth: `${elmwid2}px`, background: "#FFEEEC" }}
        >
          <div className="super_admin_first_section_card_icon_div">
            {
              // <MdAssignmentAdd className="dashboard_card_icon super_admin_first_section_fourthCard_icon" />
              <img src={Total_sales_Executives} alt="Design Icon" className="dashboard_card_icon super_admin_first_section_firstCard_icon" />
            }
          </div>
          <div className="super_admin_first_section_text_div">
            <p className="super_admin_first_section_card_text">
              <span
                className="super_admin_first_section_card_link"
                style={{ whiteSpace: "nowrap" }}
              >
                Total Sales Executives
              </span>
            </p>
            <p className="super_admin_first_section_card_number">
              {allRoles?.SALESEXECUTIVE || "0"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminSection1;
