import React, { useState, useEffect, useCallback, useContext } from "react";
// React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsTrash } from "react-icons/bs";
import { TbFileDownload } from "react-icons/tb";
import ContactRightSectionTable from "../../components/ContactRightSectionTable";
// Import Toast
import Toast from "../../components/Toast";
// Context
import permissionContext from "../PermissionsContext";
// Controller
import {
  getAllContact,
  deleteContact,
  downloadContacts,
  downloadSelectedContacts,
} from "../../controller/fetchApi";
// React Router Dom
import { Link, useLocation, useNavigate } from "react-router-dom";

import Deleteuserconfirmation from "../../components/deleteuserconfirmation";
import Loader from "../Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Goback from "../../components/goback";
import Pagination from "../../components/pagination";
import { PageDetailsContext } from "../../pageDetails";
import Gobackpage from "../gobackpage";

const Contact = () => {
  const pagedetails = useContext(PageDetailsContext);
  const location = useLocation();
  const pagePath = location.pathname;
  const navigate = useNavigate();
  //  Get Permission from app.js
  const { contactsPermission } = useContext(permissionContext);
  // Start Toast -------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  useEffect(() => {
     if (showToast.message) {
       const timer = setTimeout(() => {
         setShowToast({ success: false, message: "", optmessage: "" });
       }, 3000);
 
       return () => clearTimeout(timer);
     }
   }, [showToast])
  // Set Contact Costumer Id in main Conntact.jsx
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [contactCostumerId, setContactCostumerId] = useState([]);
  const [getAllContactData, setAllContactData] = useState([]);

  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  //set loader
  const [loading, setloading] = useState(true);

  //  Get All Contact Data
  const getContactsData = useCallback(async () => {
    try {
      await getAllContact(pageNo, tokenId, pageSize).then((res) => {
        setAllContactData(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [pageNo, tokenId, pageSize]);
  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null,
    setLeadId: null,
  });
  const [show, setshow] = useState(false);

  // Delete Contact Api Start---------------
  const handleDeleteContact = async () => {
    if (contactCostumerId.length) {
      try {
        await deleteContact(contactCostumerId, setShowToast, tokenId);
        if (deleteContact) {
          getContactsData();
        }
      } catch (error) {
        console.log("Did Not Delete Found Error", error);
      } finally {
        setContactCostumerId([]);
        setshow(false);
      }
    } else {
      setShowToast({
        success: true,
        message: "Select contact to delete.",
        optmessage: "Deleting contact.",
      });
    }
  };

  // Handle Download Contact
  const handleDowloadContacts = async () => {
    try {
      if(contactCostumerId?.length){
        await downloadSelectedContacts(uid, setShowToast, tokenId,contactCostumerId);
      }
      else{
        await downloadContacts(uid, setShowToast, tokenId);
      } 
    } catch (error) {
      console.log("Contact Downloaded:", error);
    }
  };

  const handleSelectedDowloadContacts = async () => {
    try {
      await downloadSelectedContacts(uid, setShowToast, tokenId,contactCostumerId);
    } catch (error) {
      console.log("Contact Downloaded:", error);
    }
  };
  // Pagination Function Code------
  // const [pageRangeStart, setPageRangeStart] = useState(0);
  const totalPages = getAllContactData?.totalPages || 1;
  const totalElements = getAllContactData?.totalElements || 0;
  const showingElements = getAllContactData?.numberOfElements || 0;
  


  useEffect(() => {
    getContactsData();
  }, [getContactsData]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_container">
      <div className="dashboard_content_wrapper">
        <div className="dashboard_leads_btn_mainDiv">
                <Gobackpage pageName={pagedetails[pagePath].pageName} page="contact"/>
          <div className="dashboard_leads_btns_div">
              {(contactsPermission?.includes("Delete") ||
                contactsPermission?.includes("Download")) &&
                getAllContactData?.content?.length > 0 ? (

                    <div className="leads_table_id_col">
                      <div className="contactcreatebtn">
                      <button class="default-btn dropdown-toggle"
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                        data-target="#action_menu"
                        aria-controls="#action_menu" >
                        Actions


                        <ul
                        className="dropdown-menu"
                        id="action_menu"
                        aria-labelledby="editDeleteDropdown"
                      >
                        {/* Delete Btn */}
                        {contactsPermission?.includes("Delete") ? (
                          <li style={{ cursor: "pointer" }}>
                            <span
                              className="dropdown-item"
                              onClick={() => {
                                console.log(contactCostumerId?.length);
                                // handleDeleteContact(contactCostumerId,setContactCostumerId)

                                if (contactCostumerId?.length) {
                                  setshow(true);
                                  setdellead({
                                    leadId: contactCostumerId,
                                    setLeadId: setContactCostumerId,
                                  });
                                } else {
                                  setShowToast({
                                    success: true,
                                    message: "Select Contact to delete.",
                                    optmessage: "Deleting contact.",
                                  });
                                }
                              }}
                            >
                              <BsTrash className="dashboard_section1_table_deleteBtn" />
                              Delete
                            </span>
                          </li>
                        ) : (
                          ""
                        )}

                        {/* Downloads Btn */}
                        {contactsPermission?.includes("Download") ? (<>
                          <li style={{ cursor: "pointer" }}>
                            <span
                              className="dropdown-item"
                              onClick={() => handleDowloadContacts()}
                            >
                              <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                              Download Contacts
                            </span>
                          </li>
                          {/* <li style={{ cursor: "pointer" }}>
                            <span
                              className="dropdown-item"
                              onClick={() => handleSelectedDowloadContacts()}
                            >
                              <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                              Download selected Contacts
                            </span>
                          </li> */}
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                      </button>
                    
                      </div>
                
                    </div>
              
              ) : (
                ""
              )}

              {/* Create Contact Btn */}
              {contactsPermission?.includes("Create") ? (
                <div
                  // style={{ display: "flex", alignItems: "center" }}
                  className="createbtn"
                  onClick={(event)=>{event.stopPropagation();navigate("/create-contact")}}
                >

                  <button className="default-btn" style={{ width:"85px" }}>
                    <span
                      className="dashboard_leads_create_link"
                    >
                      <span>
                      <MdAdd  style={{textAlign:"center"}}/>
                    </span>
                     <div >
                    Create
                    </div> 
                    </span>
                  </button>
                </div>
              ) : (
                ""
              )}
           
          </div>
        </div>

        {/* Table Div */}
       

          <div class="LeadRightSectionTable_body table-responsive">
            <table class="table mytable">
 
              <ContactRightSectionTable
              pageSize={pageSize}
                pageNo={pageNo}
                loading={loading}
                tblHead={{
                  firstHead: "Company Name",
                  secondHead: "Email",
                  thirdHead: "Contact",
                  fourthHead: "Address",
                  fifthHead: "View",
                  sixthHead: "Account Action",
                  seventhHead: "Lead Id",
                }}
                redirectLink="/contact-details"
                getAllContactData={getAllContactData}
                tableName="contactTable"
                contactCostumerId={contactCostumerId}
                setContactCostumerId={setContactCostumerId}
              />
            </table>
          
        </div>

        <Pagination
          totalPages={totalPages}
          totalElements={totalElements}
          showingElements={showingElements}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPagesize={setPagesize}
        />
        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          setShowToast={setShowToast}
          handleDelete={handleDeleteContact}
          sourceName="Contact"
        />
        <Toast showToast={showToast} setShowToast={setShowToast} />
      </div>
    </div>
  );
};

export default Contact;
