import React, { useState, useEffect } from "react";
// Import api function from controller
import { getSingleScheduleCall } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate,Link, useLocation } from "react-router-dom";
import Gobackpage from "../gobackpage";


const CallViewDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const data = location.state;
  const callId = location.state;
  console.log('callId',callId)
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
 const [callDetail,setCallDetail] = useState({});
  
 useEffect(()=>{
    const callDeatail = async ()=>{
      const response = await getSingleScheduleCall(callId,tokenId);
      setCallDetail({...response})
    }
    callDeatail();
 },[])
 

  return (
    <div >
      <Gobackpage pageName={"Schedule Call Details"}/>
      
      {/* Billing Information */}
      <div  style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" , minWidth:"400px"}}>
        <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
          Schedule  
        </h3>
        <p className="mx-2 dashboard_leadView_details_heading_second">
          Lead Id : LI-{callDetail?.leadId}
        </p>
        {/* <div className="row">
          <div className="col-xl-12">
            <div className="d-xl-flex d-md-flex justify-content-between flex-wrap justify-content-center align-items-start row-cols-3">
               <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call To
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callTo}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Related To
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.relatedTo}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Type
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callType}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Related To
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.relatedTo}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Type
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callType}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Status
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callStatus}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Start Time
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callStartTime}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Owner
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callOwner}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
               <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Start Time
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callStartTime}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Owner
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callOwner}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Subject
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.subject}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Reminder
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.reminder}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        reminder
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.reminder}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> 
            </div>
          </div>
        </div> */}
         <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: "20px" }}>
          {[
            { label: 'Call To', value: callDetail?.callTo },
            { label: 'Related To', value: callDetail?.relatedTo },
            { label: 'Call Type', value: callDetail?.callType },
            { label: 'Call Status', value: callDetail?.callStatus },
            { label: 'Call Start Time', value: callDetail?.callStartTime },
            { label: 'Call Owner', value: callDetail?.callOwner },
            { label: 'Subject', value: callDetail?.subject },
            { label: 'reminder', value: callDetail?.reminder },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 40%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{ fontWeight: "bold" }}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word', marginLeft: "10px" }}>{ callDetail?.callTo ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Purpose of Outgoing call  */}
      <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
        <h5 className=" dashboard_leadView_company_details_heading">
          Purpose Of Outgoing Call
        </h5>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: "20px" }}>
          {[
            { label: 'Call Purpose', value: callDetail?.callPurpose },
            { label: 'Call Agenda', value: callDetail?.callAgenda },
          
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{ fontWeight: "bold" }}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word', marginLeft: "10px" }}>{ callDetail?.callTo ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Purpose Of Outgoing Call */}
      {/* <div className="account_view_details_Row">
        <h3 className="my-2 mx-2 dashboard_leadView_company_details_heading">
          Purpose Of Outgoing Call
        </h3>
        <div className="row">
          <div className="col-xl-12">
            <div className="d-xl-flex d-md-flex justify-content-between flex-wrap justify-content-center align-items-center row-cols-3">
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Purpose
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callPurpose}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive account_view_details_table">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Call Agenda
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {data?.callAgenda}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
              <div className="table-responsive account_view_details_table"></div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CallViewDetails;
