import React, { useState, useEffect } from "react";
// React Router Dom
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import Loader from "../pages/Loader";
import { LiaUserEditSolid } from "react-icons/lia";
import { PiProhibit } from "react-icons/pi";
const LogCallTable = ({
  callsPermission,
  pageSize,
  pageNo,
  loading,
  tblHead,
  redirectLink,
  getAllLogCallData,
  logCallCostumerId,
  setLogCallCostumerId,
}) => {
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  const navigate = useNavigate();
  // Handle Single Check Box For Single Updateion And Id get and send Start ------
  const handleCheckboxChange = (callId) => {
    const isSelected = logCallCostumerId.includes(callId);
    if (isSelected) {
      setLogCallCostumerId(logCallCostumerId.filter((id) => id !== callId));
    } else {
      setLogCallCostumerId([...logCallCostumerId, callId]);
    }
  };
  // Handle Single Check Box For Single Updateion And Id get and send End    ------
  // Handle Master Checkbox Change Start -----
  const handleMasterCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsMasterChecked(isChecked);
    const allCallIds = getAllLogCallData?.content?.map((data) => data.id) || [];
    if (isChecked) {
      setLogCallCostumerId(allCallIds);
    } else {
      setLogCallCostumerId([]);
    }
  };
  // Handle Master Checkbox Change End   -----
  useEffect(() => {    
    const allLeadIds = getAllLogCallData?.content?.map((data) => data.id) || [];
    if (allLeadIds.length === 0) return;
    if (logCallCostumerId.length === allLeadIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [logCallCostumerId, getAllLogCallData]);

  return (
    <div className="container-fluid">
      <div className="LeadRightSectionTable_body table-responsive">
        <table class="table mytable">
          <thead>
            <tr>
            <th scope="col" className="text_center text-left  text-capitalize">
                <div className="wrap-check-29">
                  <div className="cbx">
                    <input
                      id="cbx-29"
                      type="checkbox"
                      onChange={handleMasterCheckboxChange}
                      checked={isMasterChecked}
                    />
                    <label htmlFor="cbx-29" />
                    <svg width={15} height={14} viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2" />
                    </svg>
                  </div>
                  {/* Gooey*/}
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo-12">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation={4}
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                          result="goo-12"
                        />
                        <feBlend in="SourceGraphic" in2="goo-12" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </th>
              <th
                scope="col"
                className="text-left text_center text-capitalize"
                style={{ cursor: "context-menu" }}
              >
                Sr. No
              </th>
              <th scope="col" className="text-left text_center text-capitalize">
                {tblHead.eighthHead}
              </th>
              <th className="text_center text-left text-capitalize" scope="col">
                {tblHead.firstHead}
              </th>
              <th className="text_center text-left text-capitalize" scope="col">
                {tblHead.secondHead}
              </th>
              <th className="text_center text-left text-capitalize" scope="col">
                {tblHead.thirdHead}
              </th>
              <th className="text_center text-left text-capitalize" scope="col">
                {tblHead.fourthHead}
              </th>
              <th className="text_center text-left text-capitalize" scope="col">
                {tblHead.fifthHead}
              </th>
              <th className="text_center text-left text-capitalize" scope="col">
                {tblHead.sixthHead}
              </th>
              <th scope="col" className="text-center text_center text-capitalize">
                {tblHead.seventhHead}
              </th>
              <th className=" text_center text-center text-capitalize">Edit</th>
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody className="dashboard_section1_tableBody ">
              {getAllLogCallData && getAllLogCallData?.content?.length ? (
                getAllLogCallData?.content?.map((data, index) => (
                  <tr
                    key={data.id}
                    onClick={() => localStorage.setItem("logCallId", data.id)}
                  >
                     <td className=" text-left text_center text-capitalize">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="flexCheckIndeterminate"
                        onChange={() => handleCheckboxChange(data.id)}
                        checked={logCallCostumerId?.includes(data.id)}
                      />
                    </td>
                    <td className=" text-left text_center text-capitalize">
                      <span className="Link-button-leads">
                        <span
                          style={{
                            cursor: "context-menu",
                            border: "1px solid #9c9c9c",
                            padding: "2px 4px",
                            borderRadius: "3px",
                          }}
                        >
                          {`${
                            pageNo * pageSize + index < 9
                              ? `0${pageNo * pageSize + index + 1}`
                              : pageNo * pageSize + index + 1
                          }`}
                          {/* {pageNo * pageSize + index + 1} */}
                        </span>
                      </span>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.subject}
                    </Link>
                  </td> */}
                    <td className="text-left text_center text-capitalize">
                      <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                        {/* <div className="leads_table_id_col rounded"> */}
                        <span className="leads_table_id_text">
                          LI-{data.leadId}
                        </span>
                        {/* </div> */}
                      </Link>
                    </td>
                    <td className=" text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.subject || "No Data"}
                        <span className="tool_tip_box"> {data.subject || "No Data"}</span>
                      </div>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.callPurpose}
                    </Link>
                  </td> */}
                    <td className=" text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.callPurpose || "No Data"}
                        <span className="tool_tip_box">
                          {" "}
                          {data.callPurpose || "No Data"}
                        </span>
                      </div>
                    </td>
                    <td className=" text_center text-left text-capitalize">
                      <Link to={redirectLink} className="Link-button-leads">
                        {data.callType || "No Data"}
                      </Link>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.callResult}
                    </Link>
                  </td> */}
                    <td className=" text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.callResult || "No Data"}
                        <span className="tool_tip_box"> {data.callResult || "No Data"}</span>
                      </div>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.callStatus}
                    </Link>
                  </td> */}
                    <td className=" text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.callStatus || "No Data"}
                        <span className="tool_tip_box"> {data.callStatus || "No Data"}</span>
                      </div>
                    </td>
                    <td className=" text_center text-capitalize">
                      <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                        <FaRegEye className="showDetailEye fs-4" />
                      </Link>
                    </td>
                    <td className="text-center text_center text-capitalize">
                      <Link
                        to="/create-meeting"
                        state={{ leadId: data.leadId, from: "CallLog" }}
                      >
                        <HiPencilSquare className="lead-table-contact-action-icon fs-4" />
                      </Link>
                      {/* &nbsp;&nbsp;
                    <Link to="">
                      <FaRegEye className="fs-5" /> 
                    </Link> */}
                    </td>
                    <td className="text-center text-capitalize">
                      {callsPermission?.includes("Update") ? (
                        <LiaUserEditSolid
                          className="lead-table-contact-action-icon fs-4"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/log-call", {
                              state: { data, from: "update" },
                            })
                          }  
                        />
                      ) : (
                        <PiProhibit 
                          style={{ cursor: "crosshair" }}
                          className="lead-table-contact-action-icon fs-4"
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className=" text-capitalize text-center">There are no Log calls at the moment.</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default LogCallTable;
