import React from 'react'
import Goback from '../components/goback';

function Gobackpage({pageName,page}) {

  
  return (
    <div style={{display:"flex",alignItems:"center"}}>
            <Goback page={page}/>
            <span style={{ fontSize: "21px", fontWeight: "700" }}>
              {pageName}
            </span>
          </div>
  )
}

export default Gobackpage;