import React, { useState, useEffect } from "react";
// CSS
// import "../../styles/dashboardCss/leadCostumerDetails.css";
// React Icons
import { MdVerified } from "react-icons/md";
import { MdCancel } from "react-icons/md";
// Import Toast
import Toast from "../../components/Toast";
// Import api function from controller
import {
  getSingleLead,
  verifyLeads,
  rejectedLeads,
} from "../../controller/fetchApi";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage"; 


const LeadCostumerDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const leadId = location.state?.leadId;
  // Start Toast Code-------
  const [showToast, setShowToast] = useState({ success: false, message: "",optmessage:"" });
  // hise toast
    useEffect(() => {
       if (showToast.message) {
         const timer = setTimeout(() => {
           setShowToast({ success: false, message: "", optmessage: "" });
         }, 3000);
   
         return () => clearTimeout(timer);
       }
     }, [showToast])

  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("leadId"));
  // const leadId = userIdTokenData;
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  const [getSingleLeadData, setSingleLeadData] = useState([]);
  const [uploading,setuploading] = useState(false)

  //  Verified lead
  const handleVerifyLeads = async (leadId) => { setuploading(true)
    const response =  await verifyLeads(leadId, setShowToast, tokenId);
   
    if(response){
      setTimeout(() => {
        navigate("/leads");
        setuploading(false);
      },1000);
    }
  };

  // Rejected lead
  const handleRejectedLeads = async (leadId) => { setuploading(true);
    await rejectedLeads(leadId, setShowToast, tokenId);
    if(rejectedLeads){
      setTimeout(() => {
        setuploading(false);
        navigate("/leads")
      }, 1000);
    }
  };

  useEffect(() => {
    getSingleLead(leadId, tokenId).then((res) => {
      setSingleLeadData(res);
    });
  }, [leadId, tokenId]);

  return (
    <>
   <Gobackpage pageName={"Lead Details"}/>
      <div className="container-fluid dashboard_create_lead_main_container">
      
        <h2 className="mt-3 mx-2 dashboard_leadView_details_heading">
          Lead Information
        </h2>
        <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
           <p className="mx-2 dashboard_leadView_details_heading_second">
          Lead Id : LI-{getSingleLeadData?.id}
        </p>
        {/* Cosutmer Details */}
        <div >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Lead Owner', value: getSingleLeadData?.leadOwner },
            { label: 'Mobile Number', value: getSingleLeadData?.mobile },
            { label: 'Lead Status', value: getSingleLeadData?.leadStatus },
            { label: 'Lead Service', value: getSingleLeadData?.leadService },
            { label: ' Email', value: getSingleLeadData?.email   },
            { label: 'Phone', value: getSingleLeadData?.phone },
            { label: 'Lead Source', value: getSingleLeadData?.leadSource },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word' ,  marginLeft:"10px" }}>{item.value || 'No Data'}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
        </div>
       
        
        {/* Company Details */}
        <h3 className=" mx-2 dashboard_leadView_company_details_heading" style={{marginTop:"40px"}}>
          Company Details
        </h3>
       
        <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "15px" }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Annual Revenue', value: getSingleLeadData?.annualRevenue },
            { label: 'Company Contact', value: getSingleLeadData?.companyContact },
            { label: 'District', value: getSingleLeadData?.district },
            { label: 'Company Name', value: getSingleLeadData?.companyName },
            { label: ' Secondary Contact', value: getSingleLeadData?.secondaryContact   },
            { label: 'State', value: getSingleLeadData?.state },
            { label: 'Company Email', value: getSingleLeadData?.companyEmail },
            { label: 'City', value: getSingleLeadData?.city },
            { label: 'Country', value: getSingleLeadData?.country },
            { label: 'Description', value: getSingleLeadData?.description },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word' ,  marginLeft:"10px" }}>{item.value || 'No Data'}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
       
      </div>
      
      <div>
        {getSingleLeadData?.leadType === "verified" ? (
          ""
        ) : getSingleLeadData?.leadType === "unverified" ? (
          <div className=" view_leads_details_btn_div">
            <button 
            disabled={uploading}
              className="leads_verify_btn"
              onClick={() => handleVerifyLeads(getSingleLeadData?.id)}
            >
              <MdVerified className="leads_verify_btn_icon" />
              Verify Leads
            </button>

            <button
              disabled={uploading}
              className="leads_reject_btn"
              onClick={() => handleRejectedLeads(getSingleLeadData?.id)}
            >
              <MdCancel className="leads_verify_btn_icon" />
              Reject Leads
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </>
  );
};

export default LeadCostumerDetails;
