import {
  // Dashboard & Singup Api
  SIGNUP_USER,
  OTP_VERIFICATION_URL,
  RESEND_OTP_URL,
  LOGIN_USER,
  LOGIN_WITH_GOOGLE,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD_VALIDATE,
  RESET_PASSWORD,
  MONHTLY_MEETINGS_URL,
  MONTHLY_TASK_URL,
  MONTHLY_CLOSING_DEALS_URL,
  PIPELINE_DEALS_URL,
  GET_CURRENT_USER_URL,
  UPDATE_PROFILE_URL,
  UPLOAD_USER_IMG_URL,
  // Super Admin Url
  GET_ALL_ROLES,
  GET_ALL_MODULE,
  GET_MODULE_PERMISSIONS,
  SEND_ROLE_MODULE_PERMISSIONS,
  UPDATE_USER_PERMISSION_URL,
  CREATE_USERS_URL,
  DELETE_USERS_URL,
  GET_TOTAL_LEADS_URL,
  GET_TOTAL_ROLES_URL,
  SINGLE_USER_PERMISSION_URL,
  // Leads Url
  ASSIGN_LEADS_URL,
  CREATE_LEAD_URL,
  GET_SINGLE_LEAD_URL,
  DELETE_LEADS_URL,
  SINGLE_LEAD_UPDATE_URL,
  GET_ALL_LEAD_URL_BY_FILTER,
  VERIFY_LEADS_URL,
  UNVERIFIED_LEADS_URL,
  DOWNLOAD_LEADS_URL,
  UPLOAD_LEADS_URL,
  // Dropdowns
  LEAD_STATUS_URL,
  // LEAD_SERVICES_URL,
  LEAD_SOURCE_URL,
  DEAL_STAGES_URL,
  TASK_STATUS_URL,
  CALL_RELATED_URL,
  CALL_PURPOSE_URL,
  CALL_RESULT_URL,
  // Contact Url
  CREATE_CONTACT_URL,
  GET_SINGLE_CONTACT_URL,
  GET_ALL_CONTACT_URL,
  DELETE_CONTACT_URL,
  DOWNLOAD_CONTACT_URL,
  GET_CONTACTS_URL,
  // Accounts Url
  CREATE_ACCOUNTS_URL,
  GET_ALL_ACCOUNT_URL,
  DOWNLOAD_ACCOUNT_URL,
  GET_SINGLE_ACCOUNT_URL,
  DELETE_ACCOUNT_URL,
  UPDATE_SINGLE_ACCOUNT_URL,
  // Deal Url
  CREATE_DEAL_URL,
  GET_ALL_DEALS_URL,
  DELETE_DEALS_URL,
  UPDATE_SINGLE_DEAL_URL,
  GET_sINGLE_DEAL_URL,
  DOWNLOAD_DEAL_URL,
  UPLOAD_DEAL_URL,
  // Task Url
  CREATE_TASK_URL,
  GET_ALL_TASK_URL,
  DELETE_TASK_URL,
  GET_SINGLE_TASK_URL,
  DOWNLOAD_TASK_URL,
  UPDATE_TASK_URL,
  UPLOAD_TASK_URL,
  // Meeting Url
  CREATE_MEETING_URL,
  GET_ALL_MEETINGS_URL,
  DELETE_MEETINGS_URL,
  SINGLE_MEETINGS_URL,
  UPDATE_MEETINGS_URL,
  DOWNLOAD_MEETING_URL,
  UPLOAD_MEETING_URL,
  // Call Url
  CREATE_LOG_CALL_URL,
  CREATE_SCHEDULE_CALL_URL,
  GET_ALL_SCHEDULE_CALL_URL,
  GET_ALL_LOG_CALL_URL,
  GET_SINGLE_SCHEDULE_CALL_URL,
  GET_SINGLE_LOG_CALL_URL,
  DELETE_SCHEDULE_URL,
  LOG_CALL_DELETE_URL,
  UPLOAD_SCHEDULE_CALL_URL,
  UPLOAD_LOG_CALL_URL,
  DOWNLOAD_SCHEDULE_CALL_URL,
  DOWNLOAD_LOG_CALL_URL,
  UPDATE_SCHEDULE_CALL_URL,
  UPDATE_LOG_CALL_URL,
  // Report Url
  GET_GENRATED_LEADS_URL,
  GET_DEALS_DONE_URL,
  GET_DONE_CALLS_URL,
  //chatbot api
  Chat_user,
  //setpin api
  SET_MPIN,
  //user image api
  GET_USER_image,
  Create_Company_URL,
  GET_All_COMPANY_URL,
  DELETE_COMPANY_URL,
  UPDATE_COMPANY_DETAILS_URL,
  SEARCH_CALL_API,
  SEARCH_RELATEDTO_API,
  UPLOAD_LOGO_URL,
  GET_USER_LOG,
  GET_PRODUCT_SERVICES,
  DELETE_PRODUCT_SERVICES,
  UPLOAD_PRODUCT_SERVICES,
  DOWNLOAD_SELECTED_CONTACT_URL,
  CHANGE_PASSWORD,
  VERIFY_PASSWORD_OTP,
} from "../constants/Constant";
import axios from "axios";

// getcategoryDataApi
export const getcategoryDataApi = async (CategoryType, tokenId) => {
  try {
    const response = axios.get(GET_PRODUCT_SERVICES + `?type=${CategoryType}`, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    return response;
  } catch (error) {return error;
    
  }
};
//get product and services api
export const getProductServices = async (tokenId,pageNo,pageSize) => {
  try {
    const response = await axios.get(GET_PRODUCT_SERVICES+"?page="+pageNo+'&size='+pageSize, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    return response;
  } catch (error) {
   
    return error;
  }
};
// delete product and services api
export const deleteProductServices = async (token, mpin, productId) => {

  try {
    const response = await axios.delete(
      `${DELETE_PRODUCT_SERVICES}?id=${productId}&mpin=${mpin}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    
    return error;
  }
};
// Add product and services api
export const AddProduct = async (producttype, productname, tokenId) => {
  try {
    const response = await axios.post(
      UPLOAD_PRODUCT_SERVICES,
      { type: producttype, name: productname },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
// get Userlog information
export const getuserLog = async (userId, tokenId) => {
  try {
    const response = await axios.get(GET_USER_LOG + userId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response) {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};
// fetch related to api
export const fetchRelatedlog = async (
  name,
  relatedtype,
  tokenId,
  setShowToast
) => {
  try {
    const url = `${SEARCH_RELATEDTO_API}?moduleType=${relatedtype}&name=${name}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    return error;
  }
};
// fetchcall api
export const fetchCalllog = async (name, calltype, tokenId, setShowToast) => {
  try {
    const url = `${SEARCH_CALL_API}?callType=${calltype}&name=${name}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    return error;
  }
};
//set Mpin api

export const setmpin = async (tokenId, mPin) => {
  try {
    const url = `${SET_MPIN}?mPin=${mPin}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
//get user image
export const getUserImage = async (tokenId) => {
  try {
    const response = await axios.get(GET_USER_image, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};
//chatboat userdata post api
export const postuserdata = async ({ name, email, contact }) => {
  try {
    const response = await fetch(Chat_user, {
      method: "POST",
      body: JSON.stringify({ name: name, email: email, mobile: contact }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    return error;
  }
};

// get country code
export const countryCode = async () => {
  const response = await axios.get("https://restcountries.com/v3.1/all");
  if (response?.status === 200) {
    return response;
  }
  return response;
};
// Signup User Post Api
export const signupUser = async (
  userData,
  setSubmitting,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.post(
      SIGNUP_USER,
      {
        firstName: userData.firstName,
        lastName: userData.lastName,
        userName: userData.userName,
        email: userData.email,
        password: userData.password,
        confirmPassword: userData.confirmPassword,
        mobile: userData.phone,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response?.data?.status == 200) {
      setShowToast({
        success: true,
        message: "Sign up successful.",
        optmessage: "Creating user...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Creating user...",
      });
    }
  } catch (error) {
    const { message } = error.response.data;
    // Show error message in toast
    setShowToast({ success: true, message, optmessage: "Creating user..." });
  } finally {
    setSubmitting(false);
  }
};

// OTP Verification Post Api
export const otpVerification = async (userData, setShowToast, tokenId) => {
  try {
    const response = await axios.post(
      OTP_VERIFICATION_URL,
      {
        email: userData.email,
        otp: userData.otp,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    // Set Data In Local Storage
    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "User created successfully.",
        optmessage: "Creating user...",
      });
      localStorage.setItem("users", JSON.stringify(response.data));
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Creating user...",
      });
    }
  } catch (error) {
    const { message } = error.response.data;
    setShowToast({
      success: true,
      message: "InCorrect Otp ! Please Try Again",
      optmessage: "Creating user...",
    });
  }
};

// Resend Otp Post Api
export const resendOtp = async (email) => {
  try {
  
    const response = await axios.post(RESEND_OTP_URL + email);
   
    return response;
  } catch (error) {
    const { message } = error.response.data;
    return error;
  }
};

// Login User Post Api
export const loginUser = async (userData, setShowToast) => {
  try {
    const response = await axios.post(LOGIN_USER, {
      email: userData.email,
      password: userData.password,
    });

    // Set Data In Local Storage

    if (response.data.status === 200) {
      setShowToast({
        success: true,
        message: "Login Successfully.",
        optmessage: "User login...",
      });

      localStorage.setItem("user", JSON.stringify(response.data));
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "User login...",
      });
    }
  } catch (error) {
    const message = error?.message || "";
    // Show error message in toast
    setShowToast({
      success: true,
      message: message ? message : "Please check your network connection.",
      optmessage: "User login...",
    });
  }
};

// Login User Through Google
export const loginUserThroughGoogle = async (userData, setShowToast) => {
  try {
    const response = await axios.post(LOGIN_WITH_GOOGLE, {
      email: userData.email,
      firstName: userData.firstName,
      userName: userData.userName,
      name: userData.name,
    });

    // Set Data In Local Storage
    if (response.data.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      // Show success message in toast
      setShowToast({ success: true, message: "Sign In successfully." });
    }
    if (response.data.status !== 206) {
      setShowToast({ success: false, message: "Invalid Credentials." });
    }
    return response;
  } catch (error) {}
};

// Logout User Api
export const logoutUser = async (tokenId) => {
  try {
    const response = await axios.post(
      LOGOUT_USER,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    return response;
  } catch (error) {
   
    const { message } = error?.message;
    return message;
  }
};

// change password api
export const changePasswordApi = async (UpdatePasswordRequest,tokenId,setShowToast)=>{
  try{
    const response = await axios.post(CHANGE_PASSWORD,UpdatePasswordRequest,
      {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    })
     if(response?.data?.status==200){
      return response?.data;
     }
     else{
      setShowToast({
        success: true,
        message: response?.message,
        optmessage: "Updating Password...",
      })
     }
  
  }
  catch(error){
    setShowToast({
      success: true,
      message: error.message,
      optmessage: "Updating Password...",
    })
  }
}

// verify otp for password change
export const verifyPasswordChange = async (password,otp,tokenId,setShowToast)=>{
 
  try{
//  const response = await axios.post(VERIFY_PASSWORD_OTP,{otp:otp,newPassword:password},
const response = await axios.post(VERIFY_PASSWORD_OTP,{newPassword:password , otp:otp},
  {
 headers:{
  Authorization:`Bearer${tokenId}`
 }
 });

 if(response?.data?.status==200){
  return response?.data;
 }
 else{
  setShowToast({
    success: true,
    message: response?.message,
    optmessage: "Updating Password...",
  })
 }
  }
  catch(error){
    setShowToast({
      success: true,
      message: error.message,
      optmessage: "Updating Password...",
    })
  }

}
// Upload User Img
export const uploadUserImg = async (
  file,
  setShowToast,
  tokenId
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_USER_IMG_URL, formData, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Upload Successfully.",
        optmessage: "Updating Profile...",
      });
     
      return response;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// upload logo api
export const uploadLogo = async (
  selectlogo,
  setShowToast,
  tokenId,
) => {
  // UPLOAD_LOGO_URL
  const formData = new FormData();
  formData.append("file", selectlogo);

  try {
    const response = await axios.post(UPLOAD_LOGO_URL, formData, {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Logo updated successfully.",
        optmessage: "Updating logo...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Updating logo...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
   
    setShowToast({
      success: true,
      message: message,
      optmessage: "Updating logo...",
    });
  }
};

// Forgot Password Api
export const forgotPassword = async (email, setShowToast) => {
  try {
    const response = await axios.post(FORGOT_PASSWORD, {
      email: email,
    });
    // Show success message in toast

    setShowToast({ success: true, message: `${response?.data?.message}` });
    return response;
  } catch (error) {
    const { message } = error.response.data;
    // Show error message in toast
    setShowToast({ success: false, message });
  }
};

// Reset Password Validation Api
export const resetPasswordValidate = async (uid) => {
  try {
    const response = await axios.post(RESET_PASSWORD_VALIDATE + uid, {
      uid: uid,
    });
    return response;
  } catch (error) {
    const { message } = error.response.data;
    return message;
  }
};

// Reset Password
export const resetPassword = async (userData, uid, setShowToast) => {
  try {
    const response = await axios.post(RESET_PASSWORD + uid, {
      email: userData.email,
      password: userData.password,
      confirmedPassword: userData.confirmPassword,
    });
    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Password reset successfully",
        optmessaage: "Setting password...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessaage: "Setting password...",
      });
    }
  } catch (error) {
    const { message } = error.response.data;
    // Show error message in toast
    setShowToast({
      success: true,
      message: message,
      optmessaage: "Setting password...",
    });
  }
};

// Update Profile
export const updateProfile = async (tokenId, setShowToast, profileData) => {
  try {
    const response = await axios.put(
      UPDATE_PROFILE_URL,
      {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        mobile: profileData.phone,
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );

    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Profile Updated.",
        optmessage: "Updating profile...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Updating profile...",
      });
    }
  } catch (error) {
    const { message } = error.response.data;

    if (error.response.status === 403) {
      setShowToast({
        success: true,
        message: "You are not authorize.",
        optmessage: "Updating profile...",
      });
    } else {
      setShowToast({
        success: true,
        message: message,
        optmessage: "Updating profile...",
      });
    }
  }
};

// --------------Super Admin Configuration Api -------------

// Roles Get Api

export const getRoles = async (tokenId) => {
  try {
    const response = await axios.get(GET_ALL_ROLES, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
   
    return message;
  }
};

// Module Get Api
export const getModules = async (tokenId) => {
  try {
    const response = await axios.get(GET_ALL_MODULE, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {}
};

// Module Get Api
export const getModulePermissions = async (tokenId) => {
  try {
    const response = await axios.get(GET_MODULE_PERMISSIONS, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {}
};

// Send Role & Permissions
export const sendRoleModulePermissions = async (
  formData,
  tokenId,
  uid,
  setShowToast
) => {
  try {
    
    const response = await axios.post(
      SEND_ROLE_MODULE_PERMISSIONS + uid,
      formData,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    setShowToast({
      success: true,
      message: "Save Role & Permission Successfully",
    });
    return response;
  } catch (error) {
    const message = error?.response?.data;
   
    return message;
  }
};

// Update Roles & Permissions
export const updateRoleModulePermissions = async (
  formData,
  tokenId,
  uid,
  setShowToast
) => {
  try {
    const response = await axios.put(
      UPDATE_USER_PERMISSION_URL + uid,
      formData,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Updated Role & Permission Successfully",
        optmessage: "Updating role&permission...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Updating role&permission...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Updating role&permission...",
    });
  }
};

// get all company made by superAdmin
export const getAllCompanies = async (tokenId, pageSize, pageNo) => {
  try {
    const URL = `${GET_All_COMPANY_URL}?size=${pageSize}&page=${pageNo}`;
    const response = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      // return response?.data?.data;
      return response?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get All User Who Made Super Admin
export const getAllUsersMadeByAdmin = async (tokenId, pageNo, pageSize) => {
  try {
    const URL = `${CREATE_USERS_URL}?page=${pageNo}&size=${pageSize}`;
    const response = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      return response;
    }
  } catch (error) {
    const message = error?.message;
  
    return error;
  }
};
// delete company by SuperAdmin
export const deleteCompanyBySuperAdmin = async (
  token,
  delcompany,
  setShowToast,
  pin
) => {
  try {
    const URL = `${DELETE_COMPANY_URL}?companyId=${delcompany}&pin=${pin}`;
    const response = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Company deleted Successfully.",
        optmessage: "Deleting company...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting company...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Deleting company...",
    });
  }
};
// Delete User By Admin
export const deleteUserByAdmin = async (tokenId, uid, setShowToast, pin) => {
  try {
    const url = `${DELETE_USERS_URL}${uid}&mpin=${pin}`;
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data.status === 200) {
      setShowToast({
        success: true,
        message: "User deleted Successfully.",
        optmessage: "Deleting user...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting user...",
      });
    }
  } catch (error) {
    setShowToast({
      success: true,
      message: error?.message,
      optmessage: "Deleting user...",
    });
  }
};

// Get All Total Leads
export const getTotalLeadsInSuperAdmin = async (tokenId) => {
  
  try {
    const response = await axios.get(GET_TOTAL_LEADS_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Total Role like : Superadmin, Admin, Project manager, Sales Executives
export const getTotalRoles = async (tokenId) => {
  try {
    const response = await axios.get(GET_TOTAL_ROLES_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Single User Permission
export const getSingleUserPermission = async (userId, tokenId) => {
  try {
    const response = await axios.get(SINGLE_USER_PERMISSION_URL + userId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.status === 200) {
      return response?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// --------------Dashboard Configuration Api -------------

// Meeting This Month Get Api
export const monthlyMeetings = async (uid, tokenId) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    let response;
    if (uid) {
      response = await axios.get(
        `${MONHTLY_MEETINGS_URL}?userId=` + uid,
        config
      );
    } else {
      response = await axios.get(MONHTLY_MEETINGS_URL, config);
    }
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    } else {
      return null;
    }
  } catch (error) {
    const message = error?.response?.data;
    
    return message;
  }
};

// Task This Month  Get Api
export const monthlyTask = async (uid, tokenId) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    let response;
    if (uid) {
      response = await axios.get(`${MONTHLY_TASK_URL}?userId=` + uid, config);
    } else {
      response = await axios.get(MONTHLY_TASK_URL, config);
    }
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    } else {
      return null;
    }
  } catch (error) {
    const message = error?.response?.data;
    return message;
  }
};

// Closing Deals This Month Get Api
export const monthlyClosingDeals = async (uid, tokenId) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const URL = uid
      ? `${MONTHLY_CLOSING_DEALS_URL}?userId=${uid}`
      : MONTHLY_CLOSING_DEALS_URL;
    const response = await axios.get(URL, config);
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    } else {
      return null;
    }
  } catch (error) {
    const message = error?.response?.data;
    return message;
  }
};

// Pipeline Deals Get Api
export const pipelineDeals = async (uid, tokenId) => {
  try {
    let response;
    if (uid) {
      response = await axios.get(`${PIPELINE_DEALS_URL}?userId=` + uid, {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });
    } else {
      response = await axios.get(PIPELINE_DEALS_URL, {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });
    }

    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    } else {
      return [];
    }
  } catch (error) {
    const message = error?.response?.data;

    return message;
  }
};

// Get Current User Api
export const getCurrentUser = async function (tokenId, userId) {
  try {
    // let url;
    // if (userId) {
    //   url = `${GET_CURRENT_USER_URL}?userId=${userId}`;
    // } else {
    //   url = GET_CURRENT_USER_URL;
    // }

    const url =
      userId === undefined
        ? GET_CURRENT_USER_URL
        : `${GET_CURRENT_USER_URL}?userId=${userId}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;

    return finalResponse;
  } catch (error) {
    const message = error?.response?.data;
    return message;
  }
};

// ********* Leads Page Api **********

// Create Lead Api
export const createLead = async (userData, uid, setShowToast, tokenId) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const response = await axios.post(
      CREATE_LEAD_URL,
      {
        ...userData,
        // pinCode: userData.pinCode,
        // address: userData.address,
        // productName: userData.productName,
        // subject: userData.subject,
        // emailAlt: userData.emailAlt,
        // phone: userData.phone,
        // leadOwner: userData.leadOwner,
        // firstName: userData.firstName,
        // lastName: userData.lastName,
        // mobile: userData.mobile,
        // mobileAlt: userData.mobileAlt,
        // email: userData.email,
        // leadSource: userData.leadSource,
        // leadStatus: userData.leadStatus,
        // annualRevenue: userData.annualRevenue,
        // companyName: userData.companyName,
        // companyEmail: userData.companyEmail,
        // companyContact: userData.companyContact,
        // secondaryContact: userData.secondaryContact,
        // city: userData.city,
        // district: userData.district,
        // state: userData.state,
        // country: userData.country,
        // description: userData.description,
        // priority: userData.priority,
      },
      config
    );

    // Show success message in toast
    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Adding lead...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Adding lead...",
      });
    }
  } catch (error) {
    const { message } = error.response.data;
    // Show error message in toast
    setShowToast({
      success: true,
      message: message,
      optmessage: "Adding lead...",
    });
    return message;
  }
};

// Get All Lead By Filter Api
export const getAllLeadByFilter = async (filters, tokenId, setShowToast) => {
  const queryString = Object.keys(filters)
    .filter((key) => filters[key]) // Only include filters that have a value
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(filters[key])
    )
    .join("&");

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const response = await axios.get(
      GET_ALL_LEAD_URL_BY_FILTER + queryString,
      config
    );
    const finalResponse = response?.data?.data;
    if (response?.data?.status === 200) {
      return finalResponse;
    }
    // else {
    //   setShowToast({
    //     success: true,
    //     message: response.data.message,
    //     optmessage: "Finding lead...",
    //   });
    // }
  } catch (error) {
    setShowToast({
      success: true,
      message: error.data.message,
      optmessage: "Finding lead...",
    });
  }
};

// Get Single Lead by Id
export const getSingleLead = async (leadId, tokenId) => {
  try {
    const response = await axios.get(GET_SINGLE_LEAD_URL + leadId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.data?.status === 200) {
      return response?.data?.data;
    } else {
      return [];
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Verifying Leads Post Api
export const verifyLeads = async (leadId, setShowToast, tokenId) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const response = await axios.post(VERIFY_LEADS_URL + leadId, {}, config);

    if (response.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Verify Successfully ",
        optmessage: "Verifing lead...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response.data.message,
        optmessage: "Verifing lead...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Verifing lead...",
    });
  }
};

// Verifying Leads Post Api
export const rejectedLeads = async (leadId, setShowToast, tokenId) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const response = await axios.post(
      UNVERIFIED_LEADS_URL + leadId,
      {},
      config
    );
    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Lead rejected Successfully ",
        optmessage: "Rejecting lead...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Rejecting lead...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Rejecting lead...",
    });
  }
};

// Delete Leads
export const deleteLeads = async (
  leadId,
  setLeadCostumerId,
  setShowToast,
  tokenId,
  pin
) => {
  try {
    const url = `${DELETE_LEADS_URL}leadId=${leadId}&mpin=${pin}`;
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    // DELETE_LEADS_URL + [leadId]
    const response = await axios.delete(url, config);

    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Deleted Successfully.",
        optmessage: "Deleting lead...",
      });
      setLeadCostumerId([]);
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting lead...",
      });
    }
  } catch (error) {
    
    const message = error.response.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Deleting lead...",
    });
  }
};

// Update Single Lead Put Api
export const updateSingleLead = async (
  userData,
  leadId,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.put(
      SINGLE_LEAD_UPDATE_URL + leadId,
      {
        leadOwner: userData?.leadOwner,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        mobile: userData?.mobile,
        mobileAlt: userData?.mobileAlt,
        email: userData?.email,
        leadSource: userData?.leadSource,
        leadStatus: userData?.leadStatus,
        annualRevenue: userData?.annualRevenue,
        companyName: userData?.companyName,
        companyEmail: userData?.companyEmail,
        companyContact: userData?.companyContact,
        secondaryContact: userData?.secondaryContact,
        city: userData?.city,
        district: userData?.district,
        state: userData?.state,
        country: userData?.country,
        description: userData?.description || userData?.leadRemark,
        phone: userData?.phone,
        phoneAlt: userData?.phoneAlt,
        emailAlt: userData?.emailAlt,
        queryMessage: userData?.queryMessage,
        subject: userData?.subject,
        pinCode: userData?.pinCode,
        address: userData?.address,
        // countryIso: userData?.countryIso,
        // productName: userData?.productName,
        // queryMcatName: userData?.queryMcatName,
        callDuration: userData?.callDuration,
        categoryValue:userData?.categoryValue,
        categoryType:userData?.categoryType,
        priority:userData?.priority,
        leadType:userData?.leadType,
        
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Lead updated Successfully.",
        optmessage: "Updating lead...",
      });
      return response;
    } else {
      return null;
    }
  } catch (error) {
    if (error.response.status === 403) {
      setShowToast({ success: true, message: "Please Select Lead" });
    }
  
  }
};

// Download Leads Api get Api
export const downloadLeads = async (setShowToast, tokenId,leadCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_LEADS_URL+"?ids="+leadCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "leads.xlsx");
      document.body.appendChild(link);
      link.click();
      // Show success message in toast
      setShowToast({ success: true, message: "Download Successfully." });
    }
    // return response;
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Upload Leads Post Api
export const uploadLeads = async (file, setShowToast, tokenId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_LEADS_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.status === 200) {
      setShowToast({
        success: true,
        message: "Upload Successfully.",
        optmessage: "Uploading lead...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Uploading lead...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
  
    setShowToast({
      success: true,
      message: message,
      optmessage: "Uploading lead...",
    });
  }
};

// Assign Leads Api
export const assignLeads = async (
  tokenId,
  userId,
  leadId,
  setShowToast,
  setAssignLead
) => {
  try {
    const response = await axios.post(
      `${ASSIGN_LEADS_URL}userId=${userId}&leadsId=${leadId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );

    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Leads Assigned Successfully.",
        optmessage: "Assigning lead...",
      });
      setTimeout(() => {
        setAssignLead(false);
      }, 3000);
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessaage: "Assigning lead...",
      });
    }
  } catch (error) {
   
    const message = error.response.data;
    setShowToast({
      success: true,
      message: message,
      optmessaage: "Assigning lead...",
    });
  }
};

// ************* Dropdowns Api ****************

// Lead Status API
export const leadStatusDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(LEAD_STATUS_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Lead Services API
// export const leadServicesDropdowns = async (tokenId) => {
//   try {
//     const response = await axios.get(LEAD_SERVICES_URL, {
//       headers: {
//         Authorization: `Bearer ${tokenId}`,
//       },
//     });

//     if (response?.status === 200 || 204) {
//       return response?.data?.data;
//     }
//   } catch (error) {
//     const message = error.response.data;
//     return message;
//   }
// };

// Lead Sources API
export const leadSourcesDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(LEAD_SOURCE_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.status === 200 || 204) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Lead Stages API
export const dealStagesDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(DEAL_STAGES_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200 || 204) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Lead Stages API
export const taskStatusDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(TASK_STATUS_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.status === 200 || 204) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Call Related API
export const callRelatedDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(CALL_RELATED_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200 || 204) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Call Purspose API
export const callPurposeDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(CALL_PURPOSE_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.status === 200 || 204) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};

// Call Result API
export const callResultsDropdowns = async (tokenId) => {
  try {
    const response = await axios.get(CALL_RESULT_URL, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
   
    if (response?.status === 200 || 204) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error.response.data;
    return message;
  }
};
// *****************update compabny detail**************8*
export const updateCompanyDetail = async (
  companyDetail,
  setShowToast,
  tokenId,
  companyId
) => {
  try {
    const url = `${UPDATE_COMPANY_DETAILS_URL}?companyId=${companyId}`;
    const response = await axios.put(url, companyDetail, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response.status === 200) {
      setShowToast({ success: true, message: "Details updated Successfully." });
    }
    if (response.status === 203) {
      setShowToast({ success: true, message: response.data?.message });
    }
    return response;
  } catch (error) {
   
    return error;
  }
};
// +*************create company  API***********************
export const createCompany = async (companyDetail, setShowToast, tokenId) => {
  try {
    const response = await axios.post(Create_Company_URL, companyDetail, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response.status === 200) {
      setShowToast({
        success: true,
        message: "Company Created Successfully.",
        optmessaage: "Creating company...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response.data?.message,
        optmessaage: "Creating company...",
      });
    }
  } catch (error) {
    const message = error.response.data;
    setShowToast({
      success: true,
      message: message,
      optmessaage: "Creating company...",
    });
  }
};

// ************* Conatatcs Api ****************

// Create Contact Post Api
export const createContact = async (
  contactData,
  uid,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.post(
      CREATE_CONTACT_URL,
      {
        companyName: contactData.companyName,
        companyEmail: contactData.companyEmail,
        companyContact: contactData.companyContact,
        companyAddress: contactData.address,
        leadId: contactData.leadId,
        description: contactData.description,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );

    if (response?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: " Contact created Successfully.",
        optmessaage: "Creating contact...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.message,
        optmessaage: "Creating contact...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessaage: "Creating contact...",
    });
    // return message;
  }
};

// Get Single Contact Information

export const getSingleContact = async (contactId, tokenId) => {
  try {
    const response = await axios.get(GET_SINGLE_CONTACT_URL + contactId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    } else {
      return [];
    }
  } catch (error) {
    const message = error?.response?.data;
    return message;
  }
};

//Get All Contact get Api
export const getAllContact = async (pageNo, tokenId, pageSize) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const response = await axios.get(
      GET_ALL_CONTACT_URL + pageNo + "&size=" + pageSize,
      config
    );
    const finalResponse = response?.data?.data;

    if (finalResponse) {
      return finalResponse;
    } else {
      return [];
    }
  } catch (error) {
    const message = error?.response?.data;
    return message;
  }
};

//Get All Contact get Api without pagination
export const getContacts = async (tokenId) => {
  try {
   
    let config = {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    };
    const response = await axios.get(GET_CONTACTS_URL, config);
   
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    } else {
      return [];
    }
  } catch (error) {
    const message = error?.response?.data;
    return message;
  }
};

// Delete Contact delete Api
export const deleteContact = async (contactId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(DELETE_CONTACT_URL + contactId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Contact deleted Successfully.",
        optmessage: "Deleting contact...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting contact...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Deleting contact...",
    });
    return message;
  }
};

// Download All Contact get Api
export const downloadContacts = async (uid, setShowToast, tokenId) => {
  try {
    const response = await axios.get(DOWNLOAD_CONTACT_URL + uid, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contacts.xlsx");
      document.body.appendChild(link);
      link.click();
      // Show success message in toast
      setShowToast({ success: true, message: "Download Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};
// download selected contact
export const downloadSelectedContacts = async (uid, setShowToast, tokenId,contactCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_SELECTED_CONTACT_URL+uid +"&ids="+contactCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contacts.xlsx");
      document.body.appendChild(link);
      link.click();
      // Show success message in toast
      setShowToast({ success: true, message: "Download Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};
// **************** Accounts Api **************** //
// Create Account Post Api
export const createAccount = async (
  accountData,
  uid,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.post(
      CREATE_ACCOUNTS_URL,
      {
        accountOwner: accountData.accountOwner,
        accountName: accountData.accountName,
        accountSite: accountData.accountSite,
        parentAccount: accountData.parentAccount,
        accountNumber: accountData.accountNumber,
        aadharCard: accountData.aadharNumber,
        panCard: accountData.panCardNumber,
        accountType: accountData.accountType,
        industry: accountData.industry,
        annualRevenue: accountData.annualRevenue,
        addressInformation: accountData.address,
        leadId: accountData.leadId,
        billingAddress: accountData.billingAddress,
        billingCity: accountData.billingCity,
        billingState: accountData.billingState,
        billingCode: accountData.billingCode,
        shippingStreet: accountData.shippingStreet,
        shippingCity: accountData.shippingCity,
        shippingState: accountData.shippingState,
        shippingCode: accountData.shippingCode,
        shippingAddress: accountData.shippingAddress,
        dateOfIssue: accountData.dateOfIssue,
        dateOfBilling: accountData.dateOfBilling,
        dateOfShipment: accountData.dateOfShipment,
        dealDescription: accountData.description,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response?.status === 200) {
      // Set Success Message
      setShowToast({
        success: true,
        message: "Account created Successfully",
        optmessage: "Creating account...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response?.message,
        optmessage: "Creating account...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Creating account...",
    });
  }
};

// Get All Account get Api
export const getAllAccount = async (tokenId, pageNo, pageSize) => {
  try {
    const response = await axios.get(
      GET_ALL_ACCOUNT_URL + pageNo + "&size=" + pageSize,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    const fimalResponse = response?.data?.data;
    if (fimalResponse) {
      return fimalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// GET Single Account
export const getSingleAccount = async (accountId, tokenId) => {
  try {
    
    const response = await axios.get(GET_SINGLE_ACCOUNT_URL + accountId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    
    const finalResponse = response?.data?.data;
    return finalResponse;
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Download Account get Api
export const downloadAccount = async (uid, setShowToast, tokenId,accountCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_ACCOUNT_URL + uid+"&ids="+accountCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "accounts.xlsx");
      document.body.appendChild(link);
      link.click();
      // Set Success Message
      setShowToast({
        success: true,
        message: "Download Successfully.",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Delete Account delete Api
export const deleteAccount = async (accountId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(DELETE_ACCOUNT_URL + [accountId], {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Account deleted Successfully.",
        optmessage: "Deleting account...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting account...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Update Account put Api
export const updateSingleAccount = async (
  accountData,
  accountId,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.put(
      UPDATE_SINGLE_ACCOUNT_URL + accountId,
      accountData,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response) {
      // Show success message in toast
      setShowToast({ success: true, message: "Update Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// ********** Deal Api ************* //
export const createDeal = async (uid, dealData, setShowToast, tokenId) => {
  try {
    const response = await axios.post(
      CREATE_DEAL_URL,
      {
        dealOwner: dealData.dealOwner,
        dealName: dealData.dealName,
        accountName: dealData.accountName,
        type: dealData.type,
        leadSource: dealData.leadSource,
        contactName: dealData.contactName,
        amount: dealData.amount,
        nextStep: dealData.nextStep,
        stage: dealData.stage,
        expectedRevenue: dealData.expectedRevenue,
        // campaignSource: dealData.campaignSource,
        description: dealData.description,
        closingDate: dealData.closingDate,
        leadId: dealData.leadId,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: " Deal added Successfully.",
        optmessage: "Adding deal...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response.data?.message,
        optmessage: "Adding deal...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    setShowToast({
      success: true,
      message: message,
      optmessage: "Adding deal...",
    });
    
  }
};

export const getAllDeal = async (pageNO, pageSize, tokenId) => {
  try {
    const url = `${GET_ALL_DEALS_URL}page=${pageNO}&size=${pageSize}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Single Deal
export const getSingleDeal = async (dealId, tokenId) => {
  try {
    
    const response = await axios.get(GET_sINGLE_DEAL_URL + dealId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Delete Deals Delete Api
export const deleteDeals = async (dealId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(DELETE_DEALS_URL + [dealId], {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Deal deleted Successfully.",
        optmessage: "Deleting deal...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting deal...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Update Deals Put Api
export const updateDeal = async (dealId, dealData, setShowToast, tokenId) => {
  try {
    const response = await axios.put(
      UPDATE_SINGLE_DEAL_URL + dealId,
      dealData,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response?.status === 2000) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Update Successfully.",
        optmessage: "Updating deal...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Updating deal...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Download Deal get Api
export const downloadDeal = async (setShowToast, tokenId,dealCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_DEAL_URL+"?ids="+dealCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "deals.xlsx");
      document.body.appendChild(link);
      link.click();
      // Set Success Message
      setShowToast({
        success: true,
        message: "Downloaded Deals",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Upload Deal post Api
export const uploadDeals = async (file, setShowToast, tokenId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_DEAL_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      setShowToast({ success: true, message: "Upload Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// ********** TASK Api ************* //

// Create Task Api
export const createTask = async (uid, taskData, setShowToast, tokenId) => {
  try {
    const response = await axios.post(
      CREATE_TASK_URL,
      {...taskData ,
        // taskOwner: taskData.taskOwner,
        // dueDate: taskData.dueDate,
        // contact: taskData.contact,
        // subject: taskData.taskSubject,
        // priority: taskData.priority,
        // leadId: taskData.leadId,
        // status: taskData.status,
        // reminder: taskData.reminderDateTime,
        // accountType: taskData.accountType,
        // description: taskData.description,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response.status === 200) {
      // Show success message in toast
      setShowToast({ success: true, message: "Task created Successfully." });
      return response;
    }
  } catch (error) {
    const message = error?.response?.data;
    
    return error;
  }
};

// Get All Task
export const getAllTask = async (tokenId, pageNo, pageSize) => {
  try {
    const response = await axios.get(
      GET_ALL_TASK_URL + `page=` + pageNo + "&size=" + pageSize,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Delete Task
export const deleteTasks = async (taskId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(DELETE_TASK_URL + [taskId], {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Task deleted Successfully.",
        optmessage: "Deleting task...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting task...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Single Task get Api
export const getSingleTask = async (taskId, tokenId) => {
  try {
    const response = await axios.get(GET_SINGLE_TASK_URL + taskId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Upload Task
export const uploadTask = async (file, setShowToast, tokenId) => {
  try {
   
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_TASK_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });
    
    if (response) {
      setShowToast({ success: true, message: "Upload Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Download Task get Api
export const downloadTasks = async (setShowToast, tokenId,taskCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_TASK_URL+"?ids="+taskCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "task.xlsx");
      document.body.appendChild(link);
      link.click();
      // Set Success Message
      setShowToast({
        success: true,
        message: "Downloaded Deals",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Update Task Put Api
export const updateTask = async (taskId, taskData, setShowToast, tokenId) => {
  try {
    
    const response = await axios.put(UPDATE_TASK_URL + taskId, taskData, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      // Show success message in toast
      setShowToast({ success: true, message: "Update Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// ********* Meetings Api********** //

// Create Meetings
export const createMeeting = async (uid, meetData, setShowToast, tokenId) => {
  try {
    const response = await axios.post(
      CREATE_MEETING_URL,
      {
        title: meetData.title,
        location: meetData.address,
        fromTime: meetData.fromTime,
        host: meetData.host,
        participants: meetData.participants,
        relatedTo: meetData.relatedTo,
        description: meetData.description,
        leadId: meetData.leadId,
        date: meetData.date,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );

    if (response.data.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Meeting created Successfully.",
        optmessage: "Creating meeting...",
      });
      return response;
    } else {
      setShowToast({
        success: true,
        message: response.data.message,
        optmessage: "Creating meeting...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
   
    setShowToast({
      success: true,
      message: message,
      optmessage: "Creating meeting...",
    });
  }
};

// Get All Meetings
export const getAllMeetings = async (pageNo, tokenId, pageSize) => {
  try {
    const response = await axios.get(
      GET_ALL_MEETINGS_URL + `page=` + pageNo + "&size=" + pageSize,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Delete Meeting
export const deleteMeetings = async (meetId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(DELETE_MEETINGS_URL + [meetId], {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Meating deleted Successfully.",
        optmessage: "Deleting meeting...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting meeting...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Single Meeting
export const getSingleMeeting = async (meetId, tokenId) => {
  try {
    const response = await axios.get(SINGLE_MEETINGS_URL + meetId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Update Meetings
export const updateMeeting = async (
  meetId,
  meetData,
  setShowToast,
  tokenId
) => {
  try {
    
    const response = await axios.put(UPDATE_MEETINGS_URL + meetId, meetData, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      // Show success message in toast
      setShowToast({ success: true, message: "Update Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Download Meetings
export const downloadMeetings = async (setShowToast, tokenId,meetCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_MEETING_URL+"?ids="+meetCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Meetings.xlsx");
      document.body.appendChild(link);
      link.click();
      // Set Success Message
      setShowToast({
        success: true,
        message: "Downloaded successfully.",
        optmessage: "Downloading lead...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Upload Meetings
export const uploadMeetings = async (file, setShowToast, tokenId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_MEETING_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.data?.status === 200) {
      setShowToast({
        success: true,
        message: "Uploaded Successfully.",
        optmessage: "Uploading meetings...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Uploading meetings...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Socket Io Connection

// ************* Calls Api ************

// Create Log Call
export const createLogCall = async (uid, callData, setShowToast, tokenId) => {
  try {
    
    const response = await axios.post(
      CREATE_LOG_CALL_URL,
      {
        callTo: callData.callTo,
        relatedTo: callData.relatedTo,
        callType: callData.callType,
        callStatus: callData.callStatus,
        callStartTime: callData.callStartTime,
        callDuration: callData.callDuration,
        callPurpose: callData.callPurpose,
        callAgenda: callData.callAgenda,
        subject: callData.subject,
        leadId: callData.leadId,
        callResult: callData.callResult,
        description: callData.description,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response) {
      
      // Show success message in toast
      setShowToast({ success: true, message: "Create Log Call Successfully." });
    }
  } catch (error) {}
};

// Create Schedule call
export const createScheduleCall = async (
  uid,
  callData,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.post(
      CREATE_SCHEDULE_CALL_URL,
      {
        callTo: callData.callTo,
        relatedTo: callData.relatedTo,
        callType: callData.callType,
        callStatus: callData.callStatus,
        callStartTime: callData.callStartTime,
        callOwner: callData.callOwner,
        subject: callData.subject,
        reminder: callData.reminder,
        leadId: callData.leadId,
        callPurpose: callData.callPurpose,
        callAgenda: callData.callAgenda,
        user: {
          id: uid,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Call Scheduled Successfully.",
        optmessage: "Scheduling call...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Scheduling call...",
      });
    }
  } catch (error) {
    
    setShowToast({
      success: true,
      message: error?.data?.message,
      optmessage: "Scheduling call...",
    });
  }
};

// Get All Schedul Call
export const getAllScheduleCall = async (pageNo, tokenId, pageSize) => {
  try {
    const response = await axios.get(
      GET_ALL_SCHEDULE_CALL_URL + `page=` + pageNo + `&size=` + pageSize,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get All Log Call
export const getAllLogCall = async (pageNo, tokenId, pageSize) => {
  try {
    const response = await axios.get(
      GET_ALL_LOG_CALL_URL + `page=` + pageNo + "&size=" + pageSize,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Single Schedule Call
export const getSingleScheduleCall = async (callId, tokenId) => {
  try {
    const response = await axios.get(GET_SINGLE_SCHEDULE_CALL_URL + callId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Get Single lOG Call
export const getSingleLogCall = async (callId, tokenId) => {
  try {
    const response = await axios.get(GET_SINGLE_LOG_CALL_URL + callId, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    const finalResponse = response?.data?.data;
    if (finalResponse) {
      return finalResponse;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Delete Schedule Call
export const deleteScheduleCall = async (callId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(DELETE_SCHEDULE_URL + [callId], {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "ScheduleCall deleted Successfully.",
        optmessage: "Deleting scheduled call...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting scheduled call...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Delete Log Call
export const deleteLogCall = async (callId, setShowToast, tokenId) => {
  try {
    const response = await axios.delete(LOG_CALL_DELETE_URL + [callId], {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "LogCall deleted Successfully.",
        optmessage: "Deleting log call...",
      });
    } else {
      setShowToast({
        success: true,
        message: response?.data?.message,
        optmessage: "Deleting log call...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};


// Upload Schedule Call
export const uploadScheduleCalls = async (file, setShowToast, tokenId) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_SCHEDULE_CALL_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.status === 200) {
      setShowToast({ success: true, message: "Upload Successfully." });
    } else {
      setShowToast({
        success: true,
        message: response?.message,
        optmessage: "Uploading...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};
// Upload Schedule Call
export const uploadLogCalls = async (file, setShowToast, tokenId) => {
  try {
    
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(UPLOAD_LOG_CALL_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenId}`,
      },
    });
   
    if (response) {
      setShowToast({ success: true, message: "Upload Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Download Schedule Call
export const downloadScheduleCalls = async (setShowToast, tokenId,scheduleCallCostumerId) => {
  try {
    const response = await axios.get(DOWNLOAD_SCHEDULE_CALL_URL+"?ids="+scheduleCallCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Schedule Calls.xlsx");
      document.body.appendChild(link);
      link.click();
      // Set Success Message
      setShowToast({
        success: true,
        message: "Downloaded Schedule Calls",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Download Log Call Url
export const downloadLogCalls = async (setShowToast, tokenId,logCallCostumerId) => {
  try {
 
    const response = await axios.get(DOWNLOAD_LOG_CALL_URL+"?ids="+logCallCostumerId, {
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Log Calls.xlsx");
      document.body.appendChild(link);
      link.click();
      // Set Success Message
      setShowToast({
        success: true,
        message: "Downloaded Log Calls",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Update Schedule Call
export const updateScheduleCall = async (
  scheduleCallId,
  callData,
  setShowToast,
  tokenId
) => {
  try {
    const response = await axios.put(
      UPDATE_SCHEDULE_CALL_URL + scheduleCallId,
      callData,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response) {
      // Show success message in toast
      setShowToast({
        success: true,
        message: "Updated Successfully.",
        optmessage: "Updating scheduled call...",
      });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Update Log Call
export const updateLogCall = async (
  logCallId,
  callData,
  setShowToast,
  tokenId
) => {
  try {
    
    const response = await axios.put(
      UPDATE_LOG_CALL_URL + logCallId,
      callData,
      {
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );
    if (response) {
      // Show success message in toast
      setShowToast({ success: true, message: "Update Successfully." });
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// ************** Report Page Api *****************

// Lead Generated Report Api
export const getGenratedLeads = async (tokenId, leadBy) => {
  try {
    const response = await axios.get(GET_GENRATED_LEADS_URL + leadBy, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });

    if (response?.data?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// calls Done Report Api
export const getCallsDone = async (tokenId, dealBy) => {
  try {
    const response = await axios.get(GET_DONE_CALLS_URL + dealBy, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};

// Deals Done Report Api
export const getDealsDone = async (tokenId, dealBy) => {
  try {
    const response = await axios.get(GET_DEALS_DONE_URL + dealBy, {
      headers: {
        Authorization: `Bearer ${tokenId}`,
      },
    });
    if (response?.data?.status === 200) {
      return response?.data?.data;
    }
  } catch (error) {
    const message = error?.response?.data;
    return error;
  }
};
