import React, { useEffect, useState } from "react";
// React Router Dom
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import Loader from "../pages/Loader";
import { LiaUserEditSolid } from "react-icons/lia";
import { PiProhibit } from "react-icons/pi";
const AccountTable = ({
  accountsPermission,
  pageSize,
  pageNo,
  tblHead,
  redirectLink,
  getAllAccountData,
  accountCostumerId,
  setAccountCostumerId,
  loading,
}) => {
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  const navigate = useNavigate();
  // Handle Single Check Box For Single Updateion And Id get and send Start ------
  const handleCheckboxChange = (leadId) => {
    const isSelected = accountCostumerId.includes(leadId);
    if (isSelected) {
      setAccountCostumerId(accountCostumerId.filter((id) => id !== leadId));
    } else {
      setAccountCostumerId([...accountCostumerId, leadId]);
    }
  };
  // Handle Master Checkbox Change Start -----
  const handleMasterCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsMasterChecked(isChecked);
    const allLeadIds = getAllAccountData?.content?.map((data) => data.id) || [];
    if (isChecked) {
      setAccountCostumerId(allLeadIds);
    } else {
      setAccountCostumerId([]);
    }
  };
  useEffect(() => {
    const allLeadIds = getAllAccountData?.content?.map((data) => data.id) || [];
    if (allLeadIds.length === 0) return;
    if (accountCostumerId.length === allLeadIds.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }, [accountCostumerId, getAllAccountData]);

  return (
    <div className="LeadRightSectionTable_body table-responsive p-0">
      <div className="LeadRightSectionTable_body table-responsive">
        {/* Table */}
        <table className="table mytable">
          <thead>
            <tr>
            <th scope="col" className=" text_center text-left text-capitalize">
                <div className="wrap-check-29">
                  <div className="cbx">
                    <input
                      id="cbx-29"
                      type="checkbox"
                      onChange={handleMasterCheckboxChange}
                      checked={isMasterChecked}
                    />
                    <label htmlFor="cbx-29" />
                    <svg width={15} height={14} viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2" />
                    </svg>
                  </div>
                  {/* Gooey*/}
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo-12">
                        <feGaussianBlur
                          in="SourceGraphic"
                          stdDeviation={4}
                          result="blur"
                        />
                        <feColorMatrix
                          in="blur"
                          mode="matrix"
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                          result="goo-12"
                        />
                        <feBlend in="SourceGraphic" in2="goo-12" />
                      </filter>
                    </defs>
                  </svg>
                </div>
              </th>
              <th
                scope="col"
                className="text-left text_center text-capitalize"
                style={{ cursor: "context-menu" }}
              >
                Sr. No
              </th>
              {/* Checkbox Column */}
             
              <th scope="col" className="text-left text_center text-capitalize">
                {tblHead.eighthHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.firstHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.secondHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.thirdHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.fourthHead}
              </th>
              <th scope="col" className=" text_center text-left text-capitalize">
                {tblHead.fifthHead}
              </th>
              <th scope="col" className=" text_center text-capitalize">
                {tblHead.sixthHead}
              </th>
              <th scope="col" className="text-center text_center text-capitalize">
                {tblHead.seventhHead}
              </th>
              <th className=" text_center text-center text-capitalize">Edit</th>
              {/* <th scope="col" className="text-center">
                {tblHead.eighthHead}
              </th> */}
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody className="dashboard_section1_tableBody ">
              {getAllAccountData && getAllAccountData?.content?.length > 0 ? (
                getAllAccountData?.content?.map((data, index) => (
                  <tr
                    key={data.id}
                    onClick={() => localStorage.setItem("accountId", data.id)}
                  >
                      <td className=" text_center text-left text-capitalize">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                        id="flexCheckIndeterminate"
                        onChange={() => handleCheckboxChange(data.id)}
                        checked={accountCostumerId?.includes(data.id)}
                      />
                    </td>
                    <td className="text-left text_center text-capitalize">
                      <span className="Link-button-leads">
                        <span
                          style={{
                            cursor: "context-menu",
                            border: "1px solid #9c9c9c",
                            padding: "2px 4px",
                            borderRadius: "3px",
                          }}
                        >
                          {`${
                            pageNo * pageSize + index < 9
                              ? `0${pageNo * pageSize + index + 1}`
                              : pageNo * pageSize + index + 1
                          }`}
                          {/* {pageNo * pageSize + index + 1} */}
                        </span>
                      </span>
                    </td>
                  
                    <td className="text-left text_center text-capitalize">
                   
                        <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                        </Link>
                   
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads"> 
                      {data.accountName} 
                    </Link>
                  </td> */}
                    <td className="text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.accountName || "No Data"}
                        <span className="tool_tip_box">
                          {" "}
                          {data.accountName || "No Data"}
                        </span>
                      </div>
                    </td>
                    <td className=" text_center text-left text-capitalize">
                     
                      <span className="Link-button-leads">
                      {data.accountNumber || "No Data"}
                      </span>
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.accountOwner}
                    </Link>
                  </td> */}
                    <td className="text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.accountOwner || "No Data"}
                        <span className="tool_tip_box">
                          {" "}
                          {data.accountOwner || "No Data"}
                        </span>
                      </div>
                    </td>
                    <td className=" text_center text-left text-capitalize">
                     
                      <span className="Link-button-leads">
                      {data.annualRevenue || "No Data"}
                      </span>
                    
                    </td>
                    {/* <td className=" text_center">
                    <Link to={redirectLink} className="Link-button-leads">
                      {data.accountSite}
                    </Link>
                  </td> */}
                    <td className="text-left text-capitalize" style={{ position: "relative" }}>
                      <div className="tool_tip">
                        {data.accountSite || "No Data"}
                        <span className="tool_tip_box">{data.accountSite || "No Data"}</span>
                      </div>
                    </td>
                    <td className=" text_center text-capitalize">
                      <Link to={redirectLink} state={data?.id} className="Link-button-leads">
                        <FaRegEye className="showDetailEye fs-4" />
                      </Link>
                    </td>
                    <td className="text-center text_center text-capitalize">
                      <Link to="/create-task" state={{ leadId: data.leadId }}>
                        <HiPencilSquare className="lead-table-contact-action-icon fs-4" />
                      </Link>
                    </td>
                    <td className="text-center text-capitalize">
                      {accountsPermission?.includes("Update") ? (
                        <LiaUserEditSolid
                          className="lead-table-contact-action-icon fs-4"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate("/create-account", {
                              state: { data, from: "update" },
                            })
                          }
                        />
                      ) : (
                        <PiProhibit
                          style={{ cursor: "crosshair" }}
                          className="lead-table-contact-action-icon fs-4"
                        />
                      )}
                    </td>
                    {/* <td className="text-center">
                    {data?.leadId && (
                      <Link to={redirectLink} className="Link-button-leads">
                     
                          <span className="leads_table_id_text">
                            LI-{data.leadId}
                          </span>
                      
                      </Link>
                    )}
                  </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className=" text-capitalize text-center">No Account Data At this Time</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default AccountTable;
