import React, { useState, useEffect } from "react";
import { getSingleDeal } from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Gobackpage from "../gobackpage";



const DealsCostumerDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const dealId = location.state;
  const [getSingleDealData, setSingleDealData] = useState([]);
  // Get Token & Deal Id
  // const dealId = JSON.parse(localStorage.getItem("dealId"));
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  useEffect(() => {
    getSingleDeal(dealId, tokenId).then((res) => {
      setSingleDealData(res);
    });
  }, []);
  return (
    <>
    <Gobackpage pageName={"Deal Details"}/>
      
      <div className="account_view_details_Row" style={{minWidth:"400px"}}>
        {/* Deals Information */}
        <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
          Deal Information
        </h3>
        <p className="mx-2 dashboard_leadView_details_heading_second">
          Lead Id : LI-{getSingleDealData?.leadId}
        </p>

        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Deal Owner', value: getSingleDealData?.dealOwner },
            { label: 'Deal Name', value: getSingleDealData?.dealName },
            { label: 'Amount', value: getSingleDealData?.amount },
            { label: 'Closing Date', value: getSingleDealData?.closingDate },
            { label: 'Account Name', value: getSingleDealData?.accountName },
            { label: 'Type', value: getSingleDealData?.type },
            { label: 'Next Step', value: getSingleDealData?.nextStep },
            { label: 'Expected Revenue', value: getSingleDealData?.expectedRevenue },
            { label: 'Lead Source', value: getSingleDealData?.leadSource },
            { label: 'Campaign Source', value: getSingleDealData?.campaignsource },
            { label: 'Contact Name', value: getSingleDealData?.contactName },
            { label: 'Stage', value: getSingleDealData?.stage },
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 30%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{ fontWeight: "bold" }}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word', marginLeft: "10px" }}>{ getSingleDealData?.dealOwner ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Deal Owner
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.dealOwner}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Deal Name
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.dealName}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Ammount
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.amount}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Closing Date
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.closingDate}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
      {/* <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Deal Name
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.dealName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Ammount
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.amount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Closing Date
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.closingDate}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
      {/* <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Account Name
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.accountName}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Type
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.type}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Next Step
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.nextStep}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Expected Revenue
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.expectedRevenue}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
      {/* <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Type
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.type}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Next Step
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.nextStep}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Expected Revenue
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.expectedRevenue}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
      {/* <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Lead Source
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.leadSource}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Campaign Source
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.campaignSource}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Contact Name
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.contactName}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Stage
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.stage}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
      {/* <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Campaign Source
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.campaignSource}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Contact Name
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.contactName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive account_view_details_table">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Stage
                    </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                    <td className="lead_view_details_table_td">
                      {getSingleDealData?.stage}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
    </>

  );
};

export default DealsCostumerDetails;
