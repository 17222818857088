import React, { useState, useEffect } from "react";
// Controller Method & Api
import { getSingleTask } from "../../controller/fetchApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Gobackpage from "../gobackpage";
const TaskDetails = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const taskId = location.state;
  const [getSingleTaskData, setSingleTaskData] = useState([]);
  
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  useEffect(() => {
    getSingleTask(taskId, tokenId).then((res) => {
      setSingleTaskData(res);
    });
  }, []);
  // Date Time Understndable Formate In reminder
  const date = new Date(getSingleTaskData?.reminder);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.toLocaleString("default", { day: "2-digit" });
  const year = date.toLocaleString("default", { year: "numeric" });
  const time = date.toLocaleString("default", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  const reminder = `${day} ${month} ${year} || ${time}`;

  return (
    <div className="" style={{minWidth:"400px"}}>
      <Gobackpage pageName={"Task Details"}/>
    
      {/* Task Information */}
      <div style={{ padding: '16px', width: "100%", border: "1px solid #ccc", borderRadius: "10px" ,marginTop:"20px"}}>
        <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
          Task Information
        </h3>
        <p className="mx-2 dashboard_leadView_details_heading_second">
          Lead Id : LI-{getSingleTaskData?.leadId}
        </p>
        <div >
       
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {[
            { label: 'Task Owner', value: getSingleTaskData?.taskOwner },
            { label: 'Contact', value: getSingleTaskData?.contact },
            { label: 'Priority', value: getSingleTaskData?.priority },
            { label: 'Subject', value: getSingleTaskData?.subject },
            { label: 'Account Type', value: getSingleTaskData?.accountType },
            { label: 'Reminder', value: getSingleTaskData?.reminder },
            { label: 'Due Date', value: getSingleTaskData?.dueDate },
            { label: 'Status', value: getSingleTaskData?.accountSite },
            { label: 'Description   ', value: getSingleTaskData?.description},
           
          ].map((item, index) => (
            <div key={index} style={{ flex: '1 1 40%', minWidth: '250px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '8px' }}>
                <span style={{ minWidth: '150px', fontWeight: '500', whiteSpace: 'nowrap' }}>{item.label}</span><span style={{fontWeight: "bold"}}>:</span>
                <span style={{ flex: 1, textAlign: 'left', wordBreak: 'break-word' , marginLeft:"10px" }}>{getSingleTaskData?.taskOwner ? item.value || 'No Data' : ""}</span>
              </div>
            </div>
          ))}
        </div>


      </div>
        {/* <div className="row">
          <div className="col-xl-12">
            <div className="d-xl-flex d-md-flex justify-content-between flex-wrap justify-content-center align-items-start row-cols-3 ">
              <div className=" account_view_details_table">
                <div className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Task Owner
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.taskOwner}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Contact
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.contact}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Priority
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.priority}
                      </td>
                    </tr>
                  </tbody>
                </div>
              </div>
              <div className=" account_view_details_table">
                <div className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Subject
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.subject}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Account Type
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.accountType}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Reminder
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">{reminder}</td>
                    </tr>
                  </tbody>
                </div>
              </div>
              <div className=" account_view_details_table">
                <div className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Due Date
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.dueDate}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="contact_view_details_costumer_table_th"
                      >
                        Status
                      </th><span style={{fontSize:"20px",fontWeight:"500"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleTaskData?.status}
                      </td>
                    </tr>
                  </tbody>
                </div>
              </div>
            </div>
          </div>
        </div> */}
       
      </div>
    </div>
  );
};

export default TaskDetails;
