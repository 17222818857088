import React, { useState } from "react";
import { changePasswordApi } from "../controller/fetchApi";
import VerifyOtpPassword from "./VerifyOtpPassword";
// import { MdCancel } from "react-icons/md";
import { MdCancel, MdVisibility, MdVisibilityOff } from "react-icons/md";

const ChangePassword = ({
  setchangePassword,
  setpersonalInfo,
  setShowToast,
}) => {
  // verify otp
  const [verifyOtp, setverifyOtp] = useState(false);
  // change password
  const [passwordData, setpasswordData] = useState({
    oldPassword: { error: "", value: "" },
    newPassword: { error: "", value: "" },
    confirmPassword: { error: "", value: "" },
  });
// show the password
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const userIdTokenData = React.useMemo(
    () => JSON.parse(localStorage.getItem("user")),
    []
  );
  const tokenId = userIdTokenData?.data?.token;

  const handlepassowrdchange = (name, value) => {
    const pattern = /^[a-zA-Z0-9_\-\$\.\+#@]*$/;
    if (pattern.test(value)) {
      setpasswordData((prev) => ({
        ...prev,
        [name]: { value: value, error: "" },
      }));
    } else {
      setpasswordData((prev) => ({
        ...prev,
        [name]: { value: "", error: "Please enter correct value" },
      }));
    }
  };

  const handlepassowrdBlur = (name, value) => {
    if (name == "confirmPassword" && value != passwordData.newPassword.value) {
      setpasswordData((prev) => ({
        ...prev,
        [name]: {
          value: value,
          error: "Confirm Password should match new password",
        },
      }));
    }
    if (passwordData[name].value == "") {
      setpasswordData((prev) => ({
        ...prev,
        [name]: { value: value, error: `${name} is required` },
      }));
    }
  };

  const handlechangePassword = async () => {
    const UpdatePasswordRequest = {
      oldPassword: passwordData.oldPassword?.value,
      newPassword: passwordData.newPassword?.value,
      confirmPassword: passwordData.confirmPassword?.value,
    };
    const response = await changePasswordApi(
      UpdatePasswordRequest,
      tokenId,
      setShowToast
    );

    if (response?.status == 200) {
      setverifyOtp(true);
    }
  };
  return (
    <div className="card-body">
       <div className="row gx-3 mb-3" style={{marginLeft:"35px"}}>
      {verifyOtp && (
        <div className="verify_Otp">
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <MdCancel style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={() => setverifyOtp(false)} />
          </div>
          <div className="verify_otp_close_btn">
            <VerifyOtpPassword setShowToast={setShowToast} tokenId={tokenId} password={passwordData.newPassword?.value} />
          </div>
        </div>
      )}

      {/* Old Password */}
      <div className="col-md-4 error_message_password" style={{ position: "relative" ,  }}>
        <label className="mb-1" htmlFor="inputOld" style={{ fontWeight: "500" }}>
          Old Password
        </label>
        <div style={{ position: "relative" }}>
          <input
            maxLength={15}
            className="form-control"
            id="inputOld"
            type={showPassword.oldPassword ? "text" : "password"}
            name="oldPassword"
            value={passwordData?.oldPassword?.value}
            placeholder="Enter Old Password"
            onChange={({ target: { name, value } }) => handlepassowrdchange(name, value)}
            onBlur={({ target: { name, value } }) => handlepassowrdBlur(name, value)}
          />
          <span
            style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
            onClick={() => setShowPassword({ ...showPassword, oldPassword: !showPassword.oldPassword })}
          >
            {showPassword.oldPassword ? <MdVisibility /> : <MdVisibilityOff />}
          </span>
        </div>
        {passwordData?.oldPassword?.error && <small className="password_change_error">{passwordData?.oldPassword?.error}</small>}
      </div>

      {/* New Password */}
      <div className="col-md-4 error_message_password" style={{ position: "relative" }}>
        <label className="mb-1" htmlFor="inputNew" style={{ fontWeight: "500" }}>
          New Password
        </label>
        <div style={{ position: "relative" }}>
          <input
            maxLength={15}
            className="form-control"
            id="inputNew"
            type={showPassword.newPassword ? "text" : "password"}
            name="newPassword"
            value={passwordData?.newPassword?.value}
            placeholder="Enter New Password"
            onChange={({ target: { name, value } }) => handlepassowrdchange(name, value)}
            onBlur={({ target: { name, value } }) => handlepassowrdBlur(name, value)}
          />
          <span
            style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
            onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
          >
            {showPassword.newPassword ?  <MdVisibility /> : <MdVisibilityOff />}
          </span>
        </div>
        {passwordData?.newPassword?.error && <small className="password_change_error">{passwordData?.newPassword?.error}</small>}
      </div>

      {/* Confirm New Password */}
      <div className="col-md-4 error_message_password" style={{ position: "relative" }}>
        <label className="mb-1" htmlFor="inputConfirm" style={{ fontWeight: "500" }}>
          Confirm New Password
        </label>
        <div style={{ position: "relative" }}>
          <input
            maxLength={15}
            className="form-control"
            id="inputConfirm"
            type={showPassword.confirmPassword ? "text" : "password"}
            name="confirmPassword"
            value={passwordData?.confirmPassword?.value}
            placeholder="Confirm New Password"
            onChange={({ target: { name, value } }) => handlepassowrdchange(name, value)}
            onBlur={({ target: { name, value } }) => handlepassowrdBlur(name, value)}
          />
          <span
            style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
            onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })}
          >
            {showPassword.confirmPassword ?  <MdVisibility /> : <MdVisibilityOff />}
          </span>
        </div>
        {passwordData?.confirmPassword?.error && <small className="password_change_error">{passwordData?.confirmPassword?.error}</small>}
      </div>
    </div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <button
          className="btn btn-primary"
          style={{
            color:'black',
            background:'white',
            border:"1px solid black"
          }}
          onClick={() => {
            setpersonalInfo(true);
            setchangePassword(false);
          }}
        >
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handlechangePassword}
        style={{
          width:"80px"
        }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;

