import React, { useEffect } from 'react'

function DetailLog({setopenDetail,details,description}) {
    console.log(details,'details detailsdetails ')
    

    useEffect(()=>{
    document.addEventListener("click",()=>{
        setopenDetail("");
    })

    return(document.removeEventListener("click",()=>{
        setopenDetail("");
    }))
    },[])
  return ( 
    <div style={{position:"fixed",zIndex:"555",inset:"0",backgroundColor:"#cbcdce6c",display:"flex",alignItems:"center",justifyContent:"center"}}>
    <div style={{width:"780px",height:"740px",backgroundColor:"white",padding:"20px 30px",borderRadius:"8px"}} onClick={(e)=>e.stopPropagation()}>
        <div style={{height:"40px",display:"flex",alignItems:"center",borderBottom:"1px solid #dedddc",justifyContent:"flex-start",fontSize:"15px",fontWeight:"500",padding:"15px 10px 25px 10px"}}>
           <span>{description}</span>
        </div>
        <div style={{maxHeight:"670px",overflow:"auto",padding:'10px 5px'}}>
        {details?.map((detail,index)=>
              <ul key={index} className="th-design" style={{display:"flex",alignItems:"center",marginBottom:"5px"}}>
                     <li className="text-left col-xl-1 log_head" style={{listStyle:'none',paddingLeft:"15px"}}>
                   <span style={{display:"inline-block",padding:"2px 7px",border:"1px solid #dedddc",borderRadius:"2px",fontSize:"13px",color:"GrayText"}}>{`${index<9?`0${index+1}`:index+1}`}</span>
                      </li>
                        <li
                          style={{ fontSize: "15px",listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"15px"}}
                          className="text-left col-xl-3"
                        >{detail?.title}</li>
                        <li
                          style={{ fontSize: "15px",listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"15px",wordBreak:"break-word"}}
                          className="text-left col-xl-3"
                        >
                          {detail?.updatedValue[0]?.preValue}
                        </li>
                        <li
                          style={{ fontSize: "15px",listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"15px",wordBreak:"break-word"}}
                          className="text-left col-xl-3"
                        >
                          {detail?.updatedValue[0]?.updatedValue}
                        </li>
                      </ul>
                    )}
                    </div>
    </div>
    </div>
  )
}

export default DetailLog