import React, { useState, useEffect } from "react";
import bootstrap from "bootstrap";
import UpdateRoleAndPermission from "../pages/UpdateRoleAndPermission";
// React Router
import { Link } from "react-router-dom";
// React Icons
import { TfiWrite } from "react-icons/tfi";
import { TiUserDelete } from "react-icons/ti";
// Import Toast
import Toast from "./Toast";
import { FaRegEye } from "react-icons/fa";
// Controller
import {
  getSingleUserPermission,
  deleteUserByAdmin,
} from "../controller/fetchApi";
import Deleteuserconfirmation from "./deleteuserconfirmation";
import activity_icon from "../images/activity_icon.png";
import delete_icon from "../images/delete_icon.png";
import Loader from "../pages/Loader";
import { MdDelete } from "react-icons/md";
import { AiOutlineDelete, AiTwotoneDelete } from "react-icons/ai";


const CreatedUserTable = ({
  tblHead,
  data,
  getAllUser,
  redirectLink,
  setcurrdata,
  loading,
}) => {
  const pageNo = data?.pageNumber;
  const pageSize = data?.pageSize;
  // Toast Message Code
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  if (showToast.message) {
    setTimeout(() => {
      setShowToast({ success: false, message: "", optmessage: "" });
    }, 3000);
  }

  // update user
  const [updateUser, setupdateUser] = useState(null);

  // Token And Users Data
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;

  const [deluser, setdeluser] = useState("");
  const [show, setshow] = useState(false);
  // Handle User Delete
  const handleUserDelete = async (pin) => {
    const result = await deleteUserByAdmin(tokenId, deluser, setShowToast, pin);
    if (result?.data?.status === 200) {
      getAllUser();
      setshow(false);   
    }
  };

  const [updatemodalUser, setupdatemodalUser] = useState(null);

  return (
    <div className="LeadRightSectionTable_body table-responsive">
      <table className="table mytable ">
        <thead>
          <tr>
            <th scope="col" className="text-center text_center">
              {tblHead.firstHead}
            </th>
            <th scope="col" className="text-left text_center">
              {tblHead.secondHead}
            </th>
            <th scope="col" className="text-left text_center">
              {tblHead.thirdHead}
            </th>
            <th scope="col" className="text-left text_center ">
              {tblHead.seventhHead}
            </th>
            <th scope="col" className="text-left  text_center">
              {tblHead.fourthHead}
            </th>
            <th scope="col" className="text-center col-xl-1 text_center">
              {tblHead.fifthHead}
            </th>
            <th scope="col" className="text-center col-xl-1 text_center">
              {tblHead.sixthHead}
            </th>
            <th scope="col" className="text-center col-xl-1 text_center">
              {tblHead.eighthHead}
            </th>
            <th scope="col" className="text-center col-xl-1 text_center">
              {tblHead.ninthHead}
            </th>
          </tr>
        </thead>
        {loading ? (
          <Loader />
        ) : (
          <tbody className="dashboard_section1_tableBody">
            {data?.data && data?.data?.length > 0 ? (
              data?.data?.map((user, index) => (
                <tr key={index} className="th-design">
                  <td className="text-center text_center">
                    <span className="Link-button-leads">
                      <span
                        style={{
                          cursor: "context-menu",
                          border: "1px solid #9c9c9c",
                          padding: "2px 4px",
                          borderRadius: "3px",
                        }}
                      >
                        {`${
                          pageNo * pageSize + index < 9
                            ? `0${pageNo * pageSize + index + 1}`
                            : pageNo * pageSize + index + 1
                        }`}
                      </span>
                    </span>
                  </td>
                 
                  <td style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {`${user?.firstName}  ${user?.lastName}`}
                      <span className="tool_tip_box">{`${user?.firstName}  ${user?.lastName}`}</span>
                    </div>
                  </td>
                 
                   <td style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {user?.email}
                      <span className="tool_tip_box">{user?.email}</span>
                    </div>
                  </td>
                
                   <td style={{ position: "relative" }}>
                    <div className="tool_tip">
                    {user?.role || "Not Assigned"}
                      <span className="tool_tip_box"> {user?.role || "Not Assigned"}</span>
                    </div>
                  </td>
                  <td className="text-left text_center">
                    {user?.mobile}
                  </td>
                  <td className="text-center col-xl-1 text_center">
                    <Link to="/dashboard" state={{ userId: user?.id }}>
                      <FaRegEye className="fs-3 super_admin_section2_table_icon" />
                    </Link>
                  </td>
                  <td className="text-center text_center">
                    {!user.role && (
                      <Link
                        title="Assign Role and Permission"
                        to={redirectLink}
                        className="Link-button-leads col-xl-1"
                        state={{ userData: user }}
                      >
                        <TfiWrite
                          className="fs-4"
                          style={{ color: "#0d6efd" }}
                        />
                      </Link>
                    )}
                    &nbsp; &nbsp;
                    {user.role && (
                      <Link to="/updateroleandpermissions"
                      state={user}
                        title="Update Role and Permission"
                        className="Link-button-leads"
                      >
                        <button
                          className="btn btn-outline-primary btn-sm"
                          // onClick={() => {
                          //   setupdateUser(user);
                          // }}
                        >
                          Update
                        </button>
                      </Link>
                    )}
                  </td>

                  <td className="text-center col-xl-1 text_center">
                    <Link to="/userLogs" state={{ userid: user?.id }}>
                      <img
                        width="25px"
                        src={activity_icon}
                        className="fs-3 created_user_table_action_delete"
                      />
                    </Link>
                  </td>
                  <td className="text-center col-xl-1 text_center">
               
                    <AiOutlineDelete
                      title="Delete User"
                      className="fs-3 created_user_table_action_delete"
                      onClick={() => {
                        setshow(true);
                        setdeluser(user?.id);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">No users at this time</td>
              </tr>
            )}
          </tbody>
        )}
      </table>

      {/* NEW Update Role And Permission MODAL START */}
      {updateUser && (
        <div className="NEWMODALupdate ">
          <div className="actmodalupdate CREATEPOP_FORM">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setupdateUser(null)}
              >
                X
              </button>
            </div>
            <div className="modalContent createupdate_innerform">
              <UpdateRoleAndPermission
                setcurrdata={setcurrdata}
                lastUser={updateUser}
                setlastUser={setupdateUser}
              />
            </div>
          </div>
        </div>
      )}
      {/* NEWMODAL END */}

      {show && (
        <Deleteuserconfirmation
          setShowToast={setShowToast}
          show={show}
          setShow={setshow}
          handleDelete={handleUserDelete}
          deleteId={deluser}
          sourceName="User"
        />
      )}

      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreatedUserTable;
