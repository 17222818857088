import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

const FilterSidebar = ({ filterData, setFilterData, setFilter }) => {
  const [filterField, setfilterField] = useState("");

  useEffect(() => {
    const handleClickOutside = () => setFilter(false);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      filterBy: "",
      filterValue: "",
      dateRange: "",
      customStartDate: "",
      customEndDate: "",
      leadType: filterData?.leadType,
      leadStatus: "",
    },
    onSubmit: (values) => {
      setFilterData(values);
    },
  });

  useEffect(() => {
    setValues((prev) => ({ ...prev, ...filterData }));
  }, [filterData]);

  return (
    <div
      className="filter-sidebar"
      style={{ width: "100%", height: "100vh", padding: "20px", background: "#fff", }}
      onClick={(event) => event.stopPropagation()}
    >

      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label htmlFor="leadType">Lead</label>
          <select id="leadType" name="leadType" value={values.leadType} onChange={handleChange} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}>
            <option value="" hidden>Select Lead</option>
            <option value="verified">Verified</option>
            <option value="unverified">Unverified</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label htmlFor="filterBy">Filter By Fields</label>
          <select id="filterBy" name="filterBy" onChange={({ target: { value } }) => {
            setfilterField(value);
            setFieldValue("filterBy", value);
            setFieldValue("filterValue", "");
          }} value={values.filterBy} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}>
            <option hidden>Select Field</option>
            <option value="firstName">Name</option>
            <option value="city">City Name</option>
            <option value="companyName">Company Name</option>
            <option value="leadOwner">Lead Owner</option>
            <option value="leadSource">Lead Source</option>
            <option value="Mobile">Mobile</option>
            <option value="None">None</option>
          </select>
          {values.filterBy && values.filterBy !== "None" && (
            <input type="text" name="filterValue" placeholder={`Enter ${filterField}`} value={values.filterValue} onChange={handleChange} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }} />
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label htmlFor="dateRange">Date</label>
          <select id="dateRange" name="dateRange" value={values.dateRange} onChange={handleChange} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}>
            <option hidden>Select Date</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="Last Week">Last Week</option>
            <option value="Last Month">Last Month</option>
            <option value="Last year">Last Year</option>
            <option value="Custom">Custom</option>
            <option value="none">None</option>
          </select>
          {values.dateRange === "Custom" && (
            <div style={{ display: "flex", gap: "10px" }}>
              <input type="date" name="customStartDate" value={values.customStartDate} onChange={handleChange} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }} />
              <input type="date" name="customEndDate" value={values.customEndDate} onChange={handleChange} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }} />
            </div>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label htmlFor="leadStatus">Status</label>
          <select id="leadStatus" name="leadStatus" value={values.leadStatus} onChange={handleChange} style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}>
            <option hidden>Select Status</option>
            <option value="Interested">Interested</option>
            <option value="Not Interested">Not Interested</option>
            <option value="No Response">No Response</option>
          </select>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <button
            type="button"
            onClick={() => {
              setValues({
                filterBy: "",
                filterValue: "",
                dateRange: "",
                customStartDate: "",
                customEndDate: "",
                leadType: "",
                leadStatus: "",
              });
              setFilter(false);
            }}
            style={{
              padding: "10px 20px",
              width: "47%",
              background: "#E0E0E0",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Clear
          </button>

          <button type="submit" style={{ padding: "10px 20px", width: "47%", background: "#4CAF50", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>Apply</button>
        </div>
      </form>
    </div>
  );
};

export default FilterSidebar;
