import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import { verifyPasswordChange } from '../controller/fetchApi';

function VerifyOtpPassword({password,tokenId,setShowToast}) {
    const [otp, setOtp] = useState('');
   
 const verifyOTP = async ()=>{
 const response = await verifyPasswordChange(password,otp,tokenId,setShowToast)
 }
  return (
    <div>
        <h2>Enter OTP</h2>
<OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
    <button onClick={verifyOTP}>Verify</button>
    </div>
  )
}

export default VerifyOtpPassword