import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { updateProfile, uploadLogo } from "../controller/fetchApi";
import { setImageUpdated } from "../app/slices";
import { updateProfileFormSchema } from "../schema/FormValidation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function PersonalInfoUpdate({ setShowToast }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectlogo, setselectlogo] = useState(null);
  const logoRef = useRef(null);
  //  handle user data
  const { user, userImage, imageUpdated } = useSelector(
    (state) => state?.userData
  );
  const userIdTokenData = React.useMemo(
    () => JSON.parse(localStorage.getItem("user")),
    []
  );
  const tokenId = userIdTokenData?.data?.token;
  const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;

  // handle logo change
  const handleLogoChange = (event) => {
    setselectlogo(event.target.files[0]);
  };

  // Form Handle & Validations
  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.mobile || "",
    },
    validationSchema: updateProfileFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (selectlogo) {
          const response = await uploadLogo(selectlogo, setShowToast, tokenId);
          if (response) {
            dispatch(setImageUpdated(true));
          }
        }

        if (values) {
          await updateProfile(tokenId, setShowToast, values);
        }
      } catch (error) {
        console.log("Did Not Update Profile", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row gx-3 mb-3 " style={{ marginLeft: "40px" }}>
          {/* First Name */}
          <div className="col-md-4">
            <label
              className="mb-1"
              htmlFor="inputFirstName"
              style={{ fontWeight: "500" }}
            >
              First Name
            </label>
            <input
              maxLength={30}
              className="form-control"
              id="inputFirstName"
              type="text"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="firstName"
              placeholder="Enter first name"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <small style={{ color: "red" }}>{formik.errors.firstName}</small>
            )}
          </div>

          {/* Last Name */}
          <div className="col-md-4">
            <label
              className="mb-1"
              htmlFor="inputLastName"
              style={{ fontWeight: "500" }}
            >
              Last Name
            </label>
            <input
              maxLength={30}
              className="form-control"
              id="inputLastName"
              type="text"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="lastName"
              placeholder="Enter last name"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <small style={{ color: "red" }}>{formik.errors.lastName}</small>
            )}
          </div>

          {/* Phone Number */}
          <div className="col-md-4">
            <label
              className="mb-1"
              htmlFor="inputPhone"
              style={{ fontWeight: "500" }}
            >
              Phone
            </label>
            <input
              className="form-control"
              id="inputPhone"
              type="text"
              maxLength={10}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone"
              placeholder="Enter your contact no"
            />
            {formik.touched.phone && formik.errors.phone && (
              <small style={{ color: "red" }}>{formik.errors.phone}</small>
            )}
          </div>
        </div>

        {/* mobile no */}
        {/* <div className="row gx-3 mb-3">
        <div className="col-md-6">
          <label
            className="mb-1"
            htmlFor="inputLocation"
            style={{ fontWeight: "500" }}
          >
            Phone
          </label>
          <input
            className="form-control"
            id="inputLocation"
            type="text"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="phone"
            placeholder="Enter your contact no"
          />
          {formik.touched.phone && formik.errors.phone && (
            <small style={{ color: "red" }}>
              {formik.errors.phone}
            </small>
          )}
        </div>
      </div> */}
        {/* logo */}
        {["SUPERADMIN", "ADMIN"].includes(roles) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "40px",
            }}
          >
            <div className="col-xl-6 mb-4 profile_image">
              <div className="Upload_logo_box">
                <img
                  className="Upload_image"
                  style={{
                    aspectRatio: "auto",
                  }}
                  src={userImage?.companyLogo}
                  alt="logo"
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  border: "1px solid grey",
                  padding: "7px 25px",
                  borderRadius: "5px",
                  fontWeight: "500",
                  background: "#eaf0f2",
                }}
                className="Upload_image_button"
                onClick={() => logoRef.current.click()}
              >
                Upload logo
              </div>
            </div>
            <div className="mb-3 col-xl-6" style={{ display: "none" }}>
              <div className="mb-3 col-xl-12">
                <input
                  ref={logoRef}
                  type="file"
                  className="form-control"
                  id="logoPicture"
                  accept=".jpg, .png"
                  onChange={handleLogoChange}
                  disabled={!["ADMIN", "SUPERADMIN"].includes(roles)}
                />
              </div>
            </div>
          </div>
        )}

        {/* Save changes button*/}
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <button
            className="btn btn-primary"
            style={{
              color:'black',
              background:'white',
              border:"1px solid black"
            }}
            onClick={() => navigate(-1)}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            style={{
              width: "80px",
            }}
            type="submit"
            disabled={formik.isSubmitting}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default PersonalInfoUpdate;
