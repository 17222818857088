import * as XLSX from "xlsx";
// import * as XLSX from "xlsx/dist/xlsx.full.min.js";

import { saveAs } from "file-saver";

const exportToExcel = () => {
  const data = [
    {
      LeadOwner: "John",
      FirstName: 30,
      LastName: 30,
      Mobile: "9999999999",
      MobileAlt: "9999999999",
      Email: "john@example.com",
      LeadSource: "abc",
      LeadStatus: "abc",
      LeadType: "abc",
      AnnualRevenue: "999999",
      CompanyName: "companyabc",
      CompanyEmail: "company@gamil.com",
      CompanyContact: "9999999999",
      SecondaryContact: "9999999999",
      City: "abc",
      District: "abc",
      State: "abc",
      Country: "abc",
      Description: "lead description",
      Phone: "9999999999",
      PhoneAlt: "9999999999",
      EmailAlt: "example@gmail.com",
      Subject: "abc",
      PinCode: "123456",
      Address: "abc",
      CountryISO: "abc",
      ProductName: "abc",
    },
  ];
  console.log("XLSX.utils", XLSX.utils);
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(dataBlob, "data.xlsx");
};

export const exportToTask = () => {
  const data = [
    {
      leadId: 55,
      taskOwner: "Singhtek Admin",
      subject: "ghghg",
      dueDate: "2025-03-19",
      contact: "8824081228",
      accountType: "web-search",
      status: "In Progress",
      priority: "deffered",
      reminder: "2025-03-10T07:31:00",
      description: "hjhgj",
    },
  ];
  console.log("XLSX.utils", XLSX.utils);
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(dataBlob, "data.xlsx");
};

export const exportToDeal = () => {
  const data = [
    {
      id: 6,
      leadId: null,
      dealOwner: "Singhtek Admin",
      companyCode: "singhtek@yopmail.com",
      dealName: "QQQQQQQQQQ",
      accountName: "ZZZZZZZZZZZZZZZZZZ",
      type: "existing-business",
      leadSource: "advertisement",
      contactName: "XXXXXX",
      amount: 553.0,
      closingDate: "2025-03-23",
      stage: "need-analysis",
      nextStep: "AAAAAA",
      probability: 0,
      expectedRevenue: "6666666666",
      description: "ASDF",
      createdDate: "2025-03-15T09:56:03.484589",
      modifiedDate: "2025-03-15T09:56:03.484602",
    },
  ];
  console.log("XLSX.utils", XLSX.utils);
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  saveAs(dataBlob, "data.xlsx");
};

export default exportToExcel;
