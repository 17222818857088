import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
// React Icon
import { FaUserTie } from "react-icons/fa";
import { HiOutlinePhoneOutgoing } from "react-icons/hi";
import { MdOutlineBook } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ScheduleCallSchema } from "../../schema/FormValidation";
import { TfiAgenda } from "react-icons/tfi";
import { FaTreeCity } from "react-icons/fa6";
// Import Toast
import Toast from "../../components/Toast";

// Controller Api Methods
import {
  createScheduleCall,
  callRelatedDropdowns,
  callPurposeDropdowns,
  fetchCalllog,
  fetchRelatedlog,
  updateScheduleCall,
} from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Datalist from "../../components/datalist";
import Gobackpage from "../gobackpage";

// Get TokenId and Uid
const userIdTokenData = JSON.parse(localStorage.getItem("user"));
const uid = userIdTokenData?.data?.userId;
const tokenId = userIdTokenData?.data?.token;

const ScheduleCall = () => {
  const [relatedToType, setRelatedTo] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const leadId = location.state?.leadId;
  const data = location.state?.data;
  const navigatingFrom = location.state?.from;
  console.log("data schedule call", data);

  const scheduleCallId = JSON.parse(localStorage.getItem("scheduleCallId"));
  // Toast
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  // Function to hide the toast after 3 seconds
  useEffect(() => {
    if (showToast.message) {
      const timer = setTimeout(() => {
        setShowToast({ success: false, message: "", optmessage: "" });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);
  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      callToType: data?.callTo || "",
      relatedToType: data?.relatedTo || "",
      callType: data?.callType || "",
      callStatus: data?.callStatus || "",
      callStartTime: data?.callStartTime || "",
      callOwner: userIdTokenData?.data?.fullName || "",
      subject: data?.subject || "",
      reminder: data?.reminder || "",
      leadId: data?.leadId || "",
      callPurpose: data?.callPurpose || "",
      callAgenda: data?.callAgenda || "",
      callTo: data?.callTo || "",
      relatedTo: data?.relatedTo || "",
    },
    validationSchema: ScheduleCallSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (navigatingFrom == "update") {
          const updates = Object.keys(data).filter((key) => {
            if (Array.isArray(values[key])) {
              return values[key].length == data[key].length
                ? values[key].length
                  ? values[key].some((elm) => !data[key].includes(elm))
                    ? true
                    : false
                  : false
                : true;
            } else {
              return values[key] && values[key] != data[key];
            }
          });

          let updatedData = {};
          if (updates.length) {
            updates.forEach((key) => (updatedData[key] = values[key]));
            await updateScheduleCall(data?.id, updatedData, setShowToast, tokenId);
            setTimeout(() => {
              navigate("/call-schedule");
            }, 2000);
          } else {
            alert("No updated data.");
          }
        } else {
          await createScheduleCall(uid, values, setShowToast, tokenId);
          resetForm();
          setTimeout(() => {
            navigate("/call-schedule");
          }, 2000);
        }
      } catch (error) {
        console.log("Did Not Create Account", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Related To
 
  const getCallRelatedDropdowns = useCallback(async () => {
    try {
      const callRelatedDropdown = await callRelatedDropdowns(tokenId);
      setRelatedTo(callRelatedDropdown);
    } catch (error) {
      console.log(error);
    }
  }, []); //do not add dispatch because infinite loop start

  // Purpose
  const [callPurpose, setCallPurpose] = useState();
  const getCallPurposeDropdowns = useCallback(async () => {
    try {
      const callPurposeDropdownsResult = await callPurposeDropdowns(tokenId);
      setCallPurpose(callPurposeDropdownsResult);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Set Current Date and Time
  const [currentDateTime, setCurrentDateTime] = useState("");
  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, "0");
    const hh = String(today.getHours()).padStart(2, "0");
    setCurrentDateTime(`${yyyy}-${mm}-${dd}T${hh}:00`);
    getCallRelatedDropdowns();
    getCallPurposeDropdowns();
  }, [getCallRelatedDropdowns, getCallPurposeDropdowns]);

  //  search call api for callToType
  const [callToFound, setdataFound] = useState(true);
  const [lastcall, setLastcall] = useState("");
  const [relatedToFound, setrelatedToFound] = useState(true);
  const [lastrelated, setlastRelated] = useState("");
  // const [dataFound,setdataFound] = useState(true)
  const [datalist, setdatalist] = useState([]);
  const searchCallToApi = async (e) => {
    const value = e.target.value;

    if (callToFound || lastcall === value) {
      const response = await fetchCalllog(
        value,
        values.callToType,
        tokenId,
        setShowToast
      );
      setdatalist(response);
      if (response) {
        setdataFound(true);
        setLastcall(value);
      } else {
        setdataFound(false);
      }
      //  response?setdataFound(true):setdataFound(false)
    }
    !value && setdataFound(true);
  };

  const searchRelatedToToApi = async (e) => {
    const value = e.target.value;
    if (relatedToFound || lastrelated === value) {
      const response = await fetchRelatedlog(
        value,
        values.relatedToType,
        tokenId,
        setShowToast
      );
      setdatalist(response);
      if (response) {
        setrelatedToFound(true);
        setlastRelated(value);
      } else {
        setrelatedToFound(false);
      }
      // response?setrelatedToFound(true):setrelatedToFound(false)
    }
    !value && setrelatedToFound(true);
  };
  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <Gobackpage pageName={navigatingFrom == "update"
            ? "Update Schedule Call"
            : "Create Schedule Call"}/>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <Link onClick={() => navigate(-1)}>
          <IoArrowBackCircleOutline
            className="_back_button"
            style={{ width: "60px" }}
          />
        </Link>
        <div style={{ fontSize: "21px", fontWeight: "700" }}>
          {navigatingFrom == "update"
            ? "Update Schedule Call"
            : " Schedule Call"}
        </div>
      </div> */}

      <form onSubmit={handleSubmit}>
        {/* Create Schedule Call Heading */}
        <div className="create-lead-heading">
          <div>
            <p className="create_lead_section2_company_info">
              Call Information
            </p>
          </div>
          {/* <div className="create-lead-heading-right-part">
            <p className="create-lead-heading-right-para">
              Lead Name :{" "}
              <span className="create-lead-heading-span">
                Pankaj Swami Vaishnav
              </span>
            </p>
            <p className="create-lead-heading-right-para">
              Lead Id : <span className="create-lead-heading-span">2024</span>
            </p>
          </div> */}
        </div>
        {/* User Account Information */}
        <div className="row">
          {/* <p className="create_lead_section2_company_info"> </p> */}
          <div className="form-group createLeadInput_new col-md-4 col-sm-6 createLeadInput_alignment mb-3 " style={{marginTop:"4px"}}>
            <label htmlFor="callTo">
              Call To <span className="required_sign">*</span>
            </label>
            <div className="create_call_form create_lead_form_input" >
              <div>
                <select
                  style={{
                    // width:"100%",
                    border: "none",
                    outline: "none",
                    // paddingLeft: "15px",
                  }}
                  id="callToType"
                  // className="form-control"
                  value={values.callToType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="callToType"
                >
                  <option value="" hidden>
                    Call To
                  </option>
                  <option value="lead">Lead</option>
                  <option value="contact">Contact</option>
                </select>
              </div>
              <div
                style={{
                  // width: "100%",
                  borderLeft: "1px solid rgba(128, 128, 128, 0.24)",
                  position: "relative",
                }}
              >
                <input
                  autoComplet={0}
                  list="callList"
                  disabled={!values.callToType}
                  type="text"
                  maxLength={30}
                  placeholder={
                    values.callToType ? "Search....." : "Please Select Type"
                  }
                  style={{
                    border: "none",
                    outline: "none",
                    // paddingLeft: "15px",
                  }}
                  value={values.callTo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={searchCallToApi}
                  name="callTo"
                />
                <datalist id="callList" style={{ maxHeight: "350px" }}>
                  {datalist?.map((data) => (
                    <option
                      value={data?.name}
                      style={{
                        // padding: "4px",
                        borderBottom: "1px solid rgba(216, 213, 213, 0.24)",
                      }}
                    >
                      {data?.name}
                    </option>
                  ))}
                  {/* <Datalist data={datalist}/> */}
                </datalist>
              </div>
            </div>
            {/* <label htmlFor="callTo">
              Call To <span className="required_sign">*</span>
            </label>
            <select
              id="callTo"
              className="form-control"
              value={values.callTo}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callTo"
            >
              <option value="" hidden>
                Call to
              </option>
              <option value="lead">Lead</option>
              <option value="contact">Contact</option>
            </select> */}

            {touched.callToType && errors.callToType && (
              <small className="errorMessage">{errors.callToType}</small>
            )}
            {touched.callTo && errors.callTo && values?.callToType && (
              <small className="errorMessage">{errors.callTo}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_iconnew" /> */}
          </div>
          {/* Related to */}
          <div className="form-group createLeadInput_new  col-md-4 col-sm-6 createLeadInput_alignment  mb-3" style={{marginTop:"4px"}}>
            <label htmlFor="relatedTo">
              Related To <span className="required_sign">*</span>
            </label>
            <div className="create_call_form create_lead_form_input">
              <div >
                <select
                  style={{
                    border: "none",
                    outline: "none",
                    // paddingLeft: "15px",
                  }}
                  id="relatedToType"
                  // className="form-control"
                  value={values.relatedToType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="relatedToType"
                >
                  <option value="" hidden>
                    Related to
                  </option>
                  {relatedToType && relatedToType?.length > 0
                    ? relatedToType.map((item) => (
                        <option key={item?.id} value={item?.value}>
                          {item?.relatedTo}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div
                style={{
                  width: "100%",
                  borderLeft: "1px solid rgba(128, 128, 128, 0.24)",
                 
                }}
              >
                <input
                  autoComplet={0}
                  list="relatedList"
                  disabled={!values.relatedToType}
                  type="text"
                  maxLength={30}
                  placeholder={
                    values.relatedToType ? "Search....." : "Please Select Type"
                  }
                  style={{
                    border: "none",
                    outline: "none",
                    
                  }}
                  value={values.relatedTo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={searchRelatedToToApi}
                  name="relatedTo"
                />
                <datalist id="relatedList">
                  <Datalist data={datalist} />
                </datalist>
              </div>
            </div>
            {/* ))))))))))))))))))))))) */}
            {/* <select
              id="relatedToType"
              className="form-control"
              value={values.relatedToType}
              onChange={handleChange}
              onBlur={handleBlur}
              name="relatedToType"
            >
              <option value="" hidden>
                Related to
              </option>
              {relatedToType && relatedToType?.length > 0
                ? relatedToType.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.relatedToType}
                    </option>
                  ))
                : ""}
            </select> */}
            {touched.relatedToType && errors.relatedToType && (
              <small className="errorMessage">{errors.relatedToType}</small>
            )}
            {touched.relatedTo &&
              errors.relatedTo &&
              values?.relatedToType && (
                <small className="errorMessage">{errors.relatedTo}</small>
              )}
            {/* <MdKeyboardArrowDown className="create_lead_input_iconnew" /> */}
            {/* )))))))))))))))))))))))))))))))))))))) */}
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3  createLeadInput_alignment" style={{position:"relative" }}>
            <label htmlFor="callType">
              Call Type <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              name="callType"
              id="callType"
              value="Outbound"
              className="form-control create_lead_form_input"
            />
            {/* <select
              id="callType"
              className="form-control"
              
              value="Outbound"
              onChange={handleChange}
              onBlur={handleBlur}
              name="callType"
            >
            
              <option value="outbound" selected>outbound</option>
             
            </select> */}
            {touched.callType && errors.callType && (
              <small className="errorMessage">{errors.callType}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_iconnew" /> */}
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="callStatus">
              Outgoing Call Status <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callStatus"
              className="form-control create_lead_form_input"
              // value={values.callStatus}
              value="Scheduled"
              onChange={handleChange}
              onBlur={handleBlur}
              name="callStatus"
              placeholder="Enter call status"
            />
            {touched.callStatus && errors.callStatus && (
              <small className="errorMessage">{errors.callStatus}</small>
            )}
            <HiOutlinePhoneOutgoing className="icon" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="callStartTime">
              Call Start Time <span className="required_sign">*</span>
            </label>
            <input
              type="datetime-local"
              id="callStartTime"
              min={currentDateTime}
              className="form-control create_lead_form_input"
              value={values.callStartTime}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callStartTime"
            />
            {touched.callStartTime && errors.callStartTime && (
              <small className="errorMessage">{errors.callStartTime}</small>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="callOwner">
              Call Owner <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callOwner"
              disabled
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.callOwner}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callOwner"
              placeholder="Enter Call Owner Name"
            />
            {touched.callOwner && errors.callOwner && (
              <small className="errorMessage">{errors.callOwner}</small>
            )}
            <FaUserTie className="icon" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="accountNumber">
              Subject <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="subject"
              maxLength={250}
              className="form-control create_lead_form_input"
              value={values.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              name="subject"
              placeholder="Enter Subject"
            />
            {touched.subject && errors.subject && (
              <small className="errorMessage">{errors.subject}</small>
            )}
            <MdOutlineBook className="icon" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="reminder">
              Reminder <span className="required_sign">*</span>
            </label>
            <select
              id="reminder"
              className="form-control"
              value={values.reminder}
              onChange={handleChange}
              onBlur={handleBlur}
              name="reminder"
            >
              <option value="">
                {/* {touched.reminder && errors.reminder ? (
                  <p className="text-danger">{errors.reminder}</p>
                ) : (
                  "None"
                )} */}
                None
              </option>
              <option value="5">5 Minute Before</option>
              <option value="10">10 Minute Before</option>
              <option value="15">15 Minute Before</option>
              <option value="30">30 Minute Before</option>
            </select>
            {touched.reminder && errors.reminder && (
              <small className="errorMessage">{errors.reminder}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_iconnew" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 " style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="leadId">
              Lead Id <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="leadId"
              disabled
              className="form-control create_lead_form_input"
              value={values.leadId}
              onChange={handleChange}
              onBlur={handleBlur}
              name="leadId"
              placeholder="Enter Address"
            />
            {touched.leadId && errors.leadId && (
              <small className="errorMessage">{errors.leadId}</small>
            )}
            <FaTreeCity className="icon" />
          </div>
        </div>
        {/* Calling Purpose */}
        <div className="row">
          <p className="create_lead_section2_company_info">
            Purpose Of Outgoing Call
          </p>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 createLeadInput_alignment" style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="callPurpose">
              Call Purpose <span className="required_sign">*</span>
            </label>
            <select
              id="callPurpose"
              className="form-control create_lead_form_input"
              value={values.callPurpose}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callPurpose"
            >
              <option value="">
                {/* {touched.callPurpose && errors.callPurpose ? (
                  <p className="text-danger">{errors.callPurpose}</p>
                ) : (
                  "None"
                )} */}
                None
              </option>
              {callPurpose && callPurpose?.length > 0
                ? callPurpose.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.callPurpose}
                    </option>
                  ))
                : ""}
            </select>
            {touched.callPurpose && errors.callPurpose && (
              <small className="errorMessage">{errors.callPurpose}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_iconnew" />
          </div>
          <div className="col-12 col-sm-6 col-md-4 mb-3 px-2 " style={{position:"relative" , marginTop:"10px"}}>
            <label htmlFor="callAgenda">
              Call Agenda <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callAgenda"
              className="form-control create_lead_form_input"
              value={values.callAgenda}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callAgenda"
              placeholder="Enter Call Agenda "
            />
            {touched.callAgenda && errors.callAgenda && (
              <small className="errorMessage">{errors.callAgenda}</small>
            )}
            <TfiAgenda className="icon" />
          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center mb-2">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={isSubmitting}
          >
             {navigatingFrom=="update" ? "Update" : "submit"}
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default ScheduleCall;
